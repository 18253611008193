import { combineReducers } from 'redux';

import cartReducer from './cart.reducer';
import filterReducer from './filter.reducer';
import loginReducer from './login.reducer';
import messageReducer from './message.reducer';
import quickSettingsReducer from './quickSettings.reducer';
import quoteReducer from './quote.reducer';
import settingsReducer from './settings.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
	user: userReducer,
	settings: settingsReducer,
	login: loginReducer,
	message: messageReducer,
	quickSettings: quickSettingsReducer,
	filters: filterReducer,
	cart: cartReducer,
	quote: quoteReducer,
});
export default rootReducer;
