import { Grid, Skeleton } from '@sourcewiz/the-source';

export default function FilterSkeleton() {
	return (
		<Grid xs={12} sm={6} md={4} lg={6.5} xl={7} item mt={1} mr="auto" container>
			<Grid item xs>
				<Skeleton
					sx={{ background: '#EEF1F7' }}
					variant="rounded"
					height={60}
				/>
			</Grid>
		</Grid>
	);
}
