/* eslint-disable */

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import { Button, Chip, Grid, Image, Typography } from '@sourcewiz/the-source';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from '../../../store/actions/cart.actions';
import { getFallbackSrc, getImageSrc } from '../../../util/common';
import classes from './AgGrid.module.css';
import { fetchData, setBulkData } from './api';

function ProductRenderer(params) {
	return (
		<Grid className={classes.productCell}>
			<Grid className={classes.image}>
				<Image
					src={getImageSrc(params.value.image)}
					fallbackSrc={getFallbackSrc(params.value.image)}
					width="42px"
					height="42px"
					className={classes.imageCls}
				/>
			</Grid>
			<Grid className={classes.description}>
				<Grid>
					<Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
						{params?.value?.id}
					</Typography>
				</Grid>
				<Grid>
					<Typography
						variant="body2"
						sx={{ fontWeight: '400' }}
						color="rgba(0, 0, 0, 0.6)">
						{params?.value?.name}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

function ListingPriceRenderer(params) {
	return (
		<Grid>
			{params?.data?.product?.action === 'customised' ? (
				<Chip
					label="CUSTOM"
					bgColor="#FCEFD6 !important"
					textColor="rgba(0, 0, 0, 0.87) !important"
				/>
			) : (
				params?.data?.listing_price
			)}
		</Grid>
	);
}

function AgGrid() {
	const [changedRows, setChangedRows] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [columnData, setColumnData] = useState([]);
	const [currency, setCurrency] = useState('');

	const apiRef = useRef({
		grid: undefined,
		column: undefined,
	});
	const onGridReady = (params) => {
		apiRef.current.grid = params.api;
		apiRef.current.column = params.columnApi;
	};
	const cart = useSelector((state) => state.cart.data);

	const dispatch = useDispatch();

	const cellStyle = {
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
		color: 'rgba(0, 0, 0, 0.87)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	};
	const gridOptions = {
		headerHeight: 56,
	};
	const columnDefs = [
		{
			headerClass: classes.headerClass,
			headerName: 'Product',
			field: 'product',
			width: 180,
			cellStyle: cellStyle,
			cellRenderer: ProductRenderer,
		},
		{
			headerClass: classes.headerClass,
			headerName: `${currency} Listing price`,
			field: 'Listing price',
			width: 120,
			cellStyle: cellStyle,
			cellRenderer: ListingPriceRenderer,
		},
		{
			headerClass: classes.headerClass,
			headerName: 'Discount %',
			field: 'discount.discount_value',
			width: 100,
			cellStyle: { ...cellStyle, background: '#FAFAFA', color: '#D74C10' },
			editable: (params) => {
				if (
					params?.data?.product?.action == 'customised' ||
					params?.data?.listing_price == null
				) {
					return false;
				}
				return true;
			},
			cellEditor: 'agTextCellEditor',
			cellEditorParams: {
				inputType: 'number',
			},
			valueSetter: function (params) {
				const newValue = parseFloat(params.newValue);
				if (!isNaN(newValue)) {
					if (
						params?.data?.product?.action === 'customised' ||
						params.data.listing_price == null
					) {
						return true;
					}
					params.data.discount.discount_value = newValue;
					params.data.final_price =
						params.data.listing_price -
						(params.data.listing_price * newValue) / 100;
					params.data.total = (
						params.data.final_price * params.data.quantity
					).toFixed(2);
					return true;
				}
				return false;
			},
		},
		{
			headerClass: classes.headerClass,
			headerName: `${currency} Final price`,
			field: 'Final price',
			field: 'final_price',
			width: 120,
			cellStyle: { ...cellStyle, background: '#FAFAFA' },
			editable: true,
			cellEditor: 'agTextCellEditor',
			cellEditorParams: {
				inputType: 'number',
			},
			valueSetter: function (params) {
				const newValue = parseFloat(params.newValue);
				if (!isNaN(newValue)) {
					params.data.final_price = newValue;
					if (
						params?.data?.product?.action === 'customised' ||
						params.data.listing_price == null
					) {
						params.data.total = (
							params.data.final_price * params.data.quantity
						).toFixed(2);
						return true;
					}
					params.data.discount.discount_value =
						100 - (newValue / params.data.listing_price) * 100;
					params.data.total = (
						params.data.final_price * params.data.quantity
					).toFixed(2);
					return true;
				}
				return false;
			},
		},
		{
			headerClass: classes.headerClass,
			headerName: 'Quantity',
			field: 'quantity',
			cellStyle: {
				fontSize: '14px',
				flex: 'none',
				order: '0',
				flexGrow: '0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
			},
			width: 110,
		},
		{
			headerClass: classes.headerClass,
			headerName: `${currency} Total`,
			field: 'total',
			cellStyle: { ...cellStyle, fontWeight: '700' },
			width: 105,
		},
	];

	const onCellValueChanged = (params) => {
		const arr = [...changedRows];
		if (!arr.includes(params.rowIndex)) {
			arr.push(params.rowIndex);
			setChangedRows(arr);
		}
	};

	const getRowStyle = (params) => {
		const style = {
			borderLeft: 'none !important',
			borderRight: 'none !important',
			background: 'white !important',
		};
		return style;
	};

	const handleSave = () => {
		const cart_id = cart.id;
		let products = [];
		changedRows.forEach((e) => {
			products.push(rowData[e]);
		});
		products = products.map((product) => {
			const row = {
				cart_product_id: product?.cart_product_id,
				discount: product?.discount?.discount_value,
				discount_type: 'percent',
			};
			if (product.listing_price == null) {
				delete row.discount;
				delete row.discount_type;
				row.final_price = product?.final_price;
			}
			return row;
		});
		const data = { cart_id, products };
		setBulkData(data)
			.then((res) => {
				dispatch(toast('success', 'Success', 'Successfully saved data'));
			})
			.catch((e) => {
				dispatch(toast('error', 'Error', 'Error while saving data'));
			});
	};

	useEffect(() => {
		fetchData().then((gridData) => {
			setRowData(gridData.rows);
			setColumnData(gridData.columns);
			setCurrency(gridData.currency);
		});
	}, []);

	return (
		<>
			<Grid
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '28px',
				}}>
				<Grid>
					<Typography variant="h6">Edit cart details</Typography>
					<Typography
						variant="body2"
						sx={{
							fontStyle: 'normal',
							fontWeight: '400',
							fontSize: '14px',
							marginTop: '4px',
							color: 'rgba(0, 0, 0, 0.6)',
						}}>
						Bulk edit discount or final prices
					</Typography>
				</Grid>
				<Grid>
					<Button onClick={handleSave} width="8.75rem">
						Done
					</Button>
				</Grid>
			</Grid>
			<Grid style={{ height: '45.75rem', marginBottom: '2rem' }}>
				<Grid
					style={{ height: '100%', width: '100%' }}
					className="ag-theme-balham">
					<AgGridReact
						rowSelection="multiple"
						gridOptions={gridOptions}
						getRowHeight={() => 77}
						getRowStyle={getRowStyle}
						suppressRowClickSelection
						columnDefs={columnDefs}
						onGridReady={onGridReady}
						rowData={rowData}
						onCellValueChanged={onCellValueChanged}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default AgGrid;
