import PropTypes from 'prop-types';
import './ProductDetails.css';

import { Typography } from '@sourcewiz/the-source';
import React, { useState } from 'react';

export default function ReadMore({ children }) {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	return (
		<Typography>
			{isReadMore ? text?.slice(0, 1200) : text}
			{text?.length > 1200 && (
				<span onClick={toggleReadMore} className="pdp_read_or_hide">
					{isReadMore ? '...read more' : ' show less'}
				</span>
			)}
		</Typography>
	);
}

ReadMore.propTypes = {
	children: PropTypes.any.isRequired,
};
