import PropTypes from 'prop-types';
import { Button, Checkbox, Grid, Typography } from '@sourcewiz/the-source';
import React, { useState } from 'react';
import TextEdit from '../../../TextEdit/TextEdit';
import styles from '../ReviewSubmit.module.css';
export default function Notes({ section, onSave = () => {} }) {
	const [show, setShow] = useState(false);
	const editNote = () => {
		setShow(true);
	};
	const isSharingEnabled = section?.all_data?.find(
		(val) => val.key === 'is_sharing_enabled'
	);
	const text = section?.all_data?.find((val) => val.key === 'text');
	const checkboxChange = (val) => {
		setCheckBoxValue(val.target.checked);
	};
	const [checkboxValue, setCheckBoxValue] = useState(isSharingEnabled?.value);
	const notesVal = text?.value || '';
	const saveClick = (data) => {
		onSave(data);
	};
	const renderer = (attribute) => {
		switch (attribute.dtype) {
			case 'text':
				return (
					<Grid className={styles.note}>
						<Typography color="rgba(0, 0, 0, 0.87)" variant="body2">
							{attribute?.value}
						</Typography>
					</Grid>
				);
			case 'checkbox':
				return (
					<Grid className={styles.noteCheckbox}>
						<Checkbox checked={attribute.value} />
						<Typography
							sx={{ marginLeft: '0.5rem' }}
							color="rgba(0, 0, 0, 0.87)"
							variant="body2">
							{attribute.sub_heading}
						</Typography>
					</Grid>
				);

			default:
				return (
					<Grid className={styles.note}>
						<Typography color="rgba(0, 0, 0, 0.87)" variant="body2">
							{attribute?.value}
						</Typography>
					</Grid>
				);
		}
	};

	return (
		<Grid container className={styles.card}>
			<Grid xs={9}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
					{section.name}
				</Typography>
			</Grid>
			<Grid xs={3} sx={{ textAlign: 'right' }}>
				<Button variant="text" onClick={editNote}>
					Edit
				</Button>
			</Grid>
			<Grid xs={12}>
				<Grid item xs={12} className={styles.notesContainer}>
					{section.attributes.map((sec) => renderer(sec))}
				</Grid>
			</Grid>
			{
				<TextEdit
					drawerOnly
					onClose={() => setShow(false)}
					show={show}
					text={notesVal}
					saveText={saveClick}
					placeholder={text?.place_holder}
					onCheckboxChange={checkboxChange}
					checkboxValue={checkboxValue}
					showCheckBox
				/>
			}
		</Grid>
	);
}

Notes.propTypes = {
	onSave: PropTypes.func.isRequired,
	section: PropTypes.shape({
		all_data: PropTypes.shape({
			find: PropTypes.func,
		}),
		attributes: PropTypes.shape({
			map: PropTypes.func,
		}),
		name: PropTypes.any,
	}).isRequired,
};
