import { ResetPassword as ResetPasswordTheSource } from '@sourcewiz/the-source';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom/dist';

import {
	changePassword_reset,
	changePasswordConfirm_reset,
	resetPasswordAction,
	resetStatus,
} from '../../../store/actions/login.actions';
import { closeToast, showToast } from '../../../store/actions/message.actions';

export default function ResetPassword() {
	const login = useSelector((state) => state.login);
	const dispatch = useDispatch();
	const passwordChange = (event) => {
		dispatch(changePassword_reset(event.target.value));
	};
	const params = useParams();
	const onConfirmPasswordChange = (event) => {
		dispatch(changePasswordConfirm_reset(event.target.value));
	};

	const supportEmailClick = () => {
		window.location = 'mailto:support@sourcewiz.co';
	};

	useEffect(() => {
		dispatch(resetStatus());
	}, []);
	const submit = () => {
		let error = false;
		let errorMsg = '';
		if (login.changePassword === '') {
			error = true;
			errorMsg = 'Password field is empty';
		} else if (login.changePasswordConfirm === '') {
			error = true;
			errorMsg = 'Confirm password field is empty ';
		} else if (login.changePassword !== login.changePasswordConfirm) {
			error = true;
			errorMsg = 'Passwords are not same ';
		}
		if (error) {
			const message = {
				open: true,
				showCross: false,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				autoHideDuration: 5000,
				onClose: (event, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					dispatch(closeToast());
				},
				state: 'error',
				title: 'Error',
				subtitle: errorMsg,
				showActions: false,
			};
			return dispatch(showToast(message));
		}
		dispatch(
			resetPasswordAction(
				{
					password: login.changePassword,
				},
				params.id,
				params.token
			)
		);
	};
	if (login.resetSuccess) {
		return <Navigate to="/reset-password-success" />;
	}
	return (
		<ResetPasswordTheSource
			password={login.changePassword}
			confirmPassword={login.changePasswordConfirm}
			passwordError={false}
			supportEmailClick={supportEmailClick}
			logoUrl="https://uploads-ssl.webflow.com/63e4eec68738ce8318cbab16/63fcd32306971477fb638c84_New%20logo-%20Satoshi%20font.svg"
			passwordErrorConfirm={false}
			illustrationImg="https://sourcerer.tech/assets/043dc1f4-d99f-4811-8e5b-81d521586338"
			onConfirmPasswordChange={onConfirmPasswordChange}
			onSubmit={submit}
			supportEmail="support@sourcewiz.co"
			onPasswordChange={passwordChange}
		/>
	);
}
