import {
	Avatar,
	Button,
	Grid,
	Icon,
	Image,
	Typography,
} from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	removeAllProducts,
	viewCartSummary,
} from '../../../store/actions/cart.actions';
import CheckoutCartCard from '../../CheckoutCartCard/CheckoutCartCard';
import NavInfo from '../NavInfo/NavInfo';
import styles from './CartBody.module.css';
export default function CartBody() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cartData = useSelector((state) => state.cart.data);
	const viewCartSummaryClick = () => {
		dispatch(viewCartSummary(true));
	};
	const bulkEdit = () => {
		navigate('/cart/bulk/edit?cart_id=' + cartData?.id);
	};
	const addProducts = () => {
		navigate('/all-products');
	};

	const removeAllClick = () => {
		dispatch(removeAllProducts(cartData.id));
	};

	return (
		<>
			<Grid className={styles.body}>
				{cartData?.entities?.length > 0 && (
					<>
						<Grid className={styles.heading}>
							<Grid>
								<Grid>
									<Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
										{cartData.heading}
									</Typography>
								</Grid>
								<Button
									onClick={removeAllClick}
									variant="text"
									sx={{ padding: '0px' }}>
									Remove all
								</Button>
							</Grid>
							<Grid className={styles.rightSide}>
								{cartData.bulk_edit_visibility && (
									<Grid display={'inline-block'} className="tooltip">
										<Avatar
											size="medium"
											variant="circular"
											className="tooltip"
											isImageAvatar={false}
											onClick={bulkEdit}
											sx={{
												background: 'white',
												border: '1px solid #D1D6DD',
												cursor: 'pointer',
											}}
											content={<Icon color="#4F555E" iconName="IconEdit" />}
										/>
										<Grid
											className="tooltiptext tooltip-bottom"
											sx={{ width: '120px' }}>
											Edit price in bulk
										</Grid>
									</Grid>
								)}
								<Grid sx={{ marginLeft: '1rem', width: '132px' }}>
									<Button tonal onClick={addProducts} fullWidth>
										Add products
									</Button>
								</Grid>
							</Grid>
						</Grid>
						{cartData?.message && (
							<Grid sx={{ margin: '25px 20px 60px 2rem' }}>
								<NavInfo
									title={cartData?.message?.text}
									type={cartData?.message?.type}
								/>
							</Grid>
						)}
						{cartData?.entities?.map((product, index) => (
							<CheckoutCartCard
								key={index + 1}
								{...product}
								staticProps={false}
								index={index}
							/>
						))}
					</>
				)}
				{(!cartData?.entities || cartData?.entities?.length === 0) && (
					<Grid className={styles.outer}>
						<Grid className={styles.inner}>
							<Image
								height="238px"
								width="308px"
								src="https://sourcerer.tech/assets/f0b923fe-6bec-46a0-a58d-d704e4b5ed2f"
							/>
							<Typography variant="h6" sx={{ marginTop: '2.5rem' }}>
								Start adding products to cart
							</Typography>
							<Grid sx={{ marginTop: '2rem ' }}>
								<Button width="160px" onClick={addProducts}>
									Explore products
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid
				sx={{ display: 'flex', justifyContent: 'center' }}
				className={styles.bottomGrid}>
				<Button
					className={styles.smallDeviceBtn}
					onClick={viewCartSummaryClick}>
					View cart summary
				</Button>
			</Grid>
		</>
	);
}
