import { confirmQuote } from '../../api/handlers/cart';
import { getQuoteApi, updateQuote } from '../../api/handlers/quote';
import { reviewSubmitData } from '../../components/CartModule/contract';
import { getParam } from '../../util/common';
import quoteTypes from '../types/quote.types';
import { changeCartStatusAction } from './cart.actions';
import { closeToast, showToast } from './message.actions';

const toast = (state, title, subtitle) => (dispatch) => {
	dispatch(
		showToast({
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: () => {
				dispatch(closeToast());
			},
			state,
			title,
			subtitle,
			showActions: false,
		})
	);
};
export const getQuoteData = (quotation_id) => async (dispatch) => {
	try {
		const response = await getQuoteApi(quotation_id);
		if (response?.data?.data) {
			dispatch({
				data: response?.data?.data,
				type: quoteTypes.GET_QUOTE,
			});
		} else {
			dispatch({
				data: reviewSubmitData,
				type: quoteTypes.GET_QUOTE,
			});
			dispatch(toast('error', 'Error', 'Error while fetching quote data'));
		}
	} catch (error) {
		dispatch({
			data: reviewSubmitData,
			type: quoteTypes.GET_QUOTE,
		});
		dispatch(toast('error', 'Error', 'Error while fetching quote data'));
		console.error('getQuoteApi Error', error);
	}
};

export const saveSectionChanges = (data, section) => async (dispatch) => {
	try {
		let payload = section;
		if (section.multi_select) {
			payload = {};
			payload[section.section] = data
				.filter((_data) => _data.is_selected)
				.map((_data) => _data.label);
		}
		switch (section.section) {
			case 'notes':
				payload = {
					notes: {
						is_sharing_enabled: data?.checkBox,
						text: data?.textVal,
					},
				};
				break;
			default:
				break;
		}
		const response = await updateQuote(payload);
		if (response && response?.data) {
			const quotation_id = getParam('quotation_id');
			dispatch(getQuoteData(quotation_id));
		}
	} catch (error) {
		dispatch(
			toast(
				'error',
				'Error',
				'Error while fetching quote data',
				`Error while saving ${section.name}`
			)
		);
	}
};

export const createQuotationAction = (data, cb) => async (dispatch) => {
	try {
		const response = await confirmQuote(data);
		dispatch(changeCartStatusAction(() => {}, 'acknowledged'));
		if (!response?.data) {
			dispatch(
				toast(
					'error',
					'Error',
					'Error while confirming quote',
					`Error while confirming quote`
				)
			);
		} else {
			dispatch(toast('success', 'Success', 'Successfully Confirmed quote'));
			cb && cb();
		}
	} catch (error) {
		dispatch(
			toast(
				'error',
				'Error',
				'Error while confirming quote',
				`Error while confirming quote`
			)
		);
	}
};
