import { ResetPasswordConfimation as ResetPasswordConfimationUI } from '@sourcewiz/the-source';
import { useState } from 'react';
import { Navigate } from 'react-router-dom/dist';

const ResetPasswordConfirmation = () => {
	const [redirect, setRedirect] = useState(false);

	if (redirect) {
		return <Navigate to="/" />;
	}

	return (
		<ResetPasswordConfimationUI
			logoUrl="https://uploads-ssl.webflow.com/63e4eec68738ce8318cbab16/63fcd32306971477fb638c84_New%20logo-%20Satoshi%20font.svg"
			confirmationText="Your password has been reset successfully"
			onSubmit={() => setRedirect(true)}
			illustrationImg="https://sourcerer.tech/assets/043dc1f4-d99f-4811-8e5b-81d521586338"
			submitBtnText="Login with new password"
		/>
	);
};

export default ResetPasswordConfirmation;
