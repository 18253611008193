import { ForgotPassword as ForgotPasswordTheSource } from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
	forgotPasswordAction,
	forgotPasswordEmailAction,
} from '../../../store/actions/login.actions';
import { closeToast, showToast } from '../../../store/actions/message.actions';
import { validateEmail } from '../../../util/common';
export default function ForgotPassword() {
	const login = useSelector((state) => state.login);
	const [emailValidation, setValidation] = useState(false);
	const [mailSent, setMailSent] = useState(false);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		if (login.forgotPasswordEmail.length === 0) {
			setValidation(false);
		} else {
			setValidation(!validateEmail(login.forgotPasswordEmail));
		}
	}, [login.forgotPasswordEmail]);

	const dispatch = useDispatch();

	const onEmailChange = (event) => {
		dispatch(forgotPasswordEmailAction(event.target.value));
	};

	const supportEmailClick = () => {
		window.location = 'mailto:support@sourcewiz.co';
	};

	const onSubmit = () => {
		const data = {
			email: login.forgotPasswordEmail,
		};
		setValidation(!validateEmail(login.forgotPasswordEmail));
		if (!validateEmail(login.forgotPasswordEmail)) {
			return dispatch(
				showToast({
					open: true,
					showCross: false,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					autoHideDuration: 5000,
					onClose: (event, reason) => {
						if (reason === 'clickaway') {
							return;
						}
						dispatch(closeToast());
					},
					state: 'error',
					title: 'Error',
					subtitle:
						login.forgotPasswordEmail.length === 0
							? 'Field cannot be empty'
							: 'Email is not valid',
					showActions: false,
				})
			);
		}
		dispatch(forgotPasswordAction(data));
		setMailSent(true);
	};
	const backToLogin = () => {
		setRedirect(true);
	};
	if (redirect) {
		return <Navigate to="/" />;
	}
	return (
		<ForgotPasswordTheSource
			email={login.forgotPasswordEmail}
			companyDesc="A link to reset your password will be shared to your email"
			logoUrl="https://uploads-ssl.webflow.com/63e4eec68738ce8318cbab16/63fcd32306971477fb638c84_New%20logo-%20Satoshi%20font.svg"
			emailError={emailValidation}
			onEmailChange={onEmailChange}
			supportEmail="support@sourcewiz.co"
			supportEmailClick={supportEmailClick}
			disabled={emailValidation}
			onSubmit={onSubmit}
			illustrationImg="https://sourcerer.tech/assets/043dc1f4-d99f-4811-8e5b-81d521586338"
			submitText={mailSent ? 'Resend Link' : 'Send Reset Link'}
			backToLogin={backToLogin}
		/>
	);
}
