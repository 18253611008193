import { Button, Drawer, Grid, Icon, Typography } from '@sourcewiz/the-source';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getQuickSettings,
	openQuickSettings,
	updateSettingsApi,
} from '../../store/actions/quickSettings.actions';
import data from './data';
import styles from './quickSettings.module.css';
import QuickSettingsContent from './QuickSettingsContent';
export default function QuickSettings(props) {
	const quickSettings = useSelector((state) => state.quickSettings);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		dispatch(openQuickSettings(false));
	};
	useEffect(() => {
		dispatch(getQuickSettings());
	}, []);
	const saveQuickSettings = () => {
		dispatch(updateSettingsApi());
	};
	const renderDrawerData = () => {
		return (
			<Grid container className={styles.quickSettingsBody}>
				<Grid xs={12} className={styles.header}>
					<Grid
						xs={12}
						item
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<Typography variant="h5">Quick settings</Typography>
						<Icon
							iconName="IconX"
							sx={{ cursor: 'pointer' }}
							onClick={() => closeDrawer()}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						className={styles.headerSubheading}
						sx={{ marginTop: '7px' }}>
						<Typography
							color="rgba(0, 0, 0, 0.6)"
							sx={{ fontWeight: '400' }}
							variant={'body2'}>
							Selection will be applied to all products
						</Typography>
					</Grid>
				</Grid>
				<QuickSettingsContent data={data} />
				<Grid className={styles.applyGrid} item xs={12}>
					<Button
						fullwidth
						className={styles.applyBtn}
						onClick={saveQuickSettings}>
						Apply
					</Button>
				</Grid>
			</Grid>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={quickSettings.open}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
			{...props}
		/>
	);
}
