import {
	createQuote,
	deleteCartProduct,
	getCart,
	getCartBulkApi,
	updateCart,
	updateCartMeta,
} from '../../api/handlers/cart';
import { mockedContract } from '../../components/CartModule/contract';
import cartActionTypes from '../types/cart.types';
import { closeToast, showToast } from './message.actions';

export const toast = (state, title, subtitle) => (dispatch) => {
	dispatch(
		showToast({
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: () => {
				dispatch(closeToast());
			},
			state,
			title,
			subtitle,
			showActions: false,
		})
	);
};

export const showHideNotesDrawer = (data) => (dispatch) => {
	dispatch({
		type: cartActionTypes.OPEN_NOTES,
		data,
	});
};

export const getCartAction = (param) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const response = await getCart(param);
		if (response?.data?.data) {
			// dispatch(setCartData(mockedContract));
			dispatch(setCartData(response.data.data));
		}
	} catch (error) {
		console.error('get_cart_error', error);
		dispatch(setCartData(mockedContract));
		dispatch(toast('error', 'Error', 'Error in fetching cart data'));
	} finally {
		dispatch(setLoading(false));
	}
};

export const showBillingAddress = (show) => (dispatch) => {
	dispatch({ type: cartActionTypes.OPEN_BILLING_ADDRESS, show });
};
export const showShippingAddress = (show) => (dispatch) => {
	dispatch({ type: cartActionTypes.OPEN_SHIPPING_ADDRESS, show });
};

export const showAddresses = (data) => (dispatch) => {
	dispatch({ type: cartActionTypes.SHOW_ADDRESSES, data });
};

export const showAddAddress = (data) => (dispatch) => {
	dispatch({ type: cartActionTypes.ADD_ADDRESS, data });
};

export const showSelectUserModal = (data) => (dispatch) => {
	dispatch({ type: cartActionTypes.SELECT_USER, data });
};

export const showCreateAccountDrawer = (data) => (dispatch) => {
	dispatch({ type: cartActionTypes.CREATE_ACCOUNT_DRAWER, data });
};

export const setCartData = (data) => (dispatch) => {
	dispatch({ type: cartActionTypes.SET_CART_DATA, data });
};

export const setLoading = (loading) => (dispatch) => {
	dispatch({ type: cartActionTypes.SET_LOADING, loading });
};

export const viewCartSummary = (show) => (dispatch) => {
	dispatch({ type: cartActionTypes.VIEW_CART_SUMMARY, show });
};

export const addRemoveTaxes = () => (dispatch, getState) => {
	const taxes = getState().taxes;
	dispatch({
		type: cartActionTypes.ADD_TAXES,
		taxes,
	});
};

export const removeAtaxFromDrawer = (index) => (dispatch) => {
	dispatch({
		type: cartActionTypes.REMOVE_A_TAX_FROM_DRAWER,
		index,
	});
};

export const taxChange = (index, name, value) => (dispatch) => {
	const data = { index, name, value };
	dispatch({
		type: cartActionTypes.TAXES_CHANGE,
		data,
	});
};

export const removeAllTaxes = (cart_id) => async (dispatch) => {
	const payload = { type: 'taxes', data: [] };
	try {
		const response = await updateCartMeta(payload, cart_id);
		if (response?.status === 200) {
			dispatch({ type: cartActionTypes.REMOVE_ALL_TAXES });
			dispatch(getCartAction());
		}
	} catch (error) {
		dispatch({ type: cartActionTypes.REMOVE_ALL_TAXES });
		console.error('removeAllTaxes', error);
	}
};

export const addTaxesToCart = (data, cart_id) => async (dispatch, getState) => {
	let data2 = data.filter((item) => item.name && item.value);
	data2 = data2.map((item) => ({ text: item.name, value: item.value }));
	try {
		const cart = getState()?.cart?.data;
		const taxes = cart?.cart?.taxes?.data || [];
		const payload = { type: 'taxes', data: [...taxes, ...data2] };
		const response = await updateCartMeta(payload, cart_id || cart?.id);
		if (response?.status === 200) {
			dispatch({
				type: cartActionTypes.SET_TAXES_TO_CART,
				data: data2,
			});
			dispatch(showHideTaxesModal({ show: false }));
			dispatch(getCartAction());
		}
	} catch (error) {
		dispatch({
			type: cartActionTypes.SET_TAXES_TO_CART,
			data: data2,
		});
		dispatch(showHideTaxesModal({ show: false }));
		console.error(error);
	}
};

export const updateDiscount = (data, cart_id) => async (dispatch, getState) => {
	try {
		const payload = { type: 'discount', data };
		const cart = getState()?.cart?.data;
		const response = await updateCartMeta(payload, cart_id || cart?.id);
		if (response?.status === 200) {
			dispatch(getCartAction());
			dispatch({
				type: cartActionTypes.UPDATE_DISCOUNT,
				data: data,
			});
		}
	} catch (error) {
		console.error(error);
		dispatch({
			type: cartActionTypes.UPDATE_DISCOUNT,
			data: data,
		});
	}
};

export const showHideTaxesModal = (data) => (dispatch) => {
	dispatch({
		type: cartActionTypes.OPEN_TAXES_MODAL,
		data,
	});
};

export const showHideDiscountModal = (data) => (dispatch) => {
	dispatch({
		type: cartActionTypes.SHOW_DISCOUNT_MODAL,
		data,
	});
};

export const changeDiscount = (data) => (dispatch) => {
	dispatch({
		type: cartActionTypes.DISCOUNT_CHANGE,
		data,
	});
};

export const productChange = (data) => async (dispatch, getState) => {
	try {
		const cart = getState()?.cart?.data;
		const payload = {
			cart_id: cart?.id,
			entities: [
				{
					entity_id: data.product.entity_id,
					quantity: data.currentVal,
				},
			],
		};
		const response = await updateCart(payload);
		if (response?.status === 200) {
			dispatch({
				type: cartActionTypes.PRODUCT_CHANGE,
				data: payload,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const productToCartAddAction =
	(id, entity_id, value, action) => async (dispatch, getState) => {
		const cart = getState()?.cart?.data;

		const data = {
			cart_id: cart?.id,
			entities: [
				{
					entity_id: entity_id,
					action,
				},
			],
		};
		try {
			const response = await updateCart(data);
			if (response?.status === 200) {
				dispatch({
					type: cartActionTypes.PRODUCT_TO_CART_ADD_ACTION,
					id,
					value,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

export const changeDiscountType = (data) => (dispatch) => {
	dispatch({
		type: cartActionTypes.DISCOUNT_TYPE_CHANGE,
		data,
	});
};

export const removeATax = (index) => (dispatch, getState) => {
	const taxes = getState()?.cart?.taxes?.taxes || [];

	dispatch({
		type: cartActionTypes.REMOVE_A_TAX,
		index,
	});
	dispatch(addTaxesToCart(taxes));
};

export const removeADiscountAction = (index) => async (dispatch) => {
	dispatch({
		type: cartActionTypes.REMOVE_A_DISCOUNT,
		index,
	});
	dispatch(updateDiscount([]));
};

export const removeAllProducts = () => async (dispatch, getState) => {
	try {
		const cart = getState()?.cart?.data;
		const ids = cart.entities.map((entity) => entity.id);
		const response = await deleteCartProduct({
			cart_id: cart?.id,
			entities: ids,
		});
		dispatch({
			type: cartActionTypes.REMOVE_ALL_PRODUCTS,
		});
		if (response?.data) {
			dispatch(toast('success', 'Success', 'Removed all products'));
		}
	} catch (error) {
		dispatch({
			type: cartActionTypes.REMOVE_ALL_PRODUCTS,
		});
	}
};

export const removeAPRoductFromDetailsPage =
	(id) => async (dispatch, getState) => {
		const cart = getState()?.cart?.data;
		const response = await deleteCartProduct({
			cart_id: cart?.id,
			entities: [id],
		});
		if (response?.status !== 200) {
			console.error(response);
		}
	};

export const removeAPRoductFromCart = (index, id) => (dispatch, getState) => {
	const cart = getState()?.cart?.data;
	deleteCartProduct({ cart_id: cart?.id, entities: [id] })
		.then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: cartActionTypes.REMOVE_A_PRODUCT_FROM_CART,
					index,
				});
				dispatch(getCartAction());
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: cartActionTypes.REMOVE_A_PRODUCT_FROM_CART,
				index,
			});
		});
};

export const setBulkEditData = () => {};

export const buildEditSave = () => {};

export const getCartDataBulk = () => async () => {
	const data = await getCartBulkApi();

	if (data?.status !== 200) {
		console.error(data);
	}
};

export const addProductToCartAction =
	(item, value) => async (dispatch, getState) => {
		const cart = getState()?.cart?.data;
		const cart_id = cart?.id;
		const payload = {
			cart_id,
			entities: [
				{
					entity_id: item?.id,
					quantity: value,
				},
			],
		};
		try {
			const response = await updateCart(payload);
			if (response?.status !== 200) {
				console.error(response);
			}
		} catch (error) {
			console.error('editNote-->', error);
		}
	};

export const addCustomizedProductAction =
	(id, value, price, message) => async (dispatch, getState) => {
		const cart = getState()?.cart?.data;
		const cart_id = cart?.id;
		const payload = {
			cart_id,
			entities: [
				{
					entity_id: id,
					meta_data: { quantity: value, price, message },
				},
			],
		};
		try {
			const response = await updateCart(payload);
			if (response?.status !== 200) {
				console.error(response);
			}
		} catch (error) {
			console.error('editNote-->', error);
		}
	};

export const editNote =
	(index, product_id, value, meta_data) => async (dispatch, getState) => {
		const cart = getState()?.cart?.data;
		const data = {
			cart_id: cart.id,
			entities: [
				{
					entity_id: product_id,
					meta_data: { ...meta_data, message: value },
				},
			],
		};
		dispatch({
			type: cartActionTypes.UPDATE_NOTE,
			index,
			value,
		});
		try {
			const response = await updateCart(data);
			if (response?.status !== 200) {
				console.error(response);
			}
		} catch (error) {
			console.error('editNote-->', error);
		}
	};

export const createQuoteAction = (cb) => async (dispatch, getState) => {
	const cart = getState()?.cart?.data;
	const cart_id = cart?.id;
	try {
		const response = await createQuote({
			cart_id,
			buyer_id: '64723938-c5c6-2b35-9f14-e42340d64167',
		});
		const quotation_id = response?.data?.data?.id;
		if (quotation_id) {
			cb && cb(quotation_id);
		}
	} catch (error) {
		dispatch(toast('error', 'Error', 'Error while creating quote'));
		console.log('createQuoteAction', error);
	}
};

export const changeCartStatusAction =
	(cb, status = 'draft') =>
	async (dispatch, getState) => {
		const cart = getState()?.cart?.data;
		const cart_id = cart?.id;
		try {
			const response = await updateCart({
				cart_id,
				status,
			});
			const quotation_id = response?.data?.data?.id;
			if (quotation_id) {
				cb && cb(quotation_id);
			}
		} catch (error) {
			dispatch(toast('error', 'Error', 'Error while creating quote'));
			console.log('createQuoteAction', error);
		}
	};
