import PropTypes from 'prop-types';
import { Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';

import styles from '../ReviewSubmit.module.css';
import Address from './Address';

export default function CustomerInformation({ section }) {
	const { name } = section;
	const customerName = section?.attributes?.find((attr) => attr.key === 'name');
	const email = section?.attributes?.find((attr) => attr.key === 'email');
	const location = section?.attributes?.find(
		(attr) => attr.key === 'tenant_country'
	);
	const companyName = section?.attributes?.find(
		(attr) => attr.key === 'tenant_name'
	);
	const addresses = section.section_data;
	return (
		<Grid container className={styles.card}>
			<Grid xs={12}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
					{name}
				</Typography>
			</Grid>
			<Grid xs={12} className={styles.customerInfoGrid1}>
				<Grid xs={6} className={styles.customerInfoGrid2}>
					<Grid xs={12} sx={{ marginTop: '0.5rem' }}>
						<Typography variant="body2">{customerName?.text}</Typography>
					</Grid>
					<Grid xs={12} sx={{ marginTop: '0.5rem' }}>
						<Typography color="rgba(0, 0, 0, 0.7)" variant="subtitle2">
							{email?.text}
						</Typography>
					</Grid>
				</Grid>
				<Grid xs={6} className={styles.customerInfoGrid3}>
					<Grid xs={12} sx={{ marginTop: '0.5rem' }}>
						<Typography variant="body2">{companyName?.text}</Typography>
					</Grid>
					<Grid xs={12} sx={{ marginTop: '0.5rem' }}>
						<Typography color="rgba(0, 0, 0, 0.7)" variant="subtitle2">
							{location?.text}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			{addresses.map((add, index) => {
				return (
					<Grid xs={12} key={index + 1}>
						<Address address={add} />
					</Grid>
				);
			})}
		</Grid>
	);
}

CustomerInformation.propTypes = {
	section: PropTypes.shape({
		attributes: PropTypes.shape({
			find: PropTypes.func,
		}),
		name: PropTypes.any,
		section_data: PropTypes.shape({
			map: PropTypes.func,
		}),
	}).isRequired,
};
