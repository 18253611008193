import { Drawer } from '@sourcewiz/the-source';
import { useDispatch, useSelector } from 'react-redux';

import { viewCartSummary } from '../../../../store/actions/cart.actions';
import CartRight from '../../CartRight/CartRight';
export default function CartRightDrawer(props) {
	const cart = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		dispatch(viewCartSummary({ show: false }));
	};

	const renderDrawerData = () => {
		return <CartRight fromDrawer />;
	};

	return (
		<Drawer
			style={{
				margin: '0px',
			}}
			anchor="right"
			open={cart.viewCartSummary}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
			{...props}
		/>
	);
}
