import PropTypes from 'prop-types';
import {
	Avatar,
	Button,
	Checkbox,
	Datetime,
	Grid,
	Icon,
	Input,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createQuotationAction } from '../../../../store/actions/quote.action';
import styles from '../ReviewSubmit.module.css';
import { useNavigate } from 'react-router-dom';
export default function ReviewCartRightBody({
	className,
	fromDrawer,
	closeDrawer,
}) {
	const cart = useSelector((state) => state.cart.data);
	const billingData = useSelector((state) => state?.quote?.billing_details);
	const navigate = useNavigate();
	const [localState, setState] = useState({ ...billingData });
	const dispatch = useDispatch();
	const fieldValueChange = (event, index) => {
		const value = event.target.value;
		const newState = { ...localState, attributes: [...localState.attributes] };
		newState.attributes[index].value = value;
		setState(newState);
	};
	const dateChange = (value, index) => {
		const newState = { ...localState, attributes: [...localState.attributes] };
		newState.attributes[index].value = value;
		setState(newState);
	};
	const confirmQuote = () => {
		const payload = {};
		localState?.attributes?.forEach((att) => {
			payload[att.key] = att.value;
		});
		dispatch(createQuotationAction(payload, () => navigate('/all-products')));
	};

	useEffect(() => {
		setState(billingData);
	}, [billingData]);

	const inputRenderer = (attribute, index) => {
		if (attribute.dtype === 'text') {
			return (
				<Grid item xs={12} className={styles.Input}>
					<Input
						variant="outlined"
						sx={{ width: '100%' }}
						key={attribute.key}
						value={attribute.value}
						onChange={(event) => fieldValueChange(event, index)}
						placeholder={attribute.place_holder}
					/>
				</Grid>
			);
		}
		if (attribute.dtype === 'Date') {
			return (
				<Grid item xs={12} className={styles.Input}>
					<Datetime
						label={attribute.place_holder}
						value={attribute.value}
						onChange={(event) => dateChange(event, index)}
						sx={{ width: '100%' }}
						InputProps={{
							startAdornment: <>{attribute.place_holder}</>,
							endAdornment: <>....</>,
						}}
					/>
				</Grid>
			);
		}
	};
	console.log('billingData', billingData);
	return (
		<Grid className={styles.cartRight + ' ' + (className || '')} container>
			{fromDrawer && (
				<Avatar
					onClick={closeDrawer}
					size="medium"
					variant="circular"
					isImageAvatar={false}
					backgroundColor="#ede9e9"
					content={<Icon iconName="IconChevronLeft" />}
				/>
			)}
			<Grid mb={'1rem'}>
				<Typography variant="h6" sx={{ fontWeight: '700' }}>
					{billingData?.heading}
				</Typography>
			</Grid>
			{!localState?.attributes && <Grid sx={{ height: '300px' }}></Grid>}
			{localState?.attributes?.map(inputRenderer)}

			<Grid item xs={12}>
				<hr className={styles.dashed}></hr>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography color="rgba(0, 0, 0, 0.7)" variant="body2">
						Cart Total
					</Typography>
				</Grid>
				<Grid>
					<Typography color="rgba(0, 0, 0, 0.7)" variant="body2">
						{cart?.currency}{' '}
						{cart?.cart?.total_amount ? cart?.cart?.total_amount : '0'}
					</Typography>
				</Grid>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography color="rgba(0, 0, 0, 0.7)" variant="body2">
						Discount
					</Typography>
				</Grid>
				<Grid>
					<Typography color="#D74C10" variant="body2">
						- {' ' + (cart?.currency || '')}{' '}
						{0 || cart?.cart?.summary?.discount}
					</Typography>
				</Grid>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography color="rgba(0, 0, 0, 0.7)" variant="body2">
						Taxes
					</Typography>
				</Grid>
				<Grid>
					<Typography color="rgba(0, 0, 0, 0.7)" variant="body2">
						{cart?.currency} {cart?.cart?.summary?.total_taxes}
					</Typography>
				</Grid>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
						Sub Total{' '}
					</Typography>
				</Grid>
				<Grid>
					<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
						{cart?.currency} {cart?.cart?.summary?.subtotal}
					</Typography>
				</Grid>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography variant="body2" color="rgba(0, 0, 0, 0.7)">
						Regular shipping
					</Typography>
				</Grid>
				<Grid>
					<Typography variant="body2" color="rgba(0, 0, 0, 0.7)">
						$ 400
					</Typography>
				</Grid>
			</Grid>
			<Grid className={styles.spaceBetween} item xs={12}>
				<Grid>
					<Typography variant="subtitle" sx={{ fontWeight: 'bold' }}>
						Grand Total
					</Typography>
				</Grid>
				<Grid>
					<Typography variant="subtitle" sx={{ fontWeight: 'bold' }}>
						{cart?.currency} {cart?.cart?.summary?.grand_total}
					</Typography>
				</Grid>
			</Grid>
			<Grid item className={styles.spaceBetween2} xs={12}>
				<Checkbox />
				<Grid>
					<Typography variant="body2" color="rgba(0, 0, 0, 0.7)">
						Send quotation to customer via email
					</Typography>
				</Grid>
			</Grid>
			<Grid item className={styles.spaceBetween} xs={12}>
				<Button fullWidth onClick={confirmQuote}>
					Confirm quote
				</Button>
			</Grid>
		</Grid>
	);
}

ReviewCartRightBody.propTypes = {
	className: PropTypes.string.isRequired,
	closeDrawer: PropTypes.any.isRequired,
	fromDrawer: PropTypes.any.isRequired,
};
