import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ROUTES } from '../constants';
import history from '../util/history';
import {
	ForgotPassword,
	Login,
	ResetPassword,
	ResetPasswordConfirmation,
} from './Auth';

export default function PublicRoutes() {
	return (
		<Router history={history}>
			<Routes>
				<Route path={ROUTES.AUTH.LOGIN} element={<Login />} />
				<Route
					path={ROUTES.AUTH.FORGOT_PASSWORD_CHANGE_EMAIL}
					element={<ForgotPassword />}
				/>
				<Route path={ROUTES.AUTH.RESET_PASSWORD} element={<ResetPassword />} />
				<Route
					path={ROUTES.AUTH.RESET_PASSWORD_SUCCESS}
					element={<ResetPasswordConfirmation />}
				/>
			</Routes>
		</Router>
	);
}
