import PropTypes from 'prop-types';
import { Grid, Stepper, Typography } from '@sourcewiz/the-source';
import React from 'react';

import style from './CartHeader.module.css';

export default function CartHeader({ activeStep = 0 }) {
	return (
		<Grid className={style.headerStyle}>
			<Stepper
				steps={[
					{
						label: <Typography variant="subtitle1">Cart</Typography>,
					},
					{
						label: <Typography variant="subtitle1">Review & Submit</Typography>,
					},
				]}
				activeStep={activeStep}
			/>
		</Grid>
	);
}

CartHeader.propTypes = {
	activeStep: PropTypes.number.isRequired,
};
