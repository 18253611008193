import data from '../../components/QuickSettings/data';
import quickSettingsTypes from '../types/quickSettings.types';
const INITIAL_STATE = {
	open: false,
	settings: data,
};

const quickSettingsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case quickSettingsTypes.OPEN_QUICK_SETTINGS:
			return { ...state, open: action.open };
		case quickSettingsTypes.UPDATE_QUICK_SETTINGS:
			return { ...state, settings: { ...action.data } };
		default:
			return state;
	}
};
export default quickSettingsReducer;
