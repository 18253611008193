import {
	Box,
	Button,
	Chip,
	Grid,
	Icon,
	PageHeader,
	Tabs,
	Typography,
} from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants';
import AccountData from './accountDetails.json';
import AccountsLisiting from './AccountsLisiting';
import AddressDetails from './AddressDetails';
import BasicDetails from './BasicDetails';
import OtherDetails from './OtherDetails';
import Preferences from './Preferences';

export default function AccountDetails() {
	const [accountsData, setAccountsData] = useState({});
	const [tabValue, setTabValue] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		setAccountsData(AccountData);
	}, []);

	const sectionsByPriority = accountsData?.sections?.sort(
		(firstElem, secondElem) => firstElem?.priority - secondElem?.priority
	);

	const handleChange = (index) => {
		setTabValue(index);
	};

	const renderAccountsData = (value) => {
		const data = sectionsByPriority?.[value];
		const renderData = {
			basic_details: <BasicDetails data={data} />,
			address: <AddressDetails data={data} />,
			preferences: <Preferences data={data} />,
			other_details: <OtherDetails data={data} />,
			default: <Box />,
		};
		return renderData[data?.key] || renderData.default;
	};

	const handleEdit = (id) => {
		navigate(`/edit-account/${id}`);
	};

	return (
		<Box>
			<Box mb="2em">
				<PageHeader
					leftSection={<Typography variant="h6">Accounts</Typography>}
					rightSection={
						<Button
							onClick={() => navigate(ROUTES.ACCOUNT.CREATE_ACCOUNT)}
							variant="contained">
							+Account
						</Button>
					}
				/>
				<Box
					my={4}
					sx={{
						background: '#fff',
						height: '90vh',
						borderRadius: '8px',
					}}>
					<Grid container spacing={2}>
						<Grid sx={{ height: '90vh', overflowY: 'scroll' }} item xs={4}>
							<Box px={2.5} py={0.5}>
								<AccountsLisiting />
							</Box>
						</Grid>
						<Grid item xs={7} ml={4}>
							<Grid>
								<Grid container>
									<Grid item mt={1.5}>
										{accountsData?.tags &&
											accountsData?.tags?.map((item) => (
												<Chip
													key={item?.id}
													bgColor={item?.backgroundColor}
													textColor={item?.textColor}
													label={item?.name}
												/>
											))}
									</Grid>
									<Grid item ml="auto" mt={1.5}>
										<Grid container>
											<Grid item sx={{ cursor: 'pointer' }}>
												<Icon iconName="IconTrash" size="medium" />
											</Grid>
											<Grid item sx={{ cursor: 'pointer' }} ml={1}>
												<Icon
													onClick={() => handleEdit(accountsData?.id)}
													iconName="IconEdit"
													size="medium"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Box>
									{accountsData?.name && (
										<Box mt={1.5}>
											<Typography variant="h5">{accountsData?.name}</Typography>
										</Box>
									)}
								</Box>
								<Box>
									{accountsData?.account_id && (
										<Box mt={1}>
											<Typography variant="body1">
												ID: {accountsData?.account_id}
											</Typography>
										</Box>
									)}
								</Box>
								<Box>
									<Box
										marginTop={3}
										width="100%"
										sx={{
											backgroundColor: '#fff',
											borderRadius: '20px',
										}}>
										<Tabs
											label="Accounts"
											value={tabValue}
											handleChange={handleChange}
											noOftabs={sectionsByPriority}>
											<Box
												paddingX={1}
												sx={{ height: '60vh', overflowY: 'scroll' }}>
												{renderAccountsData(tabValue)}
											</Box>
										</Tabs>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}
