import { AddressCard, Box, Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';

const AddressDetails = ({ data }) => {
	const mainAddresses = data?.addresses?.filter(
		(item) => item?.section === 'main'
	);
	const otherAddresses = data?.addresses?.filter(
		(item) => item?.section === 'others'
	);
	return (
		<Box>
			<Grid mt={1} container spacing={2}>
				{mainAddresses?.map((address) => (
					<Grid key={address?.id} item xs={6}>
						<AddressCard data={address} />
					</Grid>
				))}
			</Grid>
			<Grid mt={6}>
				<Typography variant="h6">Others</Typography>
			</Grid>
			<Grid mt={0.5} container spacing={2}>
				{otherAddresses?.map((address) => (
					<Grid key={address?.id} item xs={6}>
						<AddressCard data={address} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default AddressDetails;
