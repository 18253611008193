import { Grid } from '@sourcewiz/the-source';

import CartHeader from '../CartHeader/CartHeader';
import styles from './CartSkeleton.module.css';
import CartSkeletonBody from './CartSkeletonBody';
import CartSkeletonRight from './CartSkeletonRight';
export default function CartSkeleton() {
	return (
		<Grid style={{ maxWidth: '1250px', margin: '0px auto' }}>
			<Grid container className={styles.container}>
				<Grid item md={12}>
					<CartHeader />
				</Grid>
				<Grid item sm={12} xs={12} md={8}>
					<CartSkeletonBody />
				</Grid>
				<Grid item sm={0} xs={0} md={4}>
					<CartSkeletonRight />
				</Grid>
			</Grid>
		</Grid>
	);
}
