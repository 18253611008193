import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { getData } from '../../../../api/handlers/quote';
import SelectAddress from '../../../SelectAddress/SelectAddress';
import { mockedAddressList } from '../../contract';
import styles from '../ReviewSubmit.module.css';

export default function Address({ address }) {
	const name = address?.name;
	const type = address?.key;
	const { key } = address;
	const [selectAddress, setSelectAddress] = useState(false);
	const [addressData, setAddress] = useState(null);
	const actionClick = () => {
		setSelectAddress(true);
	};
	const getAddress = () => {
		getData(key)
			.then((res) => {
				setAddress(res?.data?.data);
			})
			.catch((error) => {
				console.error(error);
				setAddress(mockedAddressList);
			});
	};
	useEffect(() => {
		getAddress();
	}, []);

	return (
		<>
			<Grid container className={styles.addressContainer}>
				<Grid className={styles.addressTitle}>
					<Typography
						variant="subtitle2"
						color="black"
						sx={{ fontWeight: 'bold' }}>
						{name}
					</Typography>
				</Grid>
				<Grid className={styles.address}>
					{address.value.map((attr, index) => (
						<Typography
							key={index + 1}
							color="rgba(0, 0, 0, 0.87)"
							variant="body2">
							{attr}
						</Typography>
					))}
				</Grid>
				<Grid className={styles.actionBtn}>
					<Button onClick={actionClick} variant="text">
						Change
					</Button>
				</Grid>
			</Grid>
			<SelectAddress
				open={selectAddress}
				close={() => {
					setSelectAddress(false);
				}}
				getAddress={getAddress}
				type={type}
				address={addressData}
				name={name}
				selectedAddress={address}
			/>
		</>
	);
}

Address.propTypes = {
	address: PropTypes.shape({
		key: PropTypes.any,
		name: PropTypes.any,
		value: PropTypes.shape({
			map: PropTypes.func,
		}),
	}).isRequired,
};
