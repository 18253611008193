import PropTypes from 'prop-types';
import './ProductDetails.css';

import { Box, Drawer, Grid, Icon, Typography } from '@sourcewiz/the-source';
import React from 'react';

export default function VolumePricingDrawer({
	pricingModalOpen,
	setPricingModalOpen,
	pricingList = [],
	setData,
}) {
	const handlePriceItem = (priceItem) => {
		setData((state) => ({ ...state, sub_heading: priceItem?.price }));
		setPricingModalOpen(false);
	};

	const content = (
		<Box height="100vh" sx={{ background: '#fff' }} padding={2}>
			<Grid marginBottom="2em" container width="100%">
				<Grid item>
					<Typography variant="h6">Volume Pricing</Typography>
				</Grid>
				<Grid
					item
					alignItems="center"
					justifyContent="space-between"
					marginLeft="auto"
				>
					<Icon
						iconName="IconX"
						className="pdp_pointer"
						onClick={() => setPricingModalOpen(false)}
					/>
				</Grid>
			</Grid>
			<Grid>
				{pricingList?.map((priceItem, index) => (
					<Grid
						onClick={() => handlePriceItem(priceItem)}
						container
						className="pdp_pricelist"
						key={index}
					>
						<Grid item xs={10}>
							{priceItem?.range}
						</Grid>
						<Grid item>{priceItem?.price}</Grid>
					</Grid>
				))}
			</Grid>
		</Box>
	);

	return (
		<Drawer
			anchor="right"
			open={pricingModalOpen}
			content={content}
			onClose={() => setPricingModalOpen(false)}
		/>
	);
}

VolumePricingDrawer.propTypes = {
	pricingList: PropTypes.array.isRequired,
	pricingModalOpen: PropTypes.any.isRequired,
	setData: PropTypes.func.isRequired,
	setPricingModalOpen: PropTypes.func.isRequired,
};
