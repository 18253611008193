import React from 'react';

import AddNotes from '../CartDrawers/AddNotes/AddNotes';
import AddTaxes from './AddTaxes/AddTaxes';
import CartRightDrawer from './CartRightDrawer/CartRightDrawer';
import RegisterAccount from './RegisterAccount/RegisterAccount';

export default function CartDrawers() {
	return (
		<>
			<AddTaxes />
			<RegisterAccount />
			<AddNotes edit />
			<CartRightDrawer />
		</>
	);
}
