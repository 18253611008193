import './ProductDetails.css';

import {
	Accordion,
	Avatar,
	Box,
	Button,
	Chip,
	Counter,
	DashedAccordion,
	Grid,
	Icon,
	ImageCarousel,
	PageHeader,
	Toaster,
	Typography,
	VariantCard,
} from '@sourcewiz/the-source';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	addCustomizedProductAction,
	addProductToCartAction,
	removeAPRoductFromDetailsPage,
} from '../../store/actions/cart.actions';
import { renderFieldsByDType, sortByPriority } from '../../util/common';
import CustomiseContentDrawer from './CustomiseContentDrawer';
import { LeftSection, RightSection } from './HeaderComponent';
import ReadMore from './ReadMore';
import VolumePricingDrawer from './VolumePricingDrawer';

const ProductDetailSection = ({ data, setData }) => {
	const [isCustomiseModalOpen, setIsCustomiseModalOpen] = useState(false);
	const [pricingModalOpen, setPricingModalOpen] = useState(false);
	const [textVal, setTextVal] = useState('');
	const [open, setOpen] = useState(false);
	const [customizeCount, setCustomize] = useState(1);
	const [customPrice, setCustomPrice] = useState(0);
	const navigate = useNavigate();

	const handleClose = () => {
		setOpen(false);
	};

	const onCustomizeProductCounterChange = (count) => {
		setCustomize(count);
	};

	const content = data?.sections?.map((item) => ({
		title: item?.name,
		expandedContent: (
			<Box key={item?.name} className="pdp_accordion_content">
				{item?.attributes?.map((attrs) => (
					<Grid key={attrs?.key} container marginBottom={2}>
						<Grid item xs={3}>
							<Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
								{attrs?.label}
							</Typography>
						</Grid>
						{attrs?.dtype === 'html' ? (
							<Grid item>
								<div
									dangerouslySetInnerHTML={{
										__html: renderFieldsByDType(attrs?.dtype, attrs?.value),
									}}></div>
							</Grid>
						) : (
							<Grid item xs={9}>
								<Typography variant="body2" color="rgba(0, 0, 0, 0.8)">
									{attrs?.value || '---'}
								</Typography>
							</Grid>
						)}
					</Grid>
				))}
			</Box>
		),
	}));

	const inventoryData = [
		{
			title: (
				<Typography sx={{ fontWeight: 700 }} variant="subtitle2">
					{data?.inventory?.name}
				</Typography>
			),
			expandedContent: data?.inventory?.list?.map((item, index) => (
				<Box className="pdp_accordion_content" key={index}>
					<Typography variant="subtitle2" sx={{ marginY: 0.5 }}>
						{item}
					</Typography>
				</Box>
			)),
		},
	];

	const productImages = data?.product_images?.map((item, index) => ({
		id: index,
		fallback: `https://res.cloudinary.com/sourcewiz/image/upload/b_white,c_pad,h_500,w_500/${item}`,
		src: `https://sourcerer.tech/assets/${item}?transforms=[["resize",{"width":500,"height":500,"fit":"contain","background":"white"}]]`,
	}));

	const attrsPriority = sortByPriority(data?.attributes);

	const handleLimit = () => setOpen(true);

	const handleVariantClick = (item) => {
		navigate(`/product-details/${item?.id}`);
	};

	const dispatch = useDispatch();

	const handleIncrement = (val, item) => {
		dispatch(addProductToCartAction(item, val));
	};

	const handleDecrement = (val, item) => {
		dispatch(addProductToCartAction(item, val));
	};

	const handleRemoveFromCart = (item) => {
		dispatch(removeAPRoductFromDetailsPage(item.id));
	};

	const handlePriceChange = (event) => {
		setCustomPrice(event.target.value);
	};
	const addCustomizeProductToCart = () => {
		dispatch(
			addCustomizedProductAction(data?.id, customizeCount, customPrice, textVal)
		);
		setIsCustomiseModalOpen(false);
	};

	return (
		<Box className="pdp-outer-layout">
			<Box mb="1em">
				<PageHeader
					leftSection={LeftSection(data)}
					rightSection={RightSection(data)}
				/>
			</Box>
			<Grid container className="pdp_internal_layout">
				<Grid item className="pdp_image_carousel" xs={6} sm={12} md={6} lg={6}>
					<ImageCarousel
						magnifierBoxDimension={300}
						images={productImages}
						width="100%"
						imageHeight="100%"
					/>
				</Grid>
				<Grid
					className="pdp_details_section"
					item
					sm={12}
					md={5.1}
					lg={5.5}
					xs={6}>
					<Box marginTop="1.7em">
						{data?.label &&
							data?.label?.map((item) => (
								<Grid key={item?.text} item marginBottom={2}>
									<Chip
										variant="filled"
										label={item?.text}
										bgColor={item?.background_color}
										textColor={item?.text_color}
									/>
								</Grid>
							))}
						{data?.heading && (
							<Typography variant="h5" sx={{ marginTop: 0.5 }} color="#4F555E">
								{data?.heading}
							</Typography>
						)}
					</Box>

					<Grid container marginTop={1.6} marginBottom={5}>
						<Grid item>
							<Typography variant="h5" color="#000">
								{data?.sub_heading && data?.sub_heading}
							</Typography>
						</Grid>
						{data?.volume_pricing?.is_enabled && (
							<Grid item marginLeft={1.5}>
								<Avatar
									variant="circular"
									size="medium"
									backgroundColor="#E8F3EF"
									isImageAvatar={false}
									content={
										<Icon
											onClick={() => setPricingModalOpen(true)}
											iconName="IconDiscount2"
											color="#16885F"
											sx={{
												cursor: 'pointer',
											}}
										/>
									}
								/>
							</Grid>
						)}
					</Grid>
					<Box marginBottom={4.5}>
						{data?.attributes &&
							attrsPriority?.map((item, index) => (
								<Grid container marginBottom={2.2} key={index}>
									<Grid item sx={{ marginBottom: 1 }} xs={3}>
										<Typography
											variant="body2"
											sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
											{item?.label}
										</Typography>
									</Grid>
									{item?.dtype === 'html' ? (
										<Grid item>
											<div
												dangerouslySetInnerHTML={{
													__html: renderFieldsByDType(item?.dtype, item?.value),
												}}></div>
										</Grid>
									) : (
										<Grid item>
											<Typography
												variant="body2"
												sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
												<ReadMore>
													{item?.value
														? renderFieldsByDType(item?.dtype, item?.value)
														: '----'}
												</ReadMore>
											</Typography>
										</Grid>
									)}
								</Grid>
							))}
					</Box>

					{data?.inventory && (
						<Grid container marginY={2}>
							{data?.inventory?.show_stock_list ? (
								<Accordion
									titleBackgroundColor={data?.inventory?.box_color}
									contentBackground={data?.inventory?.box_color}
									content={inventoryData}
								/>
							) : (
								<Box
									width="100%"
									padding="10px"
									borderRadius="8px"
									sx={{ background: data?.inventory?.box_color }}>
									{data?.inventory?.name}
								</Box>
							)}
						</Grid>
					)}

					<Box marginBottom={2}>
						{data?.variant?.has_variant && (
							<Box>
								<Box paddingTop={2.8} marginBottom={2.25}>
									<Typography>
										{data?.variant?.count} variants available
									</Typography>
								</Box>
								<Box onClick={() => {}} className="pdp_horizontal_scroll">
									{data?.variant?.list &&
										data?.variant?.list?.map((item, index) => (
											<Grid
												item
												onClick={() => handleVariantClick(item)}
												sx={{ marginRight: 2, cursor: 'pointer' }}
												key={index}>
												<VariantCard
													width="208px"
													handleLimit={handleLimit}
													handleRemoveFromCart={() =>
														handleRemoveFromCart(item)
													}
													handleIncrement={(val) => handleIncrement(val, item)}
													handleDecrement={(val) => handleDecrement(val, item)}
													background={
														data?.id === item?.id ? '#E1EDFF' : '#F7F8FA'
													}
													data={item}
												/>
											</Grid>
										))}
								</Box>
							</Box>
						)}
						{!data?.variant?.has_variant && (
							<Box marginY={2}>
								<Box>
									<Counter
										sx={{ background: '#fff' }}
										handleDecrement={(val) => handleDecrement(val, data)}
										handleIncrement={(val) => handleIncrement(val, data)}
										handleLimit={handleLimit}
										initialCount={data?.cart?.quantity}
										handleRemoveFromCart={() => handleRemoveFromCart(data)}
										min={data?.inventory?.min_order_quantity}
										max={data?.inventory?.max_order_quantity}
										step={data?.inventory?.incremental_value}
										isTonalButton={false}
									/>
								</Box>
							</Box>
						)}
					</Box>

					{data?.is_customizable && (
						<Button
							sx={{ marginTop: data?.variant?.has_variant ? 3 : 0 }}
							tonal
							fullWidth
							onClick={() => setIsCustomiseModalOpen(true)}>
							Customize this product
						</Button>
					)}

					{isCustomiseModalOpen && (
						<CustomiseContentDrawer
							isCustomiseModalOpen={isCustomiseModalOpen}
							setIsCustomiseModalOpen={setIsCustomiseModalOpen}
							setTextVal={setTextVal}
							onCustomizeProductCounterChange={onCustomizeProductCounterChange}
							customizeCount={customizeCount}
							addCustomizeProductToCart={addCustomizeProductToCart}
							textVal={textVal}
							handlePriceChange={handlePriceChange}
						/>
					)}

					{pricingModalOpen && (
						<VolumePricingDrawer
							setPricingModalOpen={setPricingModalOpen}
							pricingModalOpen={pricingModalOpen}
							pricingList={data?.volume_pricing?.data}
							setData={setData}
						/>
					)}

					<Box marginY={4}>
						<DashedAccordion
							content={content}
							titleColor="#000000"
							contentColor="#000000"
							contentBackground="#FFF"
						/>
					</Box>
				</Grid>
			</Grid>
			{open && (
				<Toaster
					open={open}
					showCross={false}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					autoHideDuration={2000}
					onClose={handleClose}
					state="warning"
					title="You have reached stock limit"
					subtitle="Please add quantity within stock limit"
					showActions={false}
					iconSize="medium"
				/>
			)}
		</Box>
	);
};

export default ProductDetailSection;
