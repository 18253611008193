import { Button, Grid } from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCartAction } from '../../../store/actions/cart.actions';
import { getQuoteData } from '../../../store/actions/quote.action';
import { getParam } from '../../../util/common';
import CartHeader from '../CartHeader/CartHeader';
import ReviewCartBody from './ReviewCartBody/ReviewCartBody';
import ReviewCartRightBody from './ReviewCartRightBody/ReviewCartRightBody';
import ReviewRightDrawer from './ReviewRightDrawer/ReviewRightDrawer';
import styles from './ReviewSubmit.module.css';
export default function ReviewSubmit() {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	useEffect(() => {
		dispatch(getCartAction());
		const quotation_id = getParam('quotation_id');
		if (quotation_id) {
			dispatch(getQuoteData(quotation_id));
		}
	}, []);

	return (
		<Grid
			container
			className={styles.container}
			style={{ maxWidth: '1250px', margin: '0px auto' }}>
			<Grid item xs={12}>
				<CartHeader activeStep={1} />
			</Grid>
			<Grid className={styles.body}>
				<ReviewCartBody />
			</Grid>
			<Grid className={styles.right}>
				<ReviewCartRightBody />
			</Grid>
			<Grid className={styles.showSmall} item xs={12}>
				<ReviewRightDrawer show={show} closeDrawer={() => setShow(false)} />
				<Button className={styles.smallDeviceBtn} onClick={() => setShow(true)}>
					View Quote Summary
				</Button>
			</Grid>
		</Grid>
	);
}
