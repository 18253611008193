import './ProductDetails.css';

import { Box, Grid, Icon, Typography } from '@sourcewiz/the-source';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LeftSection = (data) => {
	const navigate = useNavigate();
	return (
		<Grid container spacing={2}>
			<Grid item className="pdp_left_arrow">
				<Box
					onClick={() => {
						navigate(-1);
					}}
				>
					<Icon sx={{ cursor: 'pointer' }} iconName="IconArrowLeft" />
				</Box>
			</Grid>
			<Grid item>
				<Typography variant="h6">{data?.entity_id}</Typography>
			</Grid>
		</Grid>
	);
};

const RightSection = (data) => {
	const navigate = useNavigate();
	return (
		<Grid container marginTop="-0.5em">
			<Box
				onClick={() => {
					if (data?.previous_product === -1) {
						return;
					}
					navigate(`/product-details/${data?.previous_product}`);
				}}
				className={`pdp_header_arrow ${
					data?.previous_product === -1 ? 'pdp_block_pointer' : 'pdp_pointer'
				}`}
			>
				<Icon iconName="IconChevronLeft" />
			</Box>
			<Box
				onClick={() => {
					if (data?.next_product === -1) {
						return;
					}
					navigate(`/product-details/${data?.next_product}`);
				}}
				className={`pdp_header_arrow  ${
					data?.next_product === -1 ? 'pdp_block_pointer' : 'pdp_pointer'
				}`}
			>
				<Icon iconName="IconChevronRight" />
			</Box>
		</Grid>
	);
};

export { LeftSection, RightSection };
