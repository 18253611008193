/* eslint-disable */
import {
	Checkbox,
	Icon,
	Input,
	SingleSelect,
	TextArea,
	Typography,
	PageHeader,
	Tabs,
	Box,
	Button,
	Grid,
	MultiSelect,
} from '@sourcewiz/the-source';
import { useFormik } from 'formik';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	getCreateAccountsDetails,
	postCreateAccounts,
} from '../../../api/handlers/accounts';
import { getAccountsPayload, validateEmail } from '../../../util/common';
import {
	validateAddress,
	validateAllContacts,
	validateContact,
} from '../../../util/validations';
import { data } from './createAccounts';
// import AccountData from './accounts';
import editAccounts from './editAccounts';

export default function CreateAccount() {
	const [value, setTabValue] = useState(0);
	const [accountsData, setAccountsData] = useState();
	const [contactsData, setContactsData] = useState([]);
	const [addressData, setAddressData] = useState([]);
	const [paymentsData, setPaymentsData] = useState([]);
	const [bankingData, setBankingData] = useState([]);
	const [orderSettingData, setOrderSettingData] = useState([]);
	const [shipmentMethodData, setShipmentMethodData] = useState([]);
	const [termsConditionsData, setTermsConditionsData] = useState([]);
	const [remarksData, setRemarksData] = useState([]);
	const [formFields, setFormFields] = useState({});
	const [formSubmited, setFromSubmited] = useState(false);
	const params = useParams();

	useEffect(() => {
		if (params?.id) {
			setAccountsData(editAccounts);
		} else {
			getCreateAccountsDetails()
				.then((data) => {
					setAccountsData(data?.data?.data);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	useEffect(() => {
		setContactsData(accountsData?.sections[0]?.contacts?.contacts);
		setAddressData(accountsData?.sections[1]?.addresses);
		setPaymentsData(accountsData?.sections[2].payments);
		setBankingData(accountsData?.sections[2].banking);
		setOrderSettingData(accountsData?.sections[2]?.order_settings?.attributes);
		setShipmentMethodData(
			accountsData?.sections[2]?.shipment_methods?.attributes
		);
		setTermsConditionsData(accountsData?.sections[2].terms_conditions);
		setRemarksData(accountsData?.sections[3]?.remarks[0]?.attributes);
	}, [accountsData]);

	const formik = useFormik({
		initialValues: formFields,
		onSubmit: (values) => {
			console.log('Values: ', values);
			setFormFields(values);
			setFromSubmited(true);
			const validationErrors = validateForm(values);
			let isFormValid = true;
			let shouldMakeAPICall = true;
			if (validationErrors) {
				Object.keys(validationErrors).forEach((section) => {
					isFormValid =
						isFormValid && Object.keys(validationErrors[section]).length > 0
							? false
							: true;
					if (!isFormValid) {
						shouldMakeAPICall = false;
						return;
					}
				});
			}
			if (shouldMakeAPICall || !shouldMakeAPICall) {
				setFromSubmited(false);
				const getCreateAccountsPayload = getAccountsPayload(values);
				postCreateAccounts(getCreateAccountsPayload)
					.then((res) => {
						console.log(res);
					})
					.catch((err) => console.error(err));
			}
		},
	});

	function validateForm(values) {
		let errors = {
			basic_details: {},
			contacts: {},
			address: {},
			banking: {},
			order_settings: {},
			shipment_methods: {},
			remarks: {},
		};
		accountsData?.sections[0]?.attributes.forEach((attribute) => {
			const fieldName = attribute.attribute_id;
			const fieldValue = values?.basic_details?.[0]?.[fieldName];
			if (attribute.required && !fieldValue) {
				errors = {
					...errors,
					basic_details: {
						...errors.basic_details,
						[fieldName]: `${attribute.name} is required`,
					},
				};
			} else if (
				attribute.type === 'email' &&
				fieldValue &&
				!validateEmail(fieldValue)
			) {
				errors = {
					...errors,
					basic_details: {
						...errors.basic_details,
						[fieldName]: `${attribute.name} is invalid`,
					},
				};
			}
			// Add additional validation checks for other field types here...
		});

		accountsData?.sections[0]?.contacts?.contacts?.map((contact, index) => {
			const contactErrors = validateContact(contact, values, index);
			if (contactErrors) {
				errors = {
					...errors,
					contacts: {
						...errors.contacts,
						[index]: contactErrors,
					},
				};
			}
		});

		accountsData?.sections[1]?.addresses?.map((address, index) => {
			const addressErrors = validateAddress(address, values, index);
			if (addressErrors) {
				errors = {
					...errors,
					address: {
						...errors.address,
						[address.id]: addressErrors,
					},
				};
			}
		});

		accountsData?.sections[2]?.payments?.map((attribute) => {
			const fieldName = attribute.attribute_id;
			const fieldValue = values?.payments?.[0]?.[fieldName];
			if (attribute.required && !fieldValue) {
				errors = {
					...errors,
					payments: {
						...errors.payments,
						[fieldName]: `${attribute.name} is required`,
					},
				};
			}
		});

		accountsData?.sections[2]?.order_settings?.attributes?.map((attribute) => {
			const fieldName = attribute.attribute_id;
			const fieldValue = values?.order_settings?.[0]?.[fieldName];
			if (attribute.required && !fieldValue) {
				errors = {
					...errors,
					order_settings: {
						...errors.order_settings,
						[fieldName]: `${attribute.name} is required`,
					},
				};
			}
		});

		return Object.keys(errors).length === 0 ? null : errors;
	}

	const renderFormFields = (item, index, firstIndex, section) => {
		switch (item.type) {
			case 'email': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				return (
					<Input
						type="email"
						variant="outlined"
						style={{ width: '100%', marginRight: '20px' }}
						label={item.name}
						helperText={
							(formSubmited &&
								!val &&
								item?.required &&
								'This field is required') ||
							(formSubmited && !validateEmail(val) && 'Invalid email address')
						}
						required={item?.required}
						defaultValue={item?.value || ''}
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						onChange={formik.handleChange}
						error={
							(formSubmited && val?.length === 0 && item?.required) ||
							(formSubmited && !validateEmail(val))
						}
						value={val}
					/>
				);
			}

			case 'text': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				return (
					<Input
						type="text"
						variant="outlined"
						style={{ width: '100%', marginRight: '20px' }}
						label={item.name}
						helperText={
							formSubmited && !val && item?.required && 'This field is required'
						}
						required={item?.required}
						defaultValue={item?.value || ''}
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						onChange={formik.handleChange}
						error={
							formSubmited && (!val || validateEmail(val)) && item?.required
						}
						value={val}
					/>
				);
			}

			case 'number': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				return (
					<Input
						type="number"
						variant="outlined"
						style={{ width: '100%', marginRight: '20px' }}
						label={item.name}
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						onChange={formik.handleChange}
						helperText={
							formSubmited && !val && item?.required && 'This field is required'
						}
						error={formSubmited && !val && item?.required}
						required={item?.required}
						defaultValue={item?.value || ''}
						value={val}
					/>
				);
			}

			case 'multi_select': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				const options = item?.options?.map((item) => item?.label || '');
				return (
					<MultiSelect
						options={options}
						label={item.name}
						value={val}
						defaultValue={item?.value}
						handleChange={(selected) => {
							formik.setFieldValue(
								`${section}[${firstIndex}]${item.attribute_id}`,
								selected
							);
						}}
						helperText={
							formSubmited && !val && item?.required && 'This field is required'
						}
						checkmarks
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						error={formSubmited && !val && item?.required}
					/>
				);
			}
			case 'select': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				return (
					<SingleSelect
						fullWidth
						label={item.name}
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						options={item.options}
						onChange={formik.handleChange}
						error={formSubmited && !val && item?.required}
						value={val}
						defaultValue={val || item?.value}
						helperText={
							formSubmited && !val && item?.required && 'This field is required'
						}
					/>
				);
			}
			case 'checkbox': {
				let val =
					formik.values[section] &&
					formik.values[section][firstIndex] &&
					formik.values[section][firstIndex][item.attribute_id];
				return (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						<Checkbox
							size="medium"
							name={`${section}[${firstIndex}]${item.attribute_id}`}
							onChange={(e) => {
								formik.setFieldValue(
									`${section}[${firstIndex}]${item.attribute_id}`,
									e.target.checked
								);
							}}
							checked={val ? true : false}
						/>
						<Typography>{item.name}</Typography>
					</Box>
				);
			}
			case 'textarea': {
				let val =
					(formik.values[section] &&
						formik.values[section][firstIndex] &&
						formik.values[section][firstIndex][item.attribute_id]) ||
					item?.value;
				return (
					<TextArea
						label={item.name}
						name={`${section}[${firstIndex}]${item.attribute_id}`}
						handleChange={formik.handleChange}
						rows={3}
						sx={{ width: '100%' }}
						value={val}
					/>
				);
			}
			default:
				return null;
		}
	};

	const renderBasicInfo = (data, firstIndex, section, gridNum = 2) => {
		const gridTemplateColumns = `repeat(${gridNum}, 1fr)`;
		return (
			<Grid
				container
				style={{
					display: 'grid',
					gridTemplateColumns,
					width: '100%',
					gap: '20px',
				}}>
				{data?.map((item, index) => (
					<Grid key={index} item xs={12}>
						{renderFormFields(item, index, firstIndex, section)}
					</Grid>
				))}
			</Grid>
		);
	};

	// add more
	const addContact = () => {
		const newContactAttributes =
			accountsData?.sections[0]?.contacts[0]?.attributes?.map((item) => {
				return {
					...item,
					value: '',
				};
			});
		const newContactData = {
			attributes: newContactAttributes,
			id: contactsData[contactsData.length - 1].id + 1,
		};
		setContactsData((prev) => [...prev, newContactData]);
	};

	const addAddress = () => {
		const newAddress = accountsData?.sections[1]?.addresses[0];
		newAddress.id = addressData[addressData.length - 1].id + 1;
		setAddressData((prev) => [...prev, newAddress]);
	};

	const addBankingInfo = () => {
		const newBankingOption = accountsData.sections[2]?.banking[0];
		newBankingOption.id = bankingData[bankingData.length - 1].id + 1;
		setBankingData((prev) => [...prev, newBankingOption]);
	};

	const addTerms = () => {
		const newTerms = accountsData.sections[2]?.terms_conditions[0];
		newTerms.id = termsConditionsData[termsConditionsData.length - 1].id + 1;
		setTermsConditionsData((prev) => [...prev, newTerms]);
	};

	// remove
	const removeContact = (item, index) => {
		const filtered = contactsData.filter((i, indx) => indx !== index);
		setContactsData(filtered);
	};

	const removeAddress = (item, index) => {
		const filterAddress = addressData.filter((i, indx) => indx !== index);
		setAddressData(filterAddress);
	};

	const removeBankingInfo = (item, index) => {
		const filterBankingInfo = bankingData.filter((i, indx) => indx !== index);
		setBankingData(filterBankingInfo);
	};

	const removeTerms = (item, index) => {
		const filterTerms = termsConditionsData.filter((i, indx) => indx !== index);
		setTermsConditionsData(filterTerms);
	};

	const getContactLabel = (index) => {
		switch (index) {
			case 0:
				return 'Primary contact';

			default:
				return `Contact ${index}`;
		}
	};

	const formBasicData = () => (
		<Box>
			{renderBasicInfo(
				accountsData?.sections[0]?.attributes,
				0,
				'basic_details',
				2
			)}
			<Box marginTop={7}>
				<Typography variant="h6">Contacts</Typography>
				{contactsData?.length > 0 &&
					contactsData?.map((item, firstIndex) => (
						<Box>
							<Box
								sx={{
									borderColor: '#F7F8FA',
									color: '#F7F8FA',
									marginTop: '25px',
									borderTop: '1px dashed #B5BBC4',
									borderBottom: 'none',
								}}
							/>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
								marginTop={3}
								marginBottom={1}>
								<Typography>{getContactLabel(firstIndex)}</Typography>
								{firstIndex > 0 && (
									<Box
										onClick={() => removeContact(item, firstIndex)}
										sx={{
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
											cursor: 'pointer',
										}}>
										<Icon
											iconName="IconTrash"
											color="#D74C10"
											sx={{ marginRight: '10px' }}
										/>
										<Typography color="#D74C10">Remove</Typography>
									</Box>
								)}
							</Box>
							{renderBasicInfo(item.attributes, firstIndex, 'contacts')}
						</Box>
					))}
				<Box
					sx={{
						borderColor: '#F7F8FA',
						color: '#F7F8FA',
						marginTop: '25px',
						borderTop: '1px dashed #B5BBC4',
						borderBottom: 'none',
					}}
				/>
			</Box>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				marginTop={1.8}>
				<Button tonal onClick={() => addContact()}>
					Add another
				</Button>
			</Box>
		</Box>
	);

	const formAddressData = () => (
		<Box>
			<Box>
				<Box marginBottom={1}>
					<Typography variant="h6">Add address</Typography>
				</Box>
				{addressData?.length > 0 &&
					addressData?.map((item, firstIndex) => (
						<Box>
							{renderBasicInfo(item.attributes, firstIndex, 'address', 2)}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
								marginTop={3}
								marginBottom={1}>
								<Box />
								{firstIndex > 0 && (
									<Box
										onClick={() => removeAddress(item, firstIndex)}
										sx={{
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
											cursor: 'pointer',
										}}>
										<Icon
											iconName="IconTrash"
											color="#D74C10"
											sx={{ marginRight: '10px' }}
										/>
										<Typography color="#D74C10">Remove</Typography>
									</Box>
								)}
							</Box>
							<Box
								sx={{
									borderColor: '#F7F8FA',
									color: '#F7F8FA',
									marginY: '25px',
									borderTop: '1px dashed #B5BBC4',
									borderBottom: 'none',
								}}
							/>
						</Box>
					))}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				marginTop={1.8}>
				<Button tonal onClick={() => addAddress()}>
					Add another
				</Button>
			</Box>
		</Box>
	);

	const formPreferences = () => (
		<Box>
			<Box>
				<Box marginBottom={1}>
					<Typography variant="h6">Payments</Typography>
				</Box>
				<Box marginBottom={5.5}>
					{paymentsData?.length > 0 &&
						paymentsData?.map((item, firstIndex) => (
							<Box>
								{renderBasicInfo(item.attributes, firstIndex, 'payments', 2)}
							</Box>
						))}
				</Box>
			</Box>
			<Box>
				<Box marginBottom={1}>
					<Typography variant="h6">Banking</Typography>
				</Box>
				<Box>
					{bankingData?.length > 0 &&
						bankingData?.map((item, firstIndex) => (
							<Box>
								{renderBasicInfo(item.attributes, firstIndex, 'banking', 1)}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
									marginTop={3}
									marginBottom={1}>
									<Box />
									{firstIndex > 0 && (
										<Box
											onClick={() => removeBankingInfo(item, firstIndex)}
											sx={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												cursor: 'pointer',
												marginBottom: '10px',
											}}>
											<Icon
												iconName="IconTrash"
												color="#D74C10"
												sx={{ marginRight: '10px' }}
											/>
											<Typography color="#D74C10">Remove</Typography>
										</Box>
									)}
								</Box>
							</Box>
						))}
				</Box>
				<Box
					sx={{
						borderColor: '#F7F8FA',
						color: '#F7F8FA',
						marginY: '25px',
						borderTop: '1px dashed #B5BBC4',
						borderBottom: 'none',
					}}
				/>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
					marginTop={1.8}>
					<Button tonal onClick={() => addBankingInfo()}>
						Add another
					</Button>
				</Box>
			</Box>
			<Box marginTop={5.5}>
				<Box marginBottom={1}>
					<Typography variant="h6">Order settings</Typography>
				</Box>
				<Box marginBottom={5.5}>
					{renderBasicInfo(orderSettingData, 0, 'order_settings', 2)}
				</Box>
			</Box>
			<Box marginTop={5.5}>
				<Box marginBottom={1}>
					<Typography variant="h6">Shipping method</Typography>
				</Box>
				<Box marginBottom={5.5}>
					{renderBasicInfo(shipmentMethodData, 0, 'shipment_method', 2)}
				</Box>
			</Box>
			{/* terms */}
			<Box>
				<Box marginBottom={1}>
					<Typography variant="h6">Terms & Conditions</Typography>
				</Box>
				<Box>
					{termsConditionsData?.length > 0 &&
						termsConditionsData?.map((item, firstIndex) => (
							<Box>
								{renderBasicInfo(item.attributes, firstIndex, 'terms', 1)}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
									marginTop={3}
									marginBottom={1}>
									<Box />
									{firstIndex > 0 && (
										<Box
											onClick={() => removeTerms(item, firstIndex)}
											sx={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												cursor: 'pointer',
												marginBottom: '10px',
											}}>
											<Icon
												iconName="IconTrash"
												color="#D74C10"
												sx={{ marginRight: '10px' }}
											/>
											<Typography color="#D74C10">Remove</Typography>
										</Box>
									)}
								</Box>
							</Box>
						))}
				</Box>
				<Box
					sx={{
						borderColor: '#F7F8FA',
						color: '#F7F8FA',
						marginY: '25px',
						borderTop: '1px dashed #B5BBC4',
						borderBottom: 'none',
					}}
				/>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
					marginTop={1.8}>
					<Button tonal onClick={() => addTerms()}>
						Add another
					</Button>
				</Box>
			</Box>
		</Box>
	);

	const otherDetails = () => (
		<Box>
			<Box marginBottom={5.5}>
				<Box marginBottom={1}>
					<Typography variant="h6">Remarks</Typography>
				</Box>
				<Box marginBottom={5.5}>
					{renderBasicInfo(remarksData, 0, 'otherDetails', 1)}
				</Box>
			</Box>
		</Box>
	);

	const renderFormData = (val) => {
		switch (val) {
			case 0:
				return formBasicData();
			case 1:
				return formAddressData();
			case 2:
				return formPreferences();
			default:
				return otherDetails();
		}
	};

	const handleChange = (index) => {
		setTabValue(index);
	};

	const CreateFormSection = () => {
		return (
			<Box
				paddingX={10}
				paddingY={4}
				marginX={10}
				marginTop={2}
				minHeight={window.innerHeight - 130}
				sx={{
					backgroundColor: '#FFF',
					borderRadius: '20px',
				}}>
				<Tabs
					label="Tab 1"
					value={value}
					handleChange={handleChange}
					noOftabs={accountsData?.sections}>
					<Box paddingX={1}>{renderFormData(value)}</Box>
				</Tabs>
			</Box>
		);
	};

	const HeaderRight = () => {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}>
				<Box marginRight={1.4}>
					<Button
						variant="outlined"
						color="secondary"
						sx={{ backgroundColor: 'transperant' }}>
						Discard
					</Button>
				</Box>

				<Box>
					<Button
						onClick={() => {
							formik.handleSubmit();
						}}>
						Save
					</Button>
				</Box>
			</Box>
		);
	};
	return (
		<>
			<PageHeader
				leftSection={
					<Typography variant="h5">
						{params?.id ? 'Edit' : 'Create'} Account
					</Typography>
				}
				rightSection={<HeaderRight />}
			/>
			{CreateFormSection()}
		</>
	);
}
