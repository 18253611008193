import { Box, Chip, Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';

const Preferences = ({ data }) => {
	return (
		<Box>
			<Grid>
				<Typography variant="h6">{data?.payments?.key}</Typography>
				<Grid>
					{data?.payments?.sections?.map((section) => (
						<Grid my={2.5} alignItems="center" container key={section?.key}>
							<Grid item xs={3}>
								<Typography variant="body2">{section?.heading}</Typography>
							</Grid>
							<Grid item>
								<Typography>:</Typography>
							</Grid>
							<Grid item>
								{section?.tags?.map((tag) => (
									<Chip
										key={tag?.id}
										sx={{ marginLeft: '10px' }}
										label={tag?.name}
										bgColor={tag?.backgroundColor}
										textColor={tag?.textColor}
									/>
								))}
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid mt={4} mb={2}>
				<Typography variant="h6">{data?.banking?.key}</Typography>
				<Grid mt={2}>
					{data?.banking?.attributes?.map((attribute) => (
						<Grid
							key={attribute?.id}
							my={2}
							borderRadius="8px"
							border="1px solid #D1D6DD">
							<Typography
								sx={{ padding: 1 }}
								key={attribute?.key}
								variant="body2">
								{attribute?.value?.split('\n').map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<br />
									</React.Fragment>
								))}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid mb={2} mt={5}>
				<Typography variant="h6">{data?.order_settings?.key}</Typography>
				{data?.order_settings?.attributes?.map((attribute) => (
					<Grid key={attribute?.key} container my={2}>
						<Grid item xs={3}>
							<Typography variant="body2">{attribute?.key}</Typography>
						</Grid>
						<Grid item>:</Grid>
						<Grid item ml={2.5}>
							<Typography variant="body2">{attribute?.value}</Typography>
						</Grid>
					</Grid>
				))}
			</Grid>
			<Grid mb={2} mt={5}>
				<Typography variant="h6">{data?.terms_conditions?.key}</Typography>
				{data?.terms_conditions?.attributes?.map((attribute) => (
					<Grid
						key={attribute?.id}
						my={2}
						borderRadius="8px"
						border="1px solid #D1D6DD">
						<Typography
							sx={{ padding: 1 }}
							key={attribute?.key}
							variant="body2">
							{attribute?.value?.split('\n').map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default Preferences;
