/* eslint-disable */
import {
	AccountList,
	Box,
	CustomInput,
	Grid,
	Icon,
	Menu,
	Typography,
} from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import AccountsList from './accountsList.json';

const AccountsLisiting = () => {
	const [accountsList, setAccountsList] = useState({});
	const [searchInput, setSearchInput] = useState('');
	const [selectedSortOption, setSelectedSortOption] = useState(
		accountsList?.sort
	);
	const debouncedVal = useDebounce(searchInput, 1000);

	const navigate = useNavigate();
	// const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams({
			search_string: searchInput,
			sort_by: selectedSortOption,
		});
		navigate(`?${queryParams}`);
	}, [searchInput, selectedSortOption]);

	useEffect(() => {
		if (searchInput?.length >= 3) {
			// SEARCH API CALL
			console.log('API CALLED FOR SEARCH');
		}
		setAccountsList(AccountsList);
		setSelectedSortOption(AccountsList?.sort);
	}, [debouncedVal, selectedSortOption]);

	const handleSort = (option) => {
		setSelectedSortOption(option);
	};

	return (
		<Box>
			<Grid>
				<Grid container alignItems="center">
					<Grid item xs={9.5}>
						<CustomInput
							fullWidth
							value={searchInput}
							startIcon={<Icon iconName="IconSearch" />}
							onChange={(event) => setSearchInput(event.target.value)}
						/>
					</Grid>
					<Grid item ml="auto" sx={{ cursor: 'pointer' }}>
						<Menu
							LabelComponent={
								<Icon fontSize="medium" iconName="IconSortDescending" />
							}
							menu={[{ label: 'Name A-Z' }, { label: 'Name Z-A' }]}
							onClickMenuItem={(clickedItem) => {
								handleSort(clickedItem);
							}}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item my={2.5}>
						<Typography variant="body2">{accountsList?.helper_text}</Typography>
					</Grid>
					<Grid item ml="auto" my={2.5}>
						<Grid container>
							<Grid item sx={{ cursor: 'pointer' }}>
								<Icon iconName="IconChevronLeft" />
							</Grid>
							<Grid item>
								<Icon iconName="IconChevronRight" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid>
					{accountsList?.data?.map((item) => (
						<Box key={item?.id}>
							<AccountList data={item} />
							<hr style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
						</Box>
					))}
				</Grid>
			</Grid>
		</Box>
	);
};

export default AccountsLisiting;
