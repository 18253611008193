const userActionTypes = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	SIGN_OUT_START: 'SIGN_OUT_START',
	SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
	SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
	SIGN_UP_START: 'SIGN_UP_START',
	SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
	SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
	EDIT__PROFILE__START: 'EDIT__PROFILE__START',
	EDIT__PROFILE__SUCCESS: 'EDIT__PROFILE__SUCCESS',
	EDIT__PROFILE__FAILURE: 'EDIT__PROFILE__FAILURE',
};
export default userActionTypes;
