import { Box, ContactCard, Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';

const BasicDetails = ({ data }) => {
	const attributes = data?.attributes?.sort(
		(firstElem, secondElem) => firstElem?.priority - secondElem?.priority
	);

	const sortedContacts = data?.contacts?.contacts?.sort(
		(firstElem, secondElem) => firstElem?.priority - secondElem?.priority
	);

	return (
		<Box>
			{attributes?.map((attribute) => (
				<Grid container alignItems="center" my={2} key={attribute?.key}>
					<Grid item xs={3}>
						<Typography variant="body2">{attribute?.name}</Typography>
					</Grid>
					<Grid item>:</Grid>
					<Grid item ml={2.5}>
						<Typography variant="body2">{attribute?.value}</Typography>
					</Grid>
				</Grid>
			))}
			<Box mt={7}>
				<Typography variant="h6">{data?.contacts?.key}</Typography>
				<Grid mt={1} container spacing={2}>
					{sortedContacts?.map((contact) => (
						<Grid key={contact?.id} item xs={6} lg={6} md={12} sm={12}>
							<ContactCard key={contact?.key} data={contact} />
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export default BasicDetails;
