import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProductDetail } from '../../api/handlers/products';
import ProductDetailSection from './ProductDetailsSection';
import ProductDetailsSkeleton from './ProductDetailsSkeleton';

export default function ProductDetailsPage() {
	const [isLoading, setIsLoading] = useState(false);
	const [detailsData, setDetailsData] = useState({});

	const params = useParams();

	useEffect(() => {
		setIsLoading(true);
		getProductDetail(params?.id)
			.then((res) => {
				if (res.status === 200) {
					const product_details = res.data.data.find(
						(list) =>
							list.section === 'product_detail' &&
							list.page_name === 'product_detail'
					);
					setDetailsData(product_details);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	}, [params?.id]);

	console.log('-------');
	return isLoading ? (
		<ProductDetailsSkeleton />
	) : (
		<ProductDetailSection data={detailsData} setData={setDetailsData} />
	);
}
