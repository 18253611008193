import { Grid } from '@sourcewiz/the-source';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCartAction } from '../../store/actions/cart.actions';
import CartBody from './CartBody/CartBody';
import Drawers from './CartDrawers';
import CartHeader from './CartHeader/CartHeader';
import styles from './CartModule.css';
import CartRight from './CartRight/CartRight';
import CartSkeleton from './CartSkeleton/CartSkeleton';

export default function CartModule() {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.cart.loading);
	useEffect(() => {
		dispatch(getCartAction());
	}, []);
	return (
		<>
			{!loading && (
				<Grid style={{ maxWidth: '1250px', margin: '0px auto' }}>
					<Grid container className={styles.container}>
						<Grid item md={12}>
							<CartHeader />
						</Grid>
						<Grid item sm={12} xs={12} md={8}>
							<CartBody />
						</Grid>
						<Grid item sm={0} xs={0} md={4}>
							<CartRight />
						</Grid>
					</Grid>
				</Grid>
			)}
			{loading && <CartSkeleton />}
			<Drawers />
		</>
	);
}
