import {
	AllFiltersDrawer,
	Box,
	CustomInput,
	FilterChips,
	FilterIcon,
	Filters,
	Grid,
	PageHeader,
	Pagination,
	ProductCard,
	Sort,
	Typography,
} from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getProducts } from '../../api/handlers/products';
import useWindowSize from '../../hooks/useWindowSize';
import {
	addProductToCartAction,
	removeAPRoductFromDetailsPage,
} from '../../store/actions/cart.actions';
import {
	clearAllFilters,
	clearFacet,
	clearFilter,
	fetchAllFilters,
	setAppliedFacets,
} from '../../store/actions/filter.actions';
import styles from './AllProducts.module.css';
import EmptyState from './components/EmptyState';
import FilterSkeleton from './components/FilterSkeleton';
import ProductCardSkeleton from './components/ProductCardSkeleton';
import ResultTextSkeleton from './components/ResultTextSkeleton';
import VariantSelectionDrawer from './components/VariantSelectionDrawer';
import { getFilterType, getFilterName, formatUrlParams } from './helpers';

const IMAGE_ENV = process.env.REACT_APP_CLOUDINARY;
const IMAGE_FALLBACK_ENV = process.env.REACT_APP_DIRECTUS;
const PRODUCT_CARD_SKELETON_COUNT = 20;

export default function AllProducts() {
	const [productsData, setProductsData] = useState({});
	const [filtersData, setFiltersData] = useState([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [queryParams, setQueryParams] = useState({});
	const [facetsData, setFacetsData] = useState({});
	const [sortOptions, setSortOptions] = useState([]);
	const [defaultSort, setDefaultSort] = useState('');
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [isClearSearch, setIsClearSearch] = useState(false);
	const [urlParamsString, setUrlParamsString] = useState('');
	const [selectedProductId, setSelectedProductId] = useState(null);
	const [searchValue, setSearchValue] = useState(queryParams?.query ?? '');
	const [loading, setLoading] = useState(true);
	const [openVariantSelectionDrawer, setOpenVariantSelectionDrawer] =
		useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const appliedFacets = useSelector((state) => state.filters.active_filters);
	const availableFilters = useSelector(
		(state) => state.filters.available_filters
	);
	const urlParams = window.location.search;

	const { width } = useWindowSize();

	const handleCounterIncrement = (val, item) => {
		dispatch(addProductToCartAction(item, val));
	};

	const handleCounterDecrement = (val, item) => {
		dispatch(addProductToCartAction(item, val));
	};

	const handleRemoveFromCart = (item) => {
		dispatch(removeAPRoductFromDetailsPage(item.id));
	};

	// Fetch all filters
	useEffect(() => {
		dispatch(fetchAllFilters());
	}, [dispatch]);

	// Set applied filters & queryParams from url
	useEffect(() => {
		if (isInitialRender && urlParams?.length > 0 && filtersData?.length > 0) {
			setIsInitialRender(false);
			const params = new URLSearchParams(urlParams);
			const paramsObj = Object.fromEntries(params.entries());
			const outputObject = Object.entries(paramsObj).reduce(
				(acc, [key, value]) => {
					const pattern = /^f\[(.*)\]\[(min|max|unit)\]$/;
					const match = key.match(pattern);

					if (match) {
						const [, attributeName, attributeType] = match;
						const newKey = `f[${attributeName}]`;

						if (!acc[newKey]) {
							acc[newKey] = [null, null, null];
						}

						if (attributeType === 'min') {
							acc[newKey][0] = value;
						} else if (attributeType === 'max') {
							acc[newKey][1] = value;
						} else if (attributeType === 'unit') {
							acc[newKey][2] = value;
						}
					} else {
						acc[key] = value; // Assign value directly to the output object
					}

					return acc;
				},
				{}
			);
			const filter = Object.keys(outputObject).reduce((result, key) => {
				const match = key.match(/^f\[(.*)\]$/);
				if (match) {
					// check if it's a range filter
					const value =
						outputObject[key]?.length === 3
							? outputObject[key]
							: JSON.parse(outputObject[key]);
					const type = getFilterType(filtersData, match[1]);
					const filterName = getFilterName(filtersData, match[1]);
					result.push({
						key: match[1],
						label: filterName,
						type: type,
						value: value,
					});
				}
				return result;
			}, []);
			const queryParamsFromUrl =
				Object.keys(paramsObj).reduce((agg, item) => {
					const match = item.match(/^f\[(.*)\]$/);
					if (!match) {
						return {
							...agg,
							[item]: paramsObj[item],
						};
					}
				}, []) ?? {};
			if (Object.keys(queryParamsFromUrl).length > 0) {
				setQueryParams(queryParamsFromUrl);
				queryParamsFromUrl?.query && setSearchValue(queryParamsFromUrl?.query);
			}

			if (appliedFacets?.length !== filter?.length) {
				dispatch(setAppliedFacets(filter));
			}
		}
	}, [
		filtersData,
		appliedFacets?.length,
		dispatch,
		isInitialRender,
		urlParams,
	]);

	// API call on change of queryParams and appliedFilters
	useEffect(() => {
		getProducts(urlParamsString)
			.then((response) => {
				const productSection = response?.data?.data.find(
					(item) => item.section === 'product'
				);
				setProductsData(productSection);
				if (isInitialRender) {
					const sorting = productSection?.sorting;
					const sortingPrioritized = sorting.sort(
						(_a, _b) => _a.priority - _b.priority
					);
					const defaultSorting = sortingPrioritized.filter(
						(item) => item.default
					);
					const sortOptions = sortingPrioritized.reduce((agg, item) => {
						return [...agg, { value: item.key, label: item.label }];
					}, []);
					setSortOptions(sortOptions);
					setDefaultSort(defaultSorting[0]?.key || '');
				}
				queryParams?.sort && setDefaultSort(queryParams?.sort);
				const { facets, facets_stats } = productSection;
				setFacetsData({
					facets,
					facets_stats,
				});
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	}, [urlParamsString, isInitialRender, queryParams?.sort]);

	// Create filters data
	useEffect(() => {
		if (availableFilters?.length > 0 && Object.keys(facetsData)?.length > 0) {
			const availableFiltersData = availableFilters.filter((item) =>
				Object.keys(facetsData.facets).includes(item.meta.key)
			);
			const finalFilterData = availableFiltersData.map((item) => {
				const key = item.meta.key;
				const type = item.meta.type;
				if (type === 'range') {
					const { min = 0, max = 0 } = facetsData?.facets_stats?.[key] || {};
					const unit = item?.meta?.conversion_factors?.base_unit;
					return {
						...item,
						data: {
							options: {
								min,
								max,
								unit,
							},
						},
					};
				} else {
					const options = facetsData.facets[key];
					return { ...item, data: { options } };
				}
			});
			const sortedFilters = finalFilterData?.sort(
				(_a, _b) => _a.priority - _b.priority
			);
			const updatedFiltersData = sortedFilters.map((filter) => ({
				...filter,
				data: {
					...filter.data,
					applied:
						appliedFacets?.find((item) => item?.key === filter?.meta?.key)
							?.value || [],
				},
			}));
			setFiltersData(updatedFiltersData);
		}
	}, [appliedFacets, availableFilters, facetsData]);

	// Update URL params
	useEffect(() => {
		if (
			appliedFacets?.length > 0 ||
			Object.keys(queryParams)?.length > 0 ||
			isClearSearch
		) {
			const urlParams = formatUrlParams(appliedFacets, queryParams);
			setUrlParamsString(urlParams);
			navigate(`?${urlParams}`);
		}
	}, [queryParams, appliedFacets, isClearSearch, navigate]);

	const { hits = [] } = productsData || {};

	const sortedHits = hits?.sort((_a, _b) => _a.priority - _b.priority);

	const handleFilters = (key, type, value) => {
		const newFilters = filtersData.map((filter) => {
			if (filter.meta.key === key) {
				return {
					...filter,
					data: {
						...filter.data,
						applied:
							value?.length > 0 ? (type === 'select' ? [value] : value) : [],
					},
				};
			}
			return filter;
		});
		setFiltersData(newFilters);
		const filterName = getFilterName(filtersData, key);
		const filters = [
			{
				key: key,
				label: filterName,
				type: type,
				value: type === 'select' ? [value] : value,
			},
		];
		if (value?.length === 0) {
			clearUrlParams(key, type);
			dispatch(clearFilter(key));
		} else {
			dispatch(setAppliedFacets(filters));
		}
	};

	const handleClear = () => {
		handleClearAll();
		setIsDrawerOpen(false);
	};

	const clearUrlParams = (key, type) => {
		const params = new URLSearchParams(urlParams);
		const paramsObj = Object.fromEntries(params.entries());
		if (type === 'multi-select') {
			const isLastItem =
				appliedFacets.find((item) => item.key === key)?.value?.length === 1;
			if (isLastItem) {
				delete paramsObj[`f[${key}]`];
			}
		} else if (type === 'range') {
			const keysToRemove = Object.keys(paramsObj);
			keysToRemove.forEach((item) => {
				delete paramsObj[item];
			});
		} else {
			if (paramsObj[`f[${key}]`]) {
				delete paramsObj[`f[${key}]`];
			} else {
				delete paramsObj[key];
			}
		}
		const urlSearchParams = new URLSearchParams(paramsObj);
		const updatedUrlParams = urlSearchParams.toString();
		setUrlParamsString(updatedUrlParams);
		navigate(`?${updatedUrlParams}`);
	};

	const handleClearFilter = (key, value) => {
		const newFilters = filtersData.map((filter) => {
			if (filter.meta.key === key) {
				return {
					...filter,
					data: {
						...filter.data,
						applied:
							filter.meta.type === 'range'
								? []
								: filter?.data?.applied?.filter((item) => item !== value),
					},
				};
			}
			return filter;
		});
		setFiltersData(newFilters);
		const filterType = getFilterType(filtersData, key);
		clearUrlParams(key, filterType);
		// TODO: clear filter params
		if (filterType === 'range') {
			dispatch(clearFilter(key));
		} else {
			dispatch(clearFacet([{ key, value }]));
		}
	};

	const handleClearAll = () => {
		// TODO: clear filter params only
		navigate('?');
		setUrlParamsString('');
		dispatch(clearAllFilters());
	};

	const handleShowResult = () => {
		setIsDrawerOpen(false);
	};

	const handleSearch = (event) => {
		if (event.target.value === '') {
			clearSearch();
		} else {
			setQueryParams((prev) => ({
				...prev,
				page: 1,
				query: event.target.value,
			}));
			setSearchValue(event.target.value);
		}
	};

	const clearSearch = () => {
		setQueryParams((current) => {
			// eslint-disable-next-line no-unused-vars
			const { query, ...rest } = current;
			return rest;
		});
		setIsClearSearch(true);
		setSearchValue('');
	};

	const handleSort = (selected) => {
		setQueryParams((prev) => ({ ...prev, sort: selected?.value }));
	};

	const handleVariantSelection = (id) => {
		setSelectedProductId(id);
		setOpenVariantSelectionDrawer(true);
	};

	const handlePageChange = (event, page) => {
		setQueryParams((prev) => ({ ...prev, page }));
	};

	const repeatArray = Array.from(
		{ length: PRODUCT_CARD_SKELETON_COUNT },
		(_a, index) => index
	);

	const onProductCardClick = (id) => {
		navigate(`/product-details/${id}`);
	};

	return (
		<>
			{isDrawerOpen && (
				<AllFiltersDrawer
					key={JSON.stringify(filtersData)}
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
					onFilterChange={handleFilters}
					data={filtersData}
					facetResultsNumber={productsData?.nbHits}
					handleShowResult={handleShowResult}
					showClearButton={appliedFacets?.length > 0}
					handleClear={handleClear}
				/>
			)}
			<Grid container xl={10} justifyContent="center" margin="0 auto">
				<Grid container mr={-1}>
					<PageHeader
						leftSection={<Typography variant="h4">All Products</Typography>}
					/>
				</Grid>
				<Grid container mr={-1}>
					<Grid
						container
						sx={{
							background: '#f7f8fa',
							position: 'sticky',
							top: 76,
							zIndex: 999,
							padding: '1em 0',
							paddingRight: 2.1,
						}}
						// marginTop={2}
						justifyContent="space-between"
						alignItems="center">
						<Grid item xs={4} sm={4} md={3} lg={2} xl={2} mr={5}>
							<CustomInput
								key={searchValue}
								className={styles.search_input}
								onChange={handleSearch}
								inputType="search"
								fullWidth
								placeholder="Search"
								defaultValue={searchValue}
								allowClear={!!searchValue}
							/>
						</Grid>
						{loading ? (
							<FilterSkeleton />
						) : (
							<>
								<Grid
									className={styles.horizontal_filters}
									xs={12}
									sm={6}
									md={4}
									lg={6.5}
									xl={7}
									item
									mt={1}
									mr="auto">
									<Filters
										onFilterChange={handleFilters}
										filtersList={filtersData}
										showFilterCount={false}
										key={JSON.stringify(filtersData)}
									/>
								</Grid>
								<Grid
									className={styles.filter_sort}
									xs={6}
									sm={2}
									md={1}
									lg={1}
									xl={2}
									item>
									<Box mr={2} mt={0.5}>
										<FilterIcon
											btnPrefix={width <= 900 ? 'Filters' : ''}
											onClick={() => {
												setIsDrawerOpen(true);
											}}
										/>
									</Box>
									<Box mt={0.5}>
										<Sort
											key={defaultSort}
											onChange={handleSort}
											defaultValue={defaultSort}
											options={sortOptions}
										/>
									</Box>
								</Grid>
							</>
						)}
					</Grid>
					{appliedFacets?.length > 0 && (
						<Grid
							container
							ml={0}
							paddingY={2}
							sx={{
								background: '#f7f8fa',
								position: 'sticky',
								top: 80,
								zIndex: 999,
								// padding: '1em 0',
							}}
							spacing={1}>
							<FilterChips
								filterList={appliedFacets}
								onClearAll={handleClearAll}
								onClearFilter={handleClearFilter}
							/>
						</Grid>
					)}
					{!loading && sortedHits?.length === 0 ? (
						<Grid mt={7} container>
							<EmptyState
								onClearSearch={clearSearch}
								onClearFilter={handleClearAll}
								type={searchValue?.length > 0 ? 'search' : 'filters'}
							/>
						</Grid>
					) : (
						<>
							{openVariantSelectionDrawer && (
								<VariantSelectionDrawer
									open={openVariantSelectionDrawer}
									onClose={() => setOpenVariantSelectionDrawer(false)}
									id={selectedProductId}
								/>
							)}
							<Grid pb={1.5} mb={0.5}>
								{loading ? (
									<ResultTextSkeleton />
								) : (
									<Typography variant="subtitle-2" color="rgba(0, 0, 0, 0.87)">
										{productsData?.result_message}
									</Typography>
								)}
							</Grid>
							<Grid
								container
								rowSpacing={3}
								columnSpacing={{ xs: 2, sm: 1, md: 2, lg: 2, xl: 2 }}
								justifyContent="flex-start"
								maxWidth="100%">
								{loading
									? repeatArray.map((item) => (
											<Grid item xs maxWidth="280px" key={item}>
												<ProductCardSkeleton />
											</Grid>
									  ))
									: sortedHits?.map(
											(item, index) =>
												index < productsData.hitsPerPage && (
													<Grid
														item
														key={item.id}
														xs={12}
														sm={6}
														md={4}
														lg={3}
														xl={3}>
														<ProductCard
															imageEnv={IMAGE_ENV}
															fallbackEnv={IMAGE_FALLBACK_ENV}
															data={item}
															handleCounterIncrement={(val) =>
																handleCounterIncrement(val, item)
															}
															handleCounterDecrement={(val) =>
																handleCounterDecrement(val, item)
															}
															handleRemoveFromCart={() =>
																handleRemoveFromCart(item)
															}
															onProductCardClick={onProductCardClick}
															handleVariant={(id) => handleVariantSelection(id)}
														/>
													</Grid>
												)
									  )}
							</Grid>
							<Grid container mt={7} mb={2} justifyContent="center">
								<Grid item>
									<Pagination
										count={productsData.nbPages}
										onChange={handlePageChange}
										page={queryParams?.page ? JSON.parse(queryParams?.page) : 1}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
}
