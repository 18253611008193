import { Box, Grid, Skeleton } from '@sourcewiz/the-source';

export default function ProductCardSkeleton() {
	return (
		<Grid
			container
			marginTop={2}
			justifyContent="space-between"
			alignItems="center">
			<Box>
				<Skeleton
					variant="rectangular"
					sx={{ borderRadius: '8px 8px 0 0', background: '#EEF1F7' }}
					width={300}
					height={240}
				/>
				<Box sx={{ pt: 1.5, ml: 1 }}>
					<Skeleton
						sx={{ padding: 1.5, background: '#EEF1F7' }}
						width="80%"
						variant="rectangular"
					/>
					<Skeleton
						sx={{ mt: 1, background: '#EEF1F7' }}
						variant="rectangular"
						width="60%"
					/>
					<Skeleton
						sx={{ mt: 1, background: '#EEF1F7' }}
						variant="rectangular"
						width="60%"
					/>
					<Skeleton
						sx={{
							mt: 1,
							mr: 1,
							padding: 2.5,
							borderRadius: '8px',
							background: '#EEF1F7',
						}}
						variant="rectangular"
					/>
				</Box>
			</Box>
		</Grid>
	);
}
