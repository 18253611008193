import {
	Avatar,
	Button,
	Grid,
	Icon,
	Input,
	Modal,
	Typography,
} from '@sourcewiz/the-source';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getFieldsCreateAccount,
	getUserList,
	createAccount as createAccountApi,
} from '../../../api/handlers/cart';
import { showSelectUserModal } from '../../../store/actions/cart.actions';
import { getInitials } from '../../../util/common';
import SelectContact from '../SelectContact/SelectContact';
import classes from './SelectUsers.module.css';
import RegisterAccount from '../CartDrawers/RegisterAccount/RegisterAccount';
export default function SelectUsers() {
	const users = useSelector((state) => state.cart.users);
	const [userList, setUserList] = useState([]);
	const [search, setSearch] = useState('');
	const [contactFields, setContactFields] = useState();
	const dispatch = useDispatch();
	const [showCreateAccount, toggleCreateAccount] = useState(false);
	const closeCreateAccount = () => {
		toggleCreateAccount(false);
	};
	const close = () => {
		dispatch(showSelectUserModal({ show: false }));
	};
	const createAccount = () => {
		toggleCreateAccount(true);
		close();
	};
	const [showContact, setShowContact] = useState(false);
	const closeContact = () => {
		setShowContact(false);
	};
	const confirmContact = () => {
		setShowContact(false);
		close();
	};
	const showContacts = () => {
		setShowContact(true);
	};
	const getUsers = () => {
		getUserList({ search })
			.then((res) => {
				setUserList(res?.data?.data || []);
			})
			.catch((error) => {
				console.error('Error while fetching user list', error);
			})
			.finally(() => {
				closeCreateAccount();
			});
	};

	const getFields = () => {
		getFieldsCreateAccount()
			.then((res) => {
				const data = res?.data?.data;
				if (data) {
					const basicDetails = data?.sections?.find(
						(sec) => sec.key === 'basic_details'
					);
					if (basicDetails) {
						setContactFields(basicDetails);
					}
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleSearchChange = (event) => {
		setSearch(event.target.value);
	};

	useEffect(() => {
		getUsers();
		getFields();
	}, [search]);

	const addContactClick = () => {
		const newContactFields = { ...contactFields };
		newContactFields.contacts.contacts = [
			...newContactFields.contacts.contacts,
			newContactFields.contacts.contacts[0],
		];
		setContactFields(newContactFields);
	};

	const createContact = () => {
		const payload = {
			basic_details: {
				attributes: contactFields.attributes.map((field) => ({
					attribute_id: field.attribute_id,
					value: field.value,
				})),
				contact: contactFields.contacts.contacts.map((contact) => ({
					attributes: contact.attributes.map((field) => ({
						attribute_id: field.attribute_id,
						value: field.value,
					})),
				})),
			},
		};
		createAccountApi(payload).then(() => {
			getUsers();
		});
	};

	const contactChange = (type, index, event) => {
		const val = type !== 'select' ? event.target.value : event.value;
		const newContactFields = { ...contactFields };
		newContactFields.contacts.contacts = [
			...newContactFields.contacts.contacts,
		];
		newContactFields.attributes[index].value = val;
		setContactFields(newContactFields);
	};
	const fieldChange = (type, index, contactIndex, event) => {
		const val = type !== 'select' ? event.target.value : event.value;
		const newContactFields = { ...contactFields };
		newContactFields.contacts.contacts = [
			...newContactFields.contacts.contacts,
		];
		newContactFields.contacts.contacts[contactIndex].attributes[index].value =
			val;
		setContactFields(newContactFields);
	};
	return (
		<>
			<Modal
				className={classes.modal}
				open={users.show}
				onClose={close}
				sx={{ ...(showContact ? { display: 'none !important' } : {}) }}
				aria-labelledby="custom-modal-title"
				aria-describedby="custom-modal-description">
				<Grid className={classes.grid}>
					<Grid container className={classes.header}>
						<Grid>
							<Typography variant="h6">Select Customer</Typography>
						</Grid>
						<Grid>
							<Icon
								iconName="IconX"
								onClick={close}
								sx={{ cursor: 'pointer' }}
							/>
						</Grid>
					</Grid>
					<Grid className={classes.search}>
						<Input
							autocomplete="off"
							onChange={handleSearchChange}
							variant="outlined"
							sx={{
								width: '100%',
								paddingRight: '1rem',
								background: 'white',
							}}
							type="text"
							value={search}
							InputProps={{
								startAdornment: (
									<Icon iconName="IconSearch" sx={{ marginRight: '0.4rem' }} />
								),
							}}
						/>
					</Grid>
					<Grid className={classes.userTileGrid}>
						{userList.map((att, index) => (
							<Grid
								key={index + 1}
								className={classes.userSelectTile}
								onClick={showContacts}
								container>
								<Grid className={classes.grid1} xs={10}>
									<Grid>
										<Avatar
											size="medium"
											variant="circular"
											isImageAvatar={!!att.imageUrl}
											src={att.imageUrl}
											backgroundColor="#6BA6FE"
											content={
												<Typography variant="body2" color="6BA6FE">
													{getInitials(att.heading)}
												</Typography>
											}
										/>
									</Grid>
									<Grid sx={{ marginLeft: '8px' }}>
										<Grid>
											<Typography variant="body2" color="rgba(0, 0, 0, 0.87)">
												{att.heading}
											</Typography>
										</Grid>
										<Grid>
											<Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
												{att.sub_heading}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid className={classes.grid2} xs={1}>
									<Icon iconName="IconChevronRight" color="#9AA0AA" />
								</Grid>
							</Grid>
						))}
					</Grid>
					<Grid className={classes.footer}>
						<Button variant="outlined" onClick={close}>
							Skip for now
						</Button>
						<Button tonal onClick={createAccount}>
							{' '}
							Register new
						</Button>
					</Grid>
				</Grid>
			</Modal>
			<SelectContact
				contactFields={contactFields}
				show={showContact}
				close={closeContact}
				confirmContact={confirmContact}
			/>
			<RegisterAccount
				open={showCreateAccount}
				close={closeCreateAccount}
				fields={contactFields}
				addContactClick={addContactClick}
				contactChange={contactChange}
				fieldChange={fieldChange}
				createContact={createContact}
			/>
		</>
	);
}
