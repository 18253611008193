import settingActionTypes from '../types/setting.types';
const INITIAL_STATE = {
	orgSettings: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case settingActionTypes.UPDATE_SETTINGS:
			return state;
		default:
			return state;
	}
};
export default userReducer;
