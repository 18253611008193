import axios from 'axios';

import { AUTH, ENDPOINT } from '../constants/apiEndpoints';
import {
	access_token,
	refresh_token,
	remove_tokens,
	setAuthCookies,
} from './common';

export const instance = axios.create({
	baseURL: ENDPOINT,
});

export const setTokenInHeader = (tokenPassed) => {
	instance.interceptors.request.use(function (config) {
		const token = access_token() || tokenPassed;
		if (token) {config.headers.Authorization = `Bearer ${token}`;}
		return config;
	});
};

setTokenInHeader();

let isRefreshing = false; // Add a flag to track if token refresh is already in progress
let refreshSubscribers = []; // Array to hold the pending API requests during token refresh

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;

		if (error.response && error.response.status === 401) {
			if (!isRefreshing) {
				isRefreshing = true;

				return axios
					.post(`${AUTH.REFRESH_TOKEN}`, {
						refresh: refresh_token(),
					})
					.then((response) => {
						const access = response.data?.access;
						const refresh = response.data?.refresh;
						setAuthCookies(access, refresh);
						setTokenInHeader(access);

						// Execute all the pending API requests with the updated token
						refreshSubscribers.forEach((subscriber) => subscriber(access));
						refreshSubscribers = [];

						return axios(originalRequest); // Retry the original API request
					})
					.catch((error2) => {
						if (error2.request.responseURL === AUTH.REFRESH_TOKEN) {
							remove_tokens();
							window.location.href = '/';
						}
						return Promise.reject(error);
					})
					.finally(() => {
						isRefreshing = false;
					});
			} else {
				// If token refresh is already in progress, create a new promise and add it to the subscribers array
				return new Promise((resolve, reject) => {
					refreshSubscribers.push((access) => {
						// Update the headers of the queued requests with the new token
						originalRequest.headers.Authorization = `Bearer ${access}`;

						// Retry the queued requests with the updated token
						axios(originalRequest)
							.then((response) => resolve(response))
							.catch((error) => reject(error));
					});
				});
			}
		} else {
			return Promise.reject(error);
		}
	}
);

export default instance;
