import { ACCOUNTS } from '../../constants/apiEndpoints';
import axios from '../../util/axios';

export const getCreateAccountsDetails = () => {
	return axios.get(ACCOUNTS.GET_CREATE_ACCOUNTS_FORM);
};

export const postCreateAccounts = (data) => {
	return axios.post(ACCOUNTS.POST_CREATE_ACCOUNT, data);
};
