import {
	Button,
	Drawer,
	Grid,
	Icon,
	Input,
	SingleSelect,
	Typography,
	Box,
} from '@sourcewiz/the-source';
import React from 'react';
import styles from './RegisterAccount.module.css';
export default function RegisterAccount({
	close,
	open,
	fields,
	addContactClick = () => {},
	contactChange = () => {},
	fieldChange = () => {},
	createContact = () => {},
}) {
	const closeDrawer = () => {
		close();
	};

	const submit = (event) => {
		event.preventDefault();
		if (event?.target?.form?.reportValidity()) {
			createContact();
		}
	};

	const renderDrawerData = () => {
		return (
			<Box component="form" container className={styles.container}>
				<Grid className={styles.body}>
					<Grid
						xs={12}
						item
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<Typography variant="h5">Register a new account</Typography>
						<Icon
							iconName="IconX"
							sx={{ cursor: 'pointer' }}
							onClick={() => closeDrawer()}
						/>
					</Grid>
					<Grid marginTop="2rem" item xs={12}>
						{fields?.attributes?.map((field, index) => {
							return (
								<Grid className={styles.evenMargin} key={index + 1}>
									{field?.type !== 'select' && (
										<Input
											type={field?.type}
											onChange={(event) =>
												contactChange(field?.type, index, event)
											}
											variant="outlined"
											placeHolder={field?.place_holder || field?.name}
											label={field?.name}
											required={field?.required}
											value={field?.value}
											fullWidth>
											{field?.name}
										</Input>
									)}
									{field?.type === 'select' && (
										<SingleSelect
											type={field?.type}
											variant="outlined"
											handleChange={(event) =>
												contactChange(field?.type, index, event)
											}
											placeHolder={field?.place_holder || field?.name}
											label={field?.name}
											defaultValue={field?.value}
											required={field?.required}
											options={field?.options}
											fullWidth>
											{field?.name}
										</SingleSelect>
									)}
								</Grid>
							);
						})}
					</Grid>
					{fields?.contacts?.contacts.map((contact, contactIndex) => {
						return (
							<Grid key={contactIndex + 1} className={styles.contactFields}>
								<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
									{contact?.name ? contact.name : `Contact ${contactIndex + 1}`}
								</Typography>
								{contact?.attributes?.map((field, index) => {
									return (
										<Grid key={index + 1} className={styles.evenMargin}>
											<Input
												onChange={(event) =>
													fieldChange(field?.type, index, contactIndex, event)
												}
												type={field?.type}
												variant="outlined"
												placeHolder={field?.name}
												label={field?.name}
												required={field?.required}
												value={field?.value}
												fullWidth>
												{field?.name}
											</Input>
										</Grid>
									);
								})}
							</Grid>
						);
					})}
					<Grid className={styles.evenMargin}>
						<Button variant="text" onClick={addContactClick}>
							<Icon iconName="IconPlus" color="inherit" />
							&nbsp;&nbsp;Contact
						</Button>
					</Grid>
				</Grid>
				<Grid className={styles.footer}>
					<Button fullWidth onClick={submit} type="submit">
						Confirm
					</Button>
				</Grid>
			</Box>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={open}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
		/>
	);
}
