import './App.css';

import { ThemeProvider } from '@sourcewiz/the-source';
import { useEffect } from 'react';

import Toast from './components/Toast/Toast';
import Routes from './routes';
import axiosSetup from './util/axios';
function App() {
	useEffect(() => {
		axiosSetup();
	}, []);
	return (
		<ThemeProvider>
			{/* <Interceptor /> */}
			<Routes />
			<Toast />
		</ThemeProvider>
	);
}

export default App;
