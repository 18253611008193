import { Box, Grid, PageHeader, Skeleton } from '@sourcewiz/the-source';
import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { LeftSection, RightSection } from './HeaderComponent';

export default function ProductDetailsSkeleton() {
	const { width = 1300 } = useWindowSize();
	return (
		<Box>
			<Box mb="1em">
				<PageHeader leftSection={LeftSection()} rightSection={RightSection()} />
			</Box>
			<Box sx={{ background: '#fff' }} height="90vh">
				<Grid container padding={3}>
					<Grid item xs={6}>
						<Box p={2}>
							<Skeleton
								sx={{
									background:
										'linear-gradient(130.68deg, #FAFAFA -14.13%, rgba(217, 217, 217, 0.13) 47.62%, #F2F4F7 122.49%)',
								}}
								animation="wave"
								variant="rounded"
								height={width <= '1300' ? '50vh' : '70vh'}
								width="95%"
							/>
						</Box>
						<Box>
							<Grid container mt={1} spacing={1}>
								<Grid item>
									<Skeleton
										sx={{ background: '#EEF1F7' }}
										animation="wave"
										variant="rounded"
										height={60}
										width={60}
									/>
								</Grid>
								<Grid item>
									<Skeleton
										sx={{ background: '#EEF1F7' }}
										animation="wave"
										variant="rounded"
										height={60}
										width={60}
									/>
								</Grid>
								<Grid item>
									<Skeleton
										sx={{ background: '#EEF1F7' }}
										animation="wave"
										variant="rounded"
										height={60}
										width={60}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item ml={2}>
						<Box>
							<Grid mt={1}>
								<Grid mb={2.5}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="10vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={5}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="20vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={3}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="30vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={3}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="30vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={5}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="18vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={4}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="30vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={3}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="25vw"
										variant="rectangular"
									/>
								</Grid>
								<Grid mb={3}>
									<Skeleton
										sx={{ borderRadius: '20px', background: '#EEF1F7' }}
										height={19}
										width="18vw"
										variant="rectangular"
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
