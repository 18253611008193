import PropTypes from 'prop-types';
import {
	Avatar,
	Button,
	Grid,
	Icon,
	Modal,
	Typography,
} from '@sourcewiz/the-source';
import React, { useState } from 'react';

import classes from './SelectContact.module.css';
export default function SelectContact({
	show = false,
	close = () => {},
	confirmContact = () => {},
}) {
	const [selected, selectOption] = useState(null);
	return (
		<Modal
			className={classes.modal}
			open={show}
			onClose={close}
			aria-labelledby="custom-modal-title"
			aria-describedby="custom-modal-description">
			<Grid className={classes.grid}>
				<Grid container className={classes.header}>
					<Grid>
						<Typography variant="h6">Select Customer</Typography>
					</Grid>
					<Grid>
						<Icon iconName="IconX" onClick={close} sx={{ cursor: 'pointer' }} />
					</Grid>
				</Grid>
				<Grid className={classes.userTileGrid} container>
					{[1, 2, 3, 4, 45, 5, 5, 6, 6].map((attr, index) => (
						<Grid
							key={index + 1}
							className={classes.userSelectTile}
							container
							onClick={() => selectOption(index)}>
							<Grid className={classes.grid1} xs={10}>
								<Grid>
									<Avatar
										size="medium"
										variant="circular"
										isImageAvatar={false}
										backgroundColor="#6BA6FE"
										content={
											<Typography variant="body2" color="6BA6FE">
												SM
											</Typography>
										}
									/>
								</Grid>
								<Grid sx={{ marginLeft: '8px' }}>
									<Grid>
										<Typography variant="body2" color="rgba(0, 0, 0, 0.87)">
											Contact Name
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid className={classes.grid1} xs={1} justifyContent="center">
								{index === selected && (
									<Icon iconName="IconCircleCheck" color="#16885F" />
								)}
							</Grid>
						</Grid>
					))}
				</Grid>
				<Grid className={classes.footer}>
					<Button fullWidth onClick={confirmContact}>
						Confirm
					</Button>
				</Grid>
			</Grid>
		</Modal>
	);
}

SelectContact.propTypes = {
	close: PropTypes.func.isRequired,
	confirmContact: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
};
