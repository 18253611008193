import PropTypes from 'prop-types';
import {
	Button,
	Grid,
	Icon,
	Image,
	Menu,
	Typography,
} from '@sourcewiz/the-source';
import { useDispatch } from 'react-redux';

import {
	productToCartAddAction,
	removeAPRoductFromCart,
} from '../../store/actions/cart.actions';
import { getFallbackSrc, getImageSrc } from '../../util/common';
import styles from './CheckoutCartCard.module.css';
export default function CheckoutCartStatic(props) {
	const dispatch = useDispatch();
	const {
		sx = {},
		product_image = 'https://sourcerer.tech/assets/cf5c0bbb-a2e2-4b7f-803a-13eb96c76681',
		heading = 'product name',
		attributes = [],
		sub_heading = '',
		product_action = {
			value: 'Reserved',
			options: [
				{ label: 'Reserved', value: 'Reserved' },
				{ label: 'Not Reserved', value: 'Not_Reserved' },
				{ label: 'Customized', value: 'Customized' },
			],
			isEnabled: true,
			actionSubtitle: {
				text: '',
				iconName: 'IconInfoCircle',
			},
		},
		id,
		inventory = {},
		price = {},
		quantity = {},
		note = '',
		index,
		notEditable = false,
	} = props;

	const remove_a_product = () => {
		dispatch(removeAPRoductFromCart(index, id));
	};

	let color = '#E1EDFF';
	switch (product_action.value) {
		case 'Reserved':
			color = '#E1EDFF';
			break;
		case 'Not Reserved':
			color = '#F7DBCF';
			break;
		case 'Customised':
			color = '#F9DFAC';
			break;
		default:
			break;
	}

	const optionsMenu = product_action.options.map((option) => {
		return {
			label: (
				<Grid
					variant="text"
					sx={{
						width: '100%',
						padding: '0.3rem',
					}}
					fullWidth
					onClick={() => {
						dispatch(productToCartAddAction(id, option.label, option.id));
					}}>
					{option.label}
				</Grid>
			),
			value: option.value,
		};
	});

	return (
		<Grid sx={{ sx }} container className={styles.container}>
			<Grid item className={styles.image}>
				{!notEditable && (
					<Grid item className={styles.iconAction}>
						<Icon
							iconName="IconTrash"
							className={styles.btnTrash}
							onClick={remove_a_product}
						/>
					</Grid>
				)}
				<Grid className={styles.imageAndAttributes}>
					<Image
						imgClass={styles.imageCls}
						src={getImageSrc(product_image)}
						fallbackSrc={getFallbackSrc(product_image)}
					/>
					<Grid
						item
						display="flex"
						flexDirection="column"
						className={styles.attributes}>
						<Typography
							sx={{ fontWeight: 'bold' }}
							variant="subtitle2"
							className={styles.title + ' ' + styles.overFlowText}>
							{heading}
						</Typography>
						{sub_heading && (
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{sub_heading}
							</Typography>
						)}
						{attributes?.map((attr, index) => {
							return (
								<Typography
									key={index}
									className={styles.overFlowText}
									variant="subtitle2"
									sx={{ fontWeight: '300', marginTop: '5px' }}
									color="rgba(0, 0, 0, 0.87)">
									{attr.value}
								</Typography>
							);
						})}
						{note && (
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{note}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item className={styles.rightControls}>
				<Grid item className={styles.actionsMid}>
					{(product_action?.dtype !== 'select' || notEditable) && (
						<Grid>
							<Button
								sx={{
									borderRadius: '10px',
									background: color,
									padding: '0.5rem',
									color: 'black',
									':hover': {
										background: color,
									},
								}}
								className={styles.btnClass}>
								{product_action?.value}
							</Button>
						</Grid>
					)}
					{product_action?.dtype === 'select' && !notEditable && (
						<Grid>
							<Menu
								menu={optionsMenu}
								btnStyle={{ padding: '0px' }}
								className={styles.menu}
								LabelComponent={
									<Button
										sx={{
											borderRadius: '10px',
											background: color,
											padding: '0.5rem',
											color: 'black',
											':hover': {
												background: color,
											},
										}}
										endIcon={<Icon iconName="IconChevronDown" />}
										className={styles.btnClass}>
										{product_action.value}
									</Button>
								}
							/>
						</Grid>
					)}
					{!notEditable && (
						<Grid sx={{ marginTop: '1rem' }}>
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{inventory.list[0]}
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid item className={styles.actionsRight}>
					<Grid className={styles.staticQuntityGrid}>
						{quantity?.value && (
							<Grid>
								<Typography
									variant="subtitle"
									sx={{
										background: '#F7F8FA',
										borderRadius: '8px',
										padding: '0.5rem',
									}}>
									QTY : {quantity.value}
								</Typography>
							</Grid>
						)}
						<Grid className={styles.staticQuntity}>
							{price?.discount_value ? (
								<>
									<Typography
										variant="subtitle"
										sx={{
											textDecoration: 'line-through',
											marginRight: '0.5rem',
										}}
										color="grey">
										{price?.currency} {price?.price}
									</Typography>
									<Typography
										variant="subtitle"
										sx={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
										{price?.currency}
										{price?.after_discount}
									</Typography>
								</>
							) : (
								<Typography variant="subtitle" sx={{ fontWeight: 'bold' }}>
									{price?.currency} {price?.after_discount}
								</Typography>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

CheckoutCartStatic.propTypes = {
	attributes: PropTypes.array.isRequired,
	heading: PropTypes.string.isRequired,
	id: PropTypes.any.isRequired,
	index: PropTypes.any.isRequired,
	inventory: PropTypes.object.isRequired,
	notEditable: PropTypes.bool.isRequired,
	note: PropTypes.string.isRequired,
	price: PropTypes.object.isRequired,
	product_action: PropTypes.shape({
		actionSubtitle: PropTypes.shape({
			iconName: PropTypes.string,
			text: PropTypes.string,
		}),
		dtype: PropTypes.string,
		isEnabled: PropTypes.bool,
		options: PropTypes.shape({
			map: PropTypes.func,
		}),
		value: PropTypes.string,
	}).isRequired,
	product_image: PropTypes.string.isRequired,
	quantity: PropTypes.object.isRequired,
	staticProps: PropTypes.bool.isRequired,
	sub_heading: PropTypes.string.isRequired,
	sx: PropTypes.object.isRequired,
};
