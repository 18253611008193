import PropTypes from 'prop-types';
import {
	Button,
	Counter,
	Grid,
	Icon,
	Image,
	Menu,
	Typography,
} from '@sourcewiz/the-source';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	productChange,
	productToCartAddAction,
	removeAPRoductFromCart,
	showHideNotesDrawer,
} from '../../store/actions/cart.actions';
import { getFallbackSrc, getImageSrc } from '../../util/common';
import styles from './CheckoutCartCard.module.css';

export default function CheckoutCartCard(props) {
	const cardRef = useRef(null);
	const dispatch = useDispatch();
	const {
		sx = {},
		product_image = 'https://sourcerer.tech/assets/cf5c0bbb-a2e2-4b7f-803a-13eb96c76681',
		heading = 'product name',
		entity_id,
		sub_heading = '',
		product_action = {
			value: 'Reserved',
			options: [
				{ label: 'Reserved', value: 'reserve' },
				{ label: 'Not Reserved', value: 'not_reserve' },
				{ label: 'Customized', value: 'Customized' },
			],
			isEnabled: true,
			actionSubtitle: {
				text: '',
				iconName: 'IconInfoCircle',
			},
		},
		id,
		inventory = {},
		price = {},
		staticProps = false,
		quantity = {},
		meta_data = { note: '' },
		index,
		notEditable = false,
	} = props;

	const navigate = useNavigate();
	const handleCounterIncrement = (currentVal) => {
		dispatch(productChange({ currentVal, product: props }));
	};
	const handleCounterDecrement = (currentVal) => {
		dispatch(productChange({ currentVal, product: props }));
	};
	const handleLimit = () => {};
	const handleRemoveFromCart = () => {};
	const { min_order_quantity = 1, max_order_quantity = 100 } = inventory;
	const incremental_value = inventory?.incremental_value;
	const remove_a_product = () => {
		dispatch(removeAPRoductFromCart(index, id));
	};
	const editNotes = () => {
		dispatch(
			showHideNotesDrawer({
				show: true,
				value: meta_data?.message,
				meta_data,
				index,
				entity_id: entity_id,
			})
		);
	};
	const navigateToProductDetail = () => {
		navigate(`/product-details/${entity_id}`);
	};
	// product_action?.dtype !== 'select';
	let color = '#E1EDFF';
	switch (product_action.value) {
		case 'Reserve':
			color = '#E1EDFF';
			break;
		case 'Not Reserve':
			color = '#F7DBCF';
			break;
		case 'Customised':
			color = '#F9DFAC';
			break;
		default:
			break;
	}

	const optionsMenu = product_action.options.map((option) => {
		return {
			label: (
				<Grid
					variant="text"
					sx={{
						width: '100%',
						padding: '0.3rem',
					}}
					fullWidth
					onClick={() => {
						dispatch(
							productToCartAddAction(id, entity_id, option.label, option.id)
						);
					}}>
					{option.label}
				</Grid>
			),
			value: option.value,
		};
	});

	return (
		<div style={{ sx }} ref={cardRef} className={styles.container}>
			<Grid item className={styles.image}>
				{!notEditable && (
					<Grid item className={styles.iconAction}>
						<Icon
							iconName="IconTrash"
							className={styles.btnTrash}
							onClick={remove_a_product}
						/>
					</Grid>
				)}
				<Grid className={styles.imageAndAttributes}>
					<Image
						imgClass={styles.imageCls}
						onClick={navigateToProductDetail}
						src={product_image && getImageSrc(product_image)}
						fallbackSrc={product_image && getFallbackSrc(product_image)}
					/>
					<Grid
						item
						display="flex"
						flexDirection="column"
						className={styles.attributes}>
						<Typography
							sx={{ fontWeight: 'bold' }}
							variant="subtitle2"
							className={styles.title + ' ' + styles.overFlowText}>
							{heading}
						</Typography>
						{sub_heading && (
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{sub_heading}
							</Typography>
						)}
						{price?.product_price && (
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{price?.currency + ' ' + price?.product_price}
							</Typography>
						)}
						{meta_data?.message && (
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{meta_data.message}
							</Typography>
						)}
						{meta_data?.message && (
							<Typography
								variant={'body2'}
								onClick={editNotes}
								sx={{
									fontSize: '14px',
									marginTop: '4px',
									color: '#16885F',
									cursor: 'pointer',
									fontWeight: '700',
								}}
								width="80px">
								Edit Note
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item className={styles.rightControls}>
				<Grid item className={styles.actionsMid}>
					{(product_action?.dtype !== 'select' || notEditable) && (
						<Grid>
							<Button
								sx={{
									borderRadius: '10px',
									background: color,
									padding: '0.5rem',
									color: 'black',
									':hover': {
										background: color,
									},
								}}
								className={styles.btnClass}>
								{product_action?.value}
							</Button>
						</Grid>
					)}
					{product_action?.dtype === 'select' && !notEditable && (
						<Grid>
							<Menu
								menu={optionsMenu}
								btnStyle={{ padding: '0px' }}
								className={styles.menu}
								closeOnItemClick
								LabelComponent={
									<Button
										sx={{
											borderRadius: '10px',
											background: color,
											padding: '0.5rem',
											color: 'black',
											':hover': {
												background: color,
											},
										}}
										endIcon={<Icon iconName="IconChevronDown" />}
										className={styles.btnClass}>
										{product_action.value}
									</Button>
								}
							/>
						</Grid>
					)}
					{!notEditable && (
						<Grid sx={{ marginTop: '1rem' }}>
							<Typography
								className={styles.overFlowText}
								variant="subtitle2"
								sx={{ fontWeight: '300', marginTop: '5px' }}
								color="rgba(0, 0, 0, 0.87)">
								{inventory.list[0]}
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid item className={styles.actionsRight}>
					{!staticProps && !notEditable && (
						<Counter
							handleIncrement={handleCounterIncrement}
							handleDecrement={handleCounterDecrement}
							handleLimit={handleLimit}
							handleRemoveFromCart={handleRemoveFromCart}
							min={min_order_quantity}
							max={max_order_quantity}
							error={quantity?.value > max_order_quantity}
							disableIncrement={
								quantity?.value + incremental_value > max_order_quantity
							}
							initialCount={quantity?.value}
							step={incremental_value}
						/>
					)}

					<Grid className={styles.staticQuntityGrid}>
						{staticProps && inventory?.stock && (
							<Grid sx={{ marginTop: '1rem' }}>
								<Typography
									variant="subtitle"
									sx={{
										background: '#F7F8FA',
										borderRadius: '8px',
										padding: '0.5rem',
									}}>
									QTY : {inventory.stock}
								</Typography>
							</Grid>
						)}
						<Grid className={styles.staticQuntity}>
							{price?.discount_value ? (
								<>
									<Typography
										variant="subtitle"
										sx={{
											textDecoration: 'line-through',
											marginRight: '0.5rem',
										}}
										color="grey">
										{price?.currency} {price?.price}
									</Typography>
									{!!price?.after_discount && (
										<Typography
											variant="subtitle"
											sx={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
											{price?.currency}
											{price?.after_discount}
										</Typography>
									)}
								</>
							) : (
								!!price?.after_discount && (
									<Typography variant="subtitle" sx={{ fontWeight: 'bold' }}>
										{price?.currency} {price?.after_discount}
									</Typography>
								)
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

CheckoutCartCard.propTypes = {
	attributes: PropTypes.array.isRequired,
	entity_id: PropTypes.any.isRequired,
	heading: PropTypes.string.isRequired,
	id: PropTypes.any.isRequired,
	index: PropTypes.any.isRequired,
	inventory: PropTypes.shape({
		max_order_quantity: PropTypes.number,
		min_order_quantity: PropTypes.number,
	}).isRequired,
	meta_data: PropTypes.shape({
		message: PropTypes.any,
		note: PropTypes.string,
	}).isRequired,
	notEditable: PropTypes.bool.isRequired,
	price: PropTypes.object.isRequired,
	product_action: PropTypes.shape({
		actionSubtitle: PropTypes.shape({
			iconName: PropTypes.string,
			text: PropTypes.string,
		}),
		dtype: PropTypes.string,
		isEnabled: PropTypes.bool,
		options: PropTypes.shape({
			map: PropTypes.func,
		}),
		value: PropTypes.string,
	}).isRequired,
	product_image: PropTypes.string.isRequired,
	quantity: PropTypes.object.isRequired,
	staticProps: PropTypes.bool.isRequired,
	sub_heading: PropTypes.string.isRequired,
	sx: PropTypes.object.isRequired,
};
