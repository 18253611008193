import messageTypes from '../types/message.types';
let timeout;
export const showToast = (payload) => async (dispatch) => {
	clearTimeout(timeout);
	timeout = setTimeout(() => {
		dispatch({ type: messageTypes.SHOW_INITIAL_STATE });
	}, payload.autoHideDuration);
	dispatch({ type: messageTypes.SHOW_MESSAGE, payload });
};

export const closeToast = (email) => ({
	type: messageTypes.SHOW_INITIAL_STATE,
	email,
});
