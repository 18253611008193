// // get cart details header -> api after login
// let a = {
// 	count: 1,
// 	userName: 'John',
// 	iconImage: '',
// 	textColor: '',
// 	bgColor: '',
// };

// // get cart Details

// let b = {
// 	heading: '8 Products added to cart',
// 	currency: '$',
// 	id: '',
// 	products: [
// 		{
// 			productImage: '',
// 			productId: '1',
// 			heading: '',
// 			attributes: [
// 				{
// 					label: '',
// 					color: '',
// 					bgColor: '',
// 				},
// 			],
// 			price: {
// 				currency: '$',
// 				discountValue: '',
// 				discountType: '',
// 				price: '',
// 			},
// 			quantity: {
// 				value: '', // saksham quantity payload
// 				incrementValue: '',
// 				bulkPricing: {}, // saksham connect
// 			},
// 			inventory: {}, // saksham connect ,
// 			productAction: {
// 				dtype: 'select/text',
// 				value: 'Reserved',
// 				options: [
// 					{ label: 'reserve', id: 'Reserve' },
// 					{ lable: 'not reservce', id: 'not reserve' },
// 				],
// 			},
// 		},
// 	],
// 	bulkEdit: {
// 		visibility: 'true/false',
// 		icon: '',
// 		data: [
// 			{
// 				columns: [
// 					{
// 						name: 'product',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 					{
// 						name: 'Listing price',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 					{
// 						name: 'Discount ',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 					{
// 						name: 'Final Price',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 					{
// 						name: 'Quantity',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 					{
// 						name: 'total',
// 						key: '123',
// 						Filter: true,
// 						Dtype: 'text',
// 						Priority: 2,
// 						Sort: true,
// 						editable: true,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	userId: '',
// 	cart: {
// 		id: '',
// 		status,
// 		createdAt,
// 		quantity,
// 		total_amount,
// 		actions,
// 		summary: {
// 			subtotal,
// 			discount,
// 			total_taxes,
// 			grand_total,
// 		},
// 		discount: {
// 			max_limit: 1,
// 			max_amount,
// 			data: [{ type: 'percent/fix-amount', quantity }],
// 		},
// 		taxes: {
// 			max_limit: 50,
// 			data: [{ text: '', value }],
// 		},
// 	},
// 	action: ['DRAFT', 'QUOTE', 'ORDER'],
// 	cart_status: 'DRAFT/QUOTE/ORDERED',
// 	quote_status: '',
// 	quotationId: 'id',
// };

// /get/quotation/data

export const mockedContract = {
	id: 'eb05c8b1-a11f-4160-8efd-01dc3266787a',
	tenant_id: '03b40c6a-6a15-47f6-90d1-e56bdf8ec1c6',
	entities: [
		{
			id: 'a9490d05-d8a0-40df-80f0-0b0eaad415fb',
			entity_id: '1',
			note: null,
			status: 'published',
			source: null,
			created_at: '2023-04-25T13:32:28.958061Z',
			updated_at: '2023-05-01T14:02:51.558019Z',
			quantity: {
				value: 20,
				bulkPricing: {
					is_enabled: true,
					data: [
						{
							range: '100 - 500 pcs',
							quantity: 100,
							price: '$ 24.80',
						},
						{
							range: '500 - 1000 pcs',
							quantity: 500,
							price: '$ 24.80',
						},
						{
							range: '100 - 500 pcs',
							quantity: 100,
							price: '$ 24.80',
						},
					],
				},
			},
			price: {
				currency: '$',
				discount_value: 10,
				discount_type: 'fix-amount',
				price: 120,
				after_discount: 108,
			},
			price_change_value: 10,
			price_change_type: 'percent',
			action: 'reserved',
			meta_data: {
				message: 'This is some custom message',
			},
			cart: 'eb05c8b1-a11f-4160-8efd-01dc3266787a',
			tenant: '03b40c6a-6a15-47f6-90d1-e56bdf8ec1c6',
			created_by: null,
			updated_by: null,
			entity: '861fb9a6-ed3f-487f-b180-f7e6a5dd133b',
			heading: 'MA-NJ-TE-ST-1',
			sub_heading: 'Furniture - 101',
			product_image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
			product_action: {
				dtype: 'select',
				value: 'Reserved',
				options: [
					{
						id: 'reserved',
						label: 'Reserved',
					},
					{
						id: 'not_reserved',
						label: 'Not Reserved',
					},
				],
			},
			inventory: {
				min_order_quantity: 5,
				incremental_value: 10,
				max_order_quantity: 100,
				stock: 500,
				name: 'In Stock: 500 pcs',
				box_color: 'primary',
				show_stock_list: true,
				list: ['In Stock: 100 pcs', 'On Hold: 100 pcs', 'On Loom: 50 pcs'],
			},
			attributes: [
				{
					color: 'white',
					bgcolor: 'black',
					label: 'Size',
					value: '10 X 12 X 12 cm',
					dtype: 'text',
					priority: 1,
				},
				{
					color: 'white',
					bgcolor: 'black',
					label: 'Color',
					value: 'red, green',
					dtype: 'text',
					priority: 2,
				},
			],
		},
		{
			id: '995d7c26-70e8-44e2-aecf-43710b2096ba',
			note: 'This is some custom message',
			status: 'published',
			entity_id: '2',
			source: null,
			created_at: '2023-04-25T13:36:00.236293Z',
			updated_at: '2023-05-01T14:02:51.563838Z',
			quantity: {
				value: 10,
				bulkPricing: {
					is_enabled: true,
					data: [
						{
							range: '100 - 500 pcs',
							quantity: 100,
							price: '$ 24.80',
						},
						{
							range: '500 - 1000 pcs',
							quantity: 500,
							price: '$ 24.80',
						},
						{
							range: '100 - 500 pcs',
							quantity: 100,
							price: '$ 24.80',
						},
					],
				},
			},
			price: {
				currency: '$',
				discount_value: null,
				discount_type: null,
				price: 100,
				after_discount: 100,
			},
			price_change_value: null,
			price_change_type: null,
			action: 'customized',
			meta_data: {
				custom_message: null,
			},
			cart: 'eb05c8b1-a11f-4160-8efd-01dc3266787a',
			tenant: '03b40c6a-6a15-47f6-90d1-e56bdf8ec1c6',
			created_by: null,
			updated_by: null,
			entity: 'e92c99cf-b49a-43a9-9c5c-07d75686692a',
			heading: 'SB-33-V-322',
			sub_heading: 'Rugs - 101',
			product_image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
			product_action: {
				dtype: 'text',
				value: 'Customised',
				options: [
					{
						id: 'customised',
						label: 'Customised',
					},
				],
			},
			inventory: {
				min_order_quantity: 5,
				incremental_value: 10,
				max_order_quantity: 100,
				stock: 500,
				name: 'In Stock: 500 pcs',
				box_color: 'primary',
				show_stock_list: true,
				list: ['In Stock: 100 pcs', 'On Hold: 100 pcs', 'On Loom: 50 pcs'],
			},
			attributes: [
				{
					color: 'white',
					bgcolor: 'black',
					label: 'Size',
					value: '10 X 12 X 12 cm',
					dtype: 'text',
					priority: 1,
				},
				{
					color: 'white',
					bgcolor: 'black',
					label: 'Color',
					value: 'red, green',
					dtype: 'text',
					priority: 2,
				},
			],
		},
	],
	heading: '2 products added to cart',
	cart: {
		id: 'eb05c8b1-a11f-4160-8efd-01dc3266787a',
		status: 'published',
		created_at: '2023-04-25T13:31:57.618658Z',
		total_amount: 110.0,
		actions: ['save_to_draft', 'submit_quote', 'submit_order'],
		summary: {
			subtotal: 110.0,
			discount: 11.0,
			total_taxes: 25.0,
			grand_total: 124.0,
		},
		discount: {
			max_limit: 100,
			max_amount: null,
			data: [
				{
					type: 'percent',
					value: 10,
				},
			],
		},
		taxes: {
			max_limit: 100,
			data: [
				{
					text: 'government tax',
					value: 10,
				},
				{
					text: 'income tax',
					value: 15,
				},
			],
		},
	},
	currency: '$',
	bulk_edit_visibility: true,
};

export const dataBulkMock = {
	columns: [
		{
			name: 'product',
			key: 'product',
			editable: true,
		},
		{
			name: 'Listing price',
			key: 'listing_price',
			editable: true,
		},
		{
			name: 'discount',
			key: 'discount',
			editable: true,
		},
		{
			name: 'Final price',
			key: 'final_price',
			editable: true,
		},
		{
			name: 'Quantity',
			key: 'qunantity',
			editable: true,
		},
		{
			name: 'total',
			key: 'total',
			editable: true,
		},
	],
	rows: [
		{
			product: {
				image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
				id: 'MANJTEST1',
				name: 'Demo Product 1',
				action: 'reserved',
			},
			listing_price: 200,
			discount: {
				discount_type: 'fix-amount',
				discount_value: 20.0,
			},
			final_price: 120.0,
			quantity: 20,
			total: 2400.0,
			cart_product_id: 'a9490d05-d8a0-40df-80f0-0b0eaad415fb',
		},
		{
			product: {
				image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
				id: 'MAN-JTEST-1',
				name: 'Demo Product 3',
				action: 'custom',
			},
			listing_price: null,
			discount: null,
			final_price: 10.0,
			quantity: 11,
			total: 1100.0,
			cart_product_id: 'a9490d05-d8a0-40df-80f0-0b0eaad412fb',
		},
		{
			product: {
				image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
				id: 'MA-NJ-TE-ST-1',
				name: 'Demo Product 4',
				action: 'reserved',
			},
			listing_price: 200,
			discount: {
				discount_type: 'percent',
				discount_value: 10,
			},
			final_price: 90.0,
			quantity: 20,
			total: 1800.0,
			cart_product_id: 'a9490d05-d8a0-40df-80f0-0b0eaad415fb',
		},
		{
			product: {
				image: '5f0f1140-16ec-4663-9ba7-4da7f5fb0690',
				id: 'M-ANJ-TE-ST1',
				name: 'Demo Product 2',
				action: 'custom',
			},
			listing_price: null,
			discount: null,
			final_price: 100.0,
			quantity: 20,
			total: 2000.0,
			cart_product_id: 'a9490d05-d8a0-40df-80f0-0b0eaad415fb',
		},
	],
	currency: '$',
};

export const reviewSubmitData = {
	quotation_details: [
		{
			name: 'Notes',
			section: 'notes',
			priority: 1,
			is_editable: 'true',
			all_data: [
				{
					key: 'text',
					dtype: 'text',
					default: 'null',
					place_holder: 'Enter your notes here',
					value: '',
					priority: 1,
				},
				{
					key: 'is_sharing_enabled',
					text: 'Share notes with customer',
					dtype: 'checkbox',
					value: 'false',
					default: 'false',
					priority: 2,
				},
			],
			attributes: [
				{
					text: 'method1,method2, method3',
					color: '#000000',
					dtype: 'text',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					key: 'text',
					dtype: 'checkbox',
					value: 'false',
					text: 'Share notes with customer',
					priority: 2,
				},
			],
		},
		{
			name: 'Customer information',
			section: 'user_details',
			priority: 2,
			is_editable: 'true',
			all_data: [],
			section_data: [
				{
					id: 'acddcc',
					key: 'shipping_address',
					name: 'Shipping Address',
					value: ['addr1', 'addr2', 'addr3'],
				},
				{
					id: 'acddccddddd',
					key: 'billing_address',
					name: 'Billing Address',
					value: ['addr1', 'addr2', 'addr3'],
				},
			],
			attributes: [
				{
					key: 'name',
					text: 'anshul',
					style: {
						'text-align': 'left',
						font_weight: 'bold',
					},
					priority: 1,
				},
				{
					key: 'email',
					text: 'adasn@dsa.co',
					style: {
						'text-align': 'left',
						font_weight: 'bold',
					},
					priority: 2,
				},
				{
					key: 'tenant_name',
					text: 'acbd org',
					style: {
						'text-align': 'right',
						font_weight: 'bold',
					},
					priority: 1,
				},
				{
					key: 'tenant_country',
					text: 'Agra',
					style: {
						'text-align': 'right',
						font_weight: 'bold',
					},
					priority: 2,
				},
			],
		},
		{
			name: 'Shipping Method',
			dtype: 'select',
			section: 'shipping_method',
			priority: 3,
			is_editable: 'true',
			multi_select: 'true',
			all_data: [
				{
					id: 'method1',
					name: 'Shipping',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method2',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method3',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method4',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'true',
				},
			],
			attributes: [
				{
					label: 'method1,method2, method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			],
		},
		{
			name: 'Payment Method',
			section: 'payment_method',
			priority: 4,
			is_editable: 'true',
			multi_select: 'true',
			all_data: [
				{
					id: 'method1',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method2',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method3',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
				{
					id: 'method4',
					name: 'Cart',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'true',
				},
			],
			attributes: [
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			],
		},
		{
			name: 'Payment Terms',
			section: 'payment_terms',
			priority: 5,
			is_editable: 'true',
			multi_select: 'true',
			all_data: [
				{
					id: 'method1',
					name: 'Term1',
					color: '#000000',
					bg_color: '#FFFFFF',
					is_selected: 'false',
				},
			],
			attributes: [
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			],
		},
		{
			name: 'Bank Details',
			section: 'bank_details',
			priority: 6,
			is_editable: 'true',
			multi_select: 'false',
			all_data: [],
			attributes: [
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			],
		},
		{
			name: 'Bank Details 2',
			section: 'bank_details',
			priority: 6,
			is_editable: 'true',
			multi_select: 'false',
			all_data: [],
			attributes: [
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			],
		},
	],
	billing_details: {
		attributes: [
			{
				dtype: 'text',
				place_holder: 'Detail 1',
			},
			{
				dtype: 'text',
				place_holder: 'Bank no.',
			},
			{
				dtype: 'text',
				place_holder: 'Account no.',
			},
			{
				dtype: 'Date',
				place_holder: 'Start Date',
			},
		],
	},
};

export const mockedAddressList = [
	{
		name: 'main',
		id: '11111',
		attributes: [
			{ label: 'c 67 111', color: '#000000', priority: 0 },
			{ label: 'Al ziena, floor 2', color: '#000000', priority: 1 },
			{ label: 'Delhi , India', color: '#000000', priority: 2 },
			{ label: '110065', color: '#000000', priority: 3 },
		],
	},
	{
		name: 'secondary',
		id: '111123',
		attributes: [
			{ label: 'c 67 114', color: '#000000', priority: 0 },
			{ label: 'Al ziena, floor 2', color: '#000000', priority: 1 },
			{ label: 'Delhi , India', color: '#000000', priority: 2 },
			{ label: '110065', color: '#000000', priority: 3 },
		],
	},
	{
		name: 'office',
		id: '11124',
		attributes: [
			{ label: 'c 67 113', color: '#000000', priority: 0 },
			{ label: 'Al ziena, floor 2', color: '#000000', priority: 1 },
			{ label: 'Delhi , India', color: '#000000', priority: 2 },
			{ label: '110065', color: '#000000', priority: 3 },
		],
	},
	{
		name: 'primary',
		id: '11122',
		attributes: [
			{ label: 'c 67 112', color: '#000000', priority: 0 },
			{ label: 'Al ziena, floor 2', color: '#000000', priority: 1 },
			{ label: 'Delhi , India', color: '#000000', priority: 2 },
			{ label: '110065', color: '#000000', priority: 3 },
		],
	},
];

export const addressFieldsData = [
	{
		key: 'mn',
		name: 'Address Name',
		type: 'text',
		dType: 'text',
		field: 'Address Name',
		label: 'Address Name',
		value: '',
		required: true,
		attribute_id: '1',
	},
	{
		key: 'tpe',
		name: 'Type',
		type: 'select',
		dType: 'type',
		field: 'type',
		label: 'type',
		value: '',
		options: [
			{ label: 'Ten', value: 10 },
			{ label: 'Twenty', value: 20 },
			{ label: 'Thirty', value: 30 },
		],
		required: true,
		attribute_id: '2',
	},
	{
		key: 'al1',
		name: 'Address line 1',
		type: 'text',
		dType: 'address_line_1',
		field: 'address_line_1',
		label: 'address_line_1',
		value: '',
		required: true,
		attribute_id: '3',
	},
	{
		key: 'al2',
		name: 'Address line 2',
		type: 'text',
		dType: 'address_line_2',
		field: 'address_line_2',
		label: 'address_line_2',
		value: '',
		required: true,
		attribute_id: '4',
	},
	{
		key: 'cty',
		name: 'City',
		type: 'select',
		dType: 'city',
		field: 'city',
		label: 'city',
		value: '',
		options: [
			{ label: 'Ten', value: 10 },
			{ label: 'Twenty', value: 20 },
			{ label: 'Thirty', value: 30 },
		],
		required: true,
		attribute_id: '5',
	},
	{
		key: 'strg',
		name: 'State / Region',
		type: 'select',
		dType: 'state_region',
		field: 'state_region',
		label: 'state_region',
		value: '',
		options: [
			{ label: 'Ten', value: 10 },
			{ label: 'Twenty', value: 20 },
			{ label: 'Thirty', value: 30 },
		],
		required: true,
		attribute_id: '6',
	},
	{
		key: 'pin',
		name: 'Pincode',
		type: 'number',
		dType: 'pincode',
		field: 'pincode',
		label: 'pincode',
		value: '',
		required: true,
		attribute_id: '7',
	},
	{
		key: 'cntry',
		name: 'Country',
		type: 'select',
		dType: 'country',
		field: 'country',
		label: 'country',
		value: '',
		options: [
			{ label: 'Ten', value: 10 },
			{ label: 'Twenty', value: 20 },
			{ label: 'Thirty', value: 30 },
		],
		required: true,
		attribute_id: '8',
	},
	{
		key: 'defShipAddr',
		name: 'Default Shipping address',
		type: 'checkbox',
		dType: 'default_shipping_address',
		field: 'default_shipping_address',
		label: 'default_shipping_address',
		value: '',
		required: true,
		attribute_id: '9',
	},
	{
		key: 'defBillAddr',
		name: 'Default Billing address',
		type: 'checkbox',
		dType: 'default_billing_address',
		field: 'default_billing_address',
		label: 'default_billing_address',
		value: '',
		required: true,
		attribute_id: '10',
	},
];
