import {
	Button,
	Grid,
	Icon,
	Input,
	Modal,
	SingleSelect,
	Typography,
} from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	changeDiscount,
	changeDiscountType,
	showHideDiscountModal,
	updateDiscount,
} from '../../../store/actions/cart.actions';
import classes from './AddDiscount.module.css';
export default function CustomModal(props) {
	const discount = useSelector((state) => state?.cart?.discount);
	const cart = useSelector((state) => state?.cart);
	const dispatch = useDispatch();
	const closeDiscount = () => {
		dispatch(showHideDiscountModal({ show: false }));
	};
	const discountChange = (name, value) => {
		const obj = {};
		obj[name] = value;
		dispatch(changeDiscount(obj));
	};
	const removeDiscount = () => {
		dispatch(changeDiscount({ text: '', value: '' }));
		dispatch(updateDiscount([]));
		closeDiscount();
	};

	const selectChange = (event) => {
		dispatch(changeDiscountType({ value: event.value }));
	};
	const currency = cart?.data?.currency;
	const grandTotal = cart?.data?.cart?.total_amount;
	const discountValue = (grandTotal * (discount.text / 100)).toFixed(2);
	const subTotal = (grandTotal - discountValue).toFixed(2);
	const setDiscount = () => {
		dispatch(
			updateDiscount(
				[
					{
						type: discount.selectType.value,
						value: discount.text,
					},
				],
				cart?.data?.id
			)
		);
		closeDiscount();
	};
	return (
		<Modal
			className={classes.modal}
			open={discount.show}
			onClose={props.handleClose}>
			<Grid className={classes.grid}>
				<Grid
					container
					display="flex"
					justifyContent="space-between"
					mb={'2rem'}
					alignItems="center">
					<Typography variant="h6">Add discount</Typography>

					<Icon
						iconName="IconX"
						onClick={closeDiscount}
						sx={{ cursor: 'pointer' }}
					/>
				</Grid>
				<Grid display="flex" justifyContent="space-between" alignItems="center">
					<Grid item className={classes.inputField1} xs={6}>
						<Input
							sx={{ width: '100%' }}
							variant="outlined"
							type="number"
							value={discount.text}
							onChange={(event) => discountChange('text', event.target.value)}
							placeholder="Enter value"
						/>
					</Grid>
					<Grid item className={classes.inputField2} xs={6}>
						<SingleSelect
							label={'Discount type'}
							handleChange={(event) => selectChange(event)}
							options={discount.selectType.options}
							value={
								discount.selectType.value ||
								discount.selectType.options[0].value
							}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						xs={12}
						className={classes.displayFlex}
						sx={{ marginTop: '1.5rem' }}>
						<Typography variant="body2">Cart Total </Typography>
						<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
							{currency} {grandTotal}
						</Typography>
					</Grid>
					<Grid
						xs={12}
						className={classes.displayFlex + ' ' + classes.bordered}>
						<Typography variant="body2">Discounted amount</Typography>
						<Typography
							sx={{ fontWeight: 'bold' }}
							variant="subtitle1"
							color="#F14545">
							{currency} {discountValue}
						</Typography>
					</Grid>
					<Grid
						xs={12}
						className={classes.displayFlex + ' ' + classes.bordered}>
						<Typography variant="body2">Sub Total </Typography>
						<Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
							{currency} {subTotal}
						</Typography>
					</Grid>
					{discount.type !== 'update' && (
						<Grid xs={12} className={classes.displayFlex}>
							<Button onClick={setDiscount} fullWidth>
								Apply
							</Button>
						</Grid>
					)}
					{discount.type === 'update' && (
						<Grid xs={12} className={classes.displayFlex}>
							<Button
								width="150px"
								sx={{ padding: '0.5rem' }}
								variant="outlined">
								<Typography
									onClick={removeDiscount}
									variant="body2"
									color="inherit">
									Remove discount
								</Typography>
							</Button>
							<Button width="150px" sx={{ padding: '0.5rem' }}>
								<Typography
									onClick={setDiscount}
									variant="body2"
									color="inherit">
									Update
								</Typography>
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Modal>
	);
}
