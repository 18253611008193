const editAccounts = {
	id: 'e4eaaaf2-1c62-4d23-b3e8-ff2bf5ab9d79',
	name: 'Walmart',
	account_id: '00001',
	tags: [
		{
			name: 'Walmart',
			backgroundColor: '#000000',
			textColor: '#ffffff',
		},
	],
	created_at: '2018-01-01T00:00:00.000Z',
	updated_at: '2018-01-01T00:00:00.000Z',
	sections: [
		{
			name: 'Basic Details',
			attributes: [
				{
					attribute_id: '1a',
					value: 'Walmart',
					type: 'text',
					name: 'Company name',
					key: 'cmpny_nm',
					required: true,
				},
				{
					attribute_id: '1b',
					value: 'Walmart',
					type: 'text',
					name: 'Display name',
					key: 'display_nm',
					required: true,
				},
				{
					attribute_id: '2a',
					value: 1234567890,
					type: 'number',
					name: 'Contact number',
					key: 'cntct_nm',
					required: false,
				},
				{
					attribute_id: '3aa',
					value: 'abc@gmail.com',
					type: 'text',
					name: 'Email ID',
					key: 'em_id',
					required: false,
				},
				{
					attribute_id: '4a',
					value: 'Admin',
					type: 'select',
					name: 'Account type',
					key: 'acc_type',
					required: false,
					options: [
						{ value: 'Admin', label: 'Admin' },
						{ value: 'Employee', label: 'Employee' },
						{ value: 'View', label: 'View' },
					],
				},
				{
					attribute_id: '5b',
					value: 20,
					type: 'select',
					name: 'Group',
					key: 'grp_type',
					required: false,
					options: [
						{ value: 10, label: 'Ten' },
						{ value: 20, label: 'Twenty' },
						{ value: 30, label: 'Thirty' },
					],
				},
			],
			contacts: [
				{
					id: 0,
					status: 'published',
					attributes: [
						{
							attribute_id: '1q',
							value: 'John Doe',
							type: 'text',
							field: 'name',
							label: 'name',
							dType: 'name',
							name: 'Name',
							key: 'nm',
							required: false,
						},
						{
							attribute_id: '2w',
							value: 'Purchasing Manager',
							type: 'text',
							field: 'name',
							label: 'Designation',
							dType: 'designation',
							name: 'Designation',
							key: 'dsgn',
							required: false,
						},
						{
							attribute_id: '3e',
							value: 'pm@abc.com',
							type: 'text',
							field: 'name',
							label: 'Email ID',
							dType: 'email',
							name: 'Email ID',
							key: 'emId',
							required: false,
						},
						{
							attribute_id: '4r',
							value: 1234567890,
							type: 'number',
							field: 'name',
							label: 'Phone number',
							dType: 'phone',
							name: 'Phone number',
							key: 'phnm',
							required: false,
						},
					],
				},
			],
		},
		{
			name: 'Addresses',
			addresses: [
				{
					id: 0,
					status: 'published',
					attributes: [
						{
							attribute_id: '1o',
							value: 'Main',
							type: 'text',
							field: 'main',
							label: 'main',
							dType: 'main',
							name: 'Main',
							key: 'mn',
							required: false,
						},
						{
							attribute_id: '2p',
							value: 10,
							type: 'select',
							field: 'type',
							label: 'type',
							dType: 'type',
							name: 'Type',
							key: 'tpe',
							required: false,
							options: [
								{ value: 10, label: 'Ten' },
								{ value: 20, label: 'Twenty' },
								{ value: 30, label: 'Thirty' },
							],
						},
						{
							attribute_id: '3d',
							value: 'Koramangala',
							type: 'text',
							field: 'address_line_1',
							label: 'address_line_1',
							dType: 'address_line_1',
							name: 'Address line 1 *',
							key: 'al1',
							required: false,
						},
						{
							attribute_id: '4f',
							value: 'Bangalore',
							type: 'text',
							field: 'address_line_2',
							label: 'address_line_2',
							dType: 'address_line_2',
							name: 'Address line 2',
							key: 'al2',
							required: false,
						},
						{
							attribute_id: '5g',
							value: 20,
							type: 'select',
							field: 'city',
							label: 'city',
							dType: 'city',
							name: 'City',
							key: 'cty',
							required: false,
							options: [
								{ value: 10, label: 'Ten' },
								{ value: 20, label: 'Twenty' },
								{ value: 30, label: 'Thirty' },
							],
						},
						{
							attribute_id: '6h',
							value: 30,
							type: 'select',
							field: 'state_region',
							label: 'state_region',
							dType: 'state_region',
							name: 'State / Region',
							key: 'strg',
							required: false,
							options: [
								{ value: 10, label: 'Ten' },
								{ value: 20, label: 'Twenty' },
								{ value: 30, label: 'Thirty' },
							],
						},
						{
							attribute_id: '7j',
							value: 560095,
							type: 'number',
							field: 'pincode',
							label: 'pincode',
							dType: 'pincode',
							name: 'Pincode ',
							key: 'pin',
							required: false,
						},
						{
							attribute_id: '8k',
							value: 30,
							type: 'select',
							field: 'country',
							label: 'country',
							dType: 'country',
							name: 'Country ',
							key: 'cntry',
							required: false,
							options: [
								{ value: 10, label: 'Canada' },
								{ value: 20, label: 'US' },
								{ value: 30, label: 'India' },
							],
						},
						{
							attribute_id: '9l',
							value: true,
							type: 'checkbox',
							field: 'default_shipping_address',
							label: 'default_shipping_address',
							dType: 'default_shipping_address',
							name: 'Default Shipping address',
							key: 'defShipAddr',
							required: false,
						},
						{
							attribute_id: '10z',
							value: false,
							type: 'checkbox',
							field: 'default_billing_address',
							label: 'default_billing_address',
							dType: 'default_billing_address',
							name: 'Default Billing address',
							key: 'defBillAddr',
							required: false,
						},
					],
				},
			],
		},
		{
			name: 'Preferences',
			payments: [
				{
					id: 0,
					name: 'Payments',
					status: 'published',
					attributes: [
						{
							attribute_id: '1x',
							value: 'Ten,Twenty',
							type: 'multi_select',
							field: 'MOP',
							label: 'MOP',
							dType: 'MOP',
							name: 'Mode of Payment',
							key: 'mop',
							required: false,
							options: [
								{ value: 'Ten', label: 'Ten' },
								{ value: 'Twenty', label: 'Twenty' },
								{ value: 'Thirty', label: 'Thirty' },
								{ value: 'Thirtysad', label: 'Thirtysad' },
								{ value: 'Thirdstysad', label: 'Thirdstysad' },
							],
						},
						{
							attribute_id: '2c',
							value: 10,
							type: 'select',
							field: 'payment_terms',
							label: 'payment_terms',
							dType: 'payment_terms',
							name: 'Payment terms *',
							key: 'paytrms',
							required: false,
							options: [
								{ value: 10, label: 'Ten' },
								{ value: 20, label: 'Twenty' },
								{ value: 30, label: 'Thirty' },
							],
						},
					],
				},
			],
			banking: [
				{
					id: 0,
					name: 'Banking',
					status: 'published',
					attributes: [
						{
							attribute_id: '1v',
							value: 'Testing',
							type: 'textarea',
							field: 'add_info',
							label: 'add_info',
							dType: 'add_info',
							name: 'Add information',
							key: 'add_info',
							required: false,
						},
					],
				},
			],
			orderSettings: {
				id: 0,
				name: 'Order Settings',
				status: 'published',
				attributes: [
					{
						attribute_id: '1b',
						value: 20,
						type: 'select',
						field: 'pricing_group',
						label: 'pricing_group',
						dType: 'pricing_group',
						name: 'Pricing group',
						key: 'prcnggrp',
						required: false,
						options: [
							{ value: 10, label: 'Ten' },
							{ value: 20, label: 'Twenty' },
							{ value: 30, label: 'Thirty' },
						],
					},
					{
						attribute_id: '2n',
						value: 'USD',
						type: 'select',
						field: 'currency',
						label: 'currency',
						dType: 'currency',
						name: 'Currency',
						key: 'crncy',
						required: false,
						options: [
							{ value: 'USD', label: 'USD' },
							{ value: 'INR', label: 'INR' },
							{ value: 'EURO', label: 'EURO' },
						],
					},
					{
						attribute_id: '3m',
						value: 'Color',
						type: 'select',
						field: 'metrics',
						label: 'metrics',
						dType: 'metrics',
						name: 'Metrics',
						key: 'mtrcs',
						options: [
							{ value: 'Size', label: 'Size' },
							{ value: 'Color', label: 'Color' },
						],
					},
				],
			},
			shipment_methods: {
				id: 0,
				name: 'Shipment methods',
				status: 'published',
				attributes: [
					{
						attribute_id: '1qwer',
						value: 'Ten',
						type: 'select',
						field: 'shp_mthd',
						label: 'shp_mthd',
						dType: 'shp_mthd',
						name: 'Select  *',
						key: 'shp_mthd',
						required: false,
						options: [
							{ value: 'Ten', label: 'Ten' },
							{ value: 'Twenty', label: 'Twenty' },
							{ value: 'Thirty', label: 'Thirty' },
						],
					},
				],
			},
			terms_conditions: [
				{
					id: 0,
					name: 'Terms & Conditions',
					status: 'published',
					attributes: [
						{
							attribute_id: '1re32',
							value: 'ABCDEF',
							type: 'textarea',
							field: 'add_info',
							label: 'add_info',
							dType: 'add_info',
							name: 'Add information',
							key: 'add_info',
							required: false,
						},
					],
				},
			],
		},
		{
			id: 0,
			status: 'published',
			name: 'Other Details',
			remarks: [
				{
					id: 0,
					name: 'Banking',
					status: 'published',
					attributes: [
						{
							attribute_id: '1bfdf',
							value: 'sadfsfbd',
							type: 'textarea',
							field: 'remarks',
							label: 'remarks',
							dType: 'remarks',
							name: 'add information',
							key: 'rmrks',
							required: false,
						},
					],
				},
			],
		},
		// {
		// 	name: 'Other details',
		// 	attributes: [
		// 		{
		// 			attribute_id: '1',
		// 			key: 'Address',
		// 			value: '',
		// 			composite: [
		// 				{
		// 					'Address Line': '702 SW 8th St',
		// 					City: 'Bentonville',
		// 					State: 'AR',
		// 					Zip: '72716',
		// 					label: 'Default Shipping',
		// 				},
		// 				{
		// 					'Address Line': '702 SW 8th St',
		// 					City: 'Bentonville',
		// 					State: 'AR',
		// 					Zip: '72716',
		// 					label: 'Default Billing',
		// 				},
		// 				{
		// 					'Address Line': '702 SW 8th St',
		// 					City: 'Bentonville',
		// 					State: 'AR',
		// 					Zip: '72716',
		// 					label: 'Others',
		// 				},
		// 				{
		// 					'Address Line': '702 SW 8th St',
		// 					City: 'Bentonville',
		// 					State: 'AR',
		// 					Zip: '72716',
		// 					label: 'Others',
		// 				},
		// 			],
		// 			type: 'address',
		// 		},
		// 	],
		// },
	],
};

export default editAccounts;
