import { Login } from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
	changeEmail,
	changePassword,
	loginAction,
} from '../../../store/actions/login.actions';
import { closeToast, showToast } from '../../../store/actions/message.actions';
import { validateEmail } from '../../../util/common';

export default function LoginForm() {
	const login = useSelector((state) => state.login);
	const [emailValidation, setValidation] = useState(false);
	const [passowrdValidation, setPwdValidation] = useState(false);
	const [redirect, setRedirect] = useState(false);
	useEffect(() => {
		if (login.email.length === 0) {
			setValidation(false);
		} else {
			setValidation(!validateEmail(login.email));
		}
	}, [login.email]);
	useEffect(() => {
		setPwdValidation(false);
	}, [login.password]);

	const dispatch = useDispatch();

	const onEmailChange = (event) => {
		dispatch(changeEmail(event.target.value));
	};
	const onPasswordChange = (event) => {
		dispatch(changePassword(event.target.value));
	};

	const onSubmit = () => {
		login.email.length === 0 && setValidation(true);
		login.password.length === 0 && setPwdValidation(true);
		if (emailValidation || passowrdValidation) {
			return dispatch(
				showToast({
					open: true,
					showCross: false,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					autoHideDuration: 5000,
					onClose: (event, reason) => {
						if (reason === 'clickaway') {
							return;
						}
						dispatch(closeToast());
					},
					state: 'error',
					title: 'Error',
					subtitle: emailValidation
						? 'Please Verify Email Id Field'
						: 'Please Verify Password Field',
					showActions: false,
				})
			);
		}
		dispatch(
			loginAction({
				username: login.email,
				password: login.password,
			})
		);
	};
	const bookDemoClick = () => {
		window.open('https://cal.com/team/sourcewiz/product-demo?duration=30');
	};

	const forgotPasswordClick = () => {
		setRedirect(true);
	};
	if (redirect) {
		return <Navigate to="/forgot-password" />;
	}
	return (
		<Login
			email={login.email}
			onEmailChange={onEmailChange}
			emailError={emailValidation}
			passwordError={passowrdValidation}
			companyName="Sourcewiz"
			companyDesc="Crafting digital experiences for B2B sales & sourcing"
			onPasswordChange={onPasswordChange}
			forgotPasswordClick={forgotPasswordClick}
			onSubmit={onSubmit}
			password={login.password}
			bookDemoClick={bookDemoClick}
			logoUrl="https://uploads-ssl.webflow.com/63e4eec68738ce8318cbab16/63fcd32306971477fb638c84_New%20logo-%20Satoshi%20font.svg"
		/>
	);
}
