import { validateEmail } from './common';

export function validateContact(contact, values, index) {
	const errors = {};
	contact.attributes.forEach((attribute) => {
		const fieldName = attribute.attribute_id;
		const fieldValue = values?.contacts?.[index]?.[fieldName];
		if (attribute.required && !fieldValue) {
			errors[fieldName] = `${attribute.name} is required`;
		} else if (
			attribute.type === 'email' &&
			fieldValue &&
			!validateEmail(fieldValue)
		) {
			errors[fieldName] = `${attribute.name} is invalid`;
		}
		// Add additional validation checks for other field types here...
	});
	return Object.keys(errors).length === 0 ? null : errors;
}

export const validateAddress = (address, values, index) => {
	const errors = {};
	address.attributes.forEach((attribute) => {
		const fieldName = attribute.attribute_id;
		const fieldValue = values?.address?.[index]?.[fieldName];
		if (attribute.required && !fieldValue) {
			errors[fieldName] = `${attribute.name} is required`;
		}
		// Add additional validation checks for other field types here...
	});
	return Object.keys(errors).length === 0 ? null : errors;
};
