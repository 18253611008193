import { Skeleton } from '@sourcewiz/the-source';

export default function ResultTextSkeleton() {
	return (
		<Skeleton
			animation="wave"
			sx={{ background: '#EEF1F7' }}
			variant="rectangular"
			width={300}
		/>
	);
}
