import PropTypes from 'prop-types';
import {
	Box,
	Drawer,
	Grid,
	Icon,
	ProductCard,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';

import { getProductVariants } from '../../../api/handlers/products';
import useWindowSize from '../../../hooks/useWindowSize';

const IMAGE_ENV = process.env.REACT_APP_CLOUDINARY;
const IMAGE_FALLBACK_ENV = process.env.REACT_APP_DIRECTUS;

const VariantSelectionDrawer = ({ open, onClose, id }) => {
	const [variantsData, setVariantsData] = useState([]);
	const { width } = useWindowSize();

	useEffect(() => {
		getProductVariants(id).then((res) => {
			const { data } = res.data;
			const variants =
				data?.find((item) => item.section === 'variants_modal')?.hits || [];
			setVariantsData(variants);
		});
	}, [id]);

	const drawerContent = (
		<Box sx={{ background: '#FFFFFF' }}>
			<Grid container justifyContent="space-between" p={2} pb={0}>
				<Grid item>
					<Typography variant="h5">Select options</Typography>
					<Typography variant="subtitle1" color="#00000099">
						{variantsData?.length} options available
					</Typography>
				</Grid>
				<Grid item sx={{ cursor: 'pointer' }} alignItems="center">
					<Icon color="#4F555E" onClick={onClose} iconName="IconX" />
				</Grid>
			</Grid>
			<Grid p={2} pb={0}></Grid>
			<Grid container spacing={2} columnSpacing={2} p={2}>
				{variantsData?.map((variant) => (
					<Grid item xs={6} p={1} key={variant.id}>
						<ProductCard
							noBoxShadow
							onProductCardClick={() => {}}
							handleVariant={() => {}}
							imageEnv={IMAGE_ENV}
							fallbackEnv={IMAGE_FALLBACK_ENV}
							data={variant}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
	return (
		<Drawer
			open={open}
			width={width <= 1200 ? '50vw' : '45vw'}
			onClose={onClose}
			content={drawerContent}></Drawer>
	);
};

VariantSelectionDrawer.propTypes = {
	id: PropTypes.any.isRequired,
	onClose: PropTypes.any.isRequired,
	open: PropTypes.any.isRequired,
};

export default VariantSelectionDrawer;
