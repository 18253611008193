import { QUICK_SETTINGS } from '../../constants/apiEndpoints';
import axios from '../../util/axios';

export const updateQuickSettings = (data) => {
	return axios.post(QUICK_SETTINGS.UPDATE_QUICK_SETTINGS, data);
};

export const getQuickSettingsApi = () => {
	return axios.get(QUICK_SETTINGS.GET_QUICK_SETTINGS);
};
