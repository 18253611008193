import cartActionTypes from '../types/cart.types';
const INITIAL_STATE = {
	loading: true,
	notes: {
		show: false,
		value: '',
		title: '',
	},
	billingAddress: {
		show: false,
	},
	shippingAddress: {
		show: false,
	},
	addAddress: {
		show: false,
	},
	addresses: {
		show: false,
	},
	users: {
		show: false,
	},
	createAccount: {
		show: false,
	},
	data: {},
	viewCartSummary: false,
	taxes: {
		show: false,
		taxes: [
			{
				name: '',
				value: '',
			},
		],
	},
	discount: {
		show: false,
		text: '',
		value: '',
		selectType: {
			value: 'percent',
			label: 'Percent (%)',
			options: [
				{
					value: 'precent',
					label: 'Percent (%)',
				},
			],
		},
	},
};

// eslint-disable-next-line complexity
const cartReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case cartActionTypes.OPEN_NOTES:
			return {
				...state,
				notes: { ...state.notes, ...action.data },
			};
		case cartActionTypes.OPEN_BILLING_ADDRESS:
			return {
				...state,
				billingAddress: { ...state.billingAddress, show: action.show },
			};
		case cartActionTypes.OPEN_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: { ...state.shippingAddress, show: action.show },
			};
		case cartActionTypes.ADD_ADDRESS:
			return {
				...state,
				addAddress: { ...state.addAddress, ...action.data },
			};
		case cartActionTypes.SHOW_ADDRESSES:
			return {
				...state,
				addresses: { ...state.addresses, ...action.data },
			};
		case cartActionTypes.SELECT_USER:
			return {
				...state,
				users: { ...state.users, ...action.data },
			};
		case cartActionTypes.CREATE_ACCOUNT_DRAWER:
			return {
				...state,
				createAccount: { ...state.createAccount, ...action.data },
			};
		case cartActionTypes.SET_CART_DATA:
			const newState = { ...state };
			newState.loading = false;
			delete newState.data;
			newState.data = action.data;
			return newState;
		case cartActionTypes.VIEW_CART_SUMMARY:
			return {
				...state,
				viewCartSummary: action.show,
			};
		case cartActionTypes.OPEN_TAXES_MODAL:
			return {
				...state,
				taxes: {
					...state.taxes,
					...action.data,
					taxes: [
						{
							name: '',
							value: '',
						},
					],
				},
			};
		case cartActionTypes.ADD_TAXES: {
			return {
				...state,
				taxes: {
					...state.taxes,
					taxes: [
						...state.taxes.taxes,
						{
							name: '',
							value: '',
						},
					],
				},
			};
		}
		case cartActionTypes.TAXES_CHANGE: {
			const newState = { ...state, taxes: { ...state.taxes } };
			let taxes = [...newState.taxes.taxes];
			taxes = [...taxes];
			const { index, name, value } = action.data;
			if (name === 'value') {
				if (value < 101) {
					taxes[index][name] = value;
				}
			} else {
				taxes[index][name] = value;
			}
			return newState;
		}
		case cartActionTypes.SET_LOADING: {
			return { ...state, loading: action.loading };
		}
		case cartActionTypes.SET_TAXES_TO_CART: {
			const newState = {
				...state,
				taxes: { ...state.taxes },
				data: { ...state.data, cart: { ...state.data.cart } },
			};
			newState.taxes = {
				show: false,
				taxes: [
					{
						name: '',
						value: '',
					},
				],
			};
			if (newState?.data?.cart?.taxes?.data) {
				newState.data.cart.taxes.data = [
					...(newState?.data?.cart?.taxes?.data || {}),
					...action.data,
				];
			}
			return newState;
		}
		case cartActionTypes.REMOVE_ALL_TAXES: {
			const newState = {
				...state,
				taxes: {
					...state.taxes,
					taxes: [
						{
							name: '',
							value: '',
						},
					],
				},
				data: {
					...state.data,
					cart: {
						...state.data.cart,
						taxes: { ...state.data.cart.taxes, data: [] },
					},
				},
			};
			return newState;
		}
		case cartActionTypes.REMOVE_A_TAX: {
			const newState = {
				...state,
				data: {
					...state.data,
					cart: {
						...state.data.cart,
						taxes: { ...state.data.cart.taxes },
					},
				},
			};
			newState?.data?.cart?.taxes?.data?.splice(action.index, 1);
			return newState;
		}
		case cartActionTypes.REMOVE_A_DISCOUNT: {
			const newState = {
				...state,
				data: {
					...state.data,
					cart: {
						...state.data.cart,
						discount: { ...state.data.cart.discount },
					},
				},
			};
			newState?.data?.cart?.discount?.data?.splice(action.index, 1);
			return newState;
		}
		case cartActionTypes.SHOW_DISCOUNT_MODAL:
			return {
				...state,
				discount: { ...state.discount, ...action.data },
			};
		case cartActionTypes.DISCOUNT_CHANGE:
			return {
				...state,
				discount: { ...state.discount, ...action.data },
			};
		case cartActionTypes.CHANGE_DISCOUNT_TYPE:
			return {
				...state,
			};
		case cartActionTypes.UPDATE_DISCOUNT: {
			const newState = {
				...state,
				taxes: {
					...state.taxes,
					taxes: [
						{
							name: '',
							value: '',
						},
					],
				},
				data: {
					...state.data,
					cart: {
						...state.data.cart,
						discount: { ...state.data.cart.discount, data: action.data },
					},
				},
			};
			return newState;
		}
		case cartActionTypes.DISCOUNT_TYPE_CHANGE: {
			const newState = { ...state };
			newState.discount = { ...state.discount, ...action.data };
			return newState;
		}
		case cartActionTypes.PRODUCT_CHANGE: {
			const newState = {
				...state,
				data: { ...state.data, entities: [...state.data.entities] },
			};
			const index = newState.data.entities.findIndex(
				(_entity) => _entity.entity_id === action.data.entities[0].entity_id
			);
			if (index > -1) {
				newState.data.entities[index].price.price =
					action.data.entities[0].price;
				newState.data.entities[index].quantity.value =
					action.data.entities[0].quantity;
			}
			return newState;
		}
		case cartActionTypes.PRODUCT_TO_CART_ADD_ACTION: {
			const newState = {
				...state,
				data: { ...state.data, entities: [...state.data.entities] },
			};
			const index = newState.data.entities.findIndex(
				(_entity) => action.id === _entity.id
			);
			if (index > -1) {
				newState.data.entities[index].product_action.value = action.value;
			}
			return newState;
		}
		case cartActionTypes.REMOVE_ALL_PRODUCTS: {
			const newState = { ...state, data: { ...state.data, entities: null } };
			return newState;
		}

		case cartActionTypes.REMOVE_A_PRODUCT_FROM_CART: {
			const newState = {
				...state,
				data: { ...state.data, entities: [...state.data.entities] },
			};
			newState.data.entities.splice(action.index, 1);
			return newState;
		}
		case cartActionTypes.UPDATE_NOTE: {
			const newState = {
				...state,
				data: { ...state.data, entities: [...state.data.entities] },
			};
			newState.data.entities[action.index].meta_data.message = action.value;
			return newState;
		}
		case cartActionTypes.REMOVE_A_TAX_FROM_DRAWER: {
			const newState = {
				...state,
				taxes: { ...state.taxes, taxes: [...state.taxes.taxes] },
			};
			const newTaxes = newState.taxes.taxes;
			newTaxes.splice(action.index, 1);
			newState.taxes.taxes = newTaxes;
			return newState;
		}
		default:
			return state;
	}
};
export default cartReducer;
