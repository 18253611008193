import { Sidebar as SWSidebar } from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants';
import { logout_click } from '../../../store/actions/login.actions';
import Logo from './Logo.svg';

export default function Sidebar() {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const sidebarItems = [
		{
			id: 2,
			icon: 'IconListDetails',
			title: 'Products',
			link: ROUTES.PRODUCTS.ALL_PRODUCTS,
			onIconClick: () => navigate(ROUTES.PRODUCTS.ALL_PRODUCTS),
		},
		{
			id: 3,
			icon: 'IconBarcode',
			title: 'PDP',
			link: ROUTES.PRODUCTS.PRODUCT_DETAILS,
			onIconClick: () =>
				navigate('/product-details/61440dc2-586e-44dc-b199-59d0662fbff7'),
		},
		{
			id: 4,
			icon: 'IconUsers',
			title: 'Customers',
			link: ROUTES.ACCOUNT.CREATE_ACCOUNT,
			onIconClick: () => navigate(ROUTES.ACCOUNT.ACCOUNT_DETAILS),
		},
	];

	const BOTTOM_ACTION = [
		{
			id: 8,
			icon: 'IconSettings',
			title: 'Settings',
			link: '#',
			onIconClick: () => alert('Settings clicked'),
		},
		{
			id: 9,
			icon: 'IconLogout',
			title: 'Logout',
			link: '#',
			onIconClick: () => dispatch(logout_click()),
		},
	];

	const active =
		sidebarItems.find((item) => item.link === location.pathname)?.id ?? 2;

	return (
		<SWSidebar
			active={active}
			logo={Logo}
			sidebarItems={sidebarItems}
			bottomActions={BOTTOM_ACTION}
		/>
	);
}
