import * as DOMPurify from 'dompurify';

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export const sortByPriority = (attributes) => {
	// eslint-disable-next-line id-length
	return attributes?.sort((a, b) => a?.priority - b?.priority);
};

export const setAuthCookies = (access_token, refresh_token) => {
	document.cookie = `access_token=${access_token}; path=/; max-age=${
		60 * 60 * 24 * 7
	};`;
	document.cookie = `refresh_token=${refresh_token}; path=/; max-age=${
		60 * 60 * 24 * 30
	}`;
};

export const cookies = document.cookie.split('; ');

export const access_token = () => {
	const token = cookies.find((cookie) => cookie.startsWith('access_token='));
	return token ? token.split('=')[1] : '';
};
export const refresh_token = () => {
	const token = cookies.find((cookie) => cookie.startsWith('refresh_token='));
	return token ? token.split('=')[1] : '';
};

export const remove_tokens = () => {
	document.cookie = 'access_token=; max-age=0; path=/;';
	document.cookie = 'refresh_token=; max-age=0; path=/;';
};

export const deleteAllCookies = () => {
	const cookies = document.cookie.split(';');

	// eslint-disable-next-line id-length
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

export const updateObjectById = (obj, id, updateFn) => {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}
	const newObj = {};
	for (const key in obj) {
		if (Object.hasOwn(obj, key)) {
			const value = obj[key];
			if (Array.isArray(value)) {
				newObj[key] = value.map((item) => updateObjectById(item, id, updateFn));
			} else if (value && typeof value === 'object') {
				newObj[key] = updateObjectById(value, id, updateFn);
			} else if (key === 'key' && value === id) {
				return updateFn(obj);
			} else {
				newObj[key] = value;
			}
		}
	}
	return newObj;
};

export const renderFieldsByDType = (dtype, value) => {
	switch (dtype) {
		case 'html':
			return DOMPurify.sanitize(value);
		default:
			return value;
	}
};

export const getAttributes = (item) => {
	const values = Object.entries(item)?.map(([attribute_id, value]) => ({
		attribute_id,
		value,
	}));
	return values;
};

export const getAccountsPayload = (values) => {
	const tabs = Object.keys(values);
	const payload = {};

	if (tabs.includes('basic_details')) {
		const attributesValues = getAttributes(values?.basic_details?.[0]);
		payload.basic_details = {
			attributes: attributesValues?.map((attr) => ({
				attribute_id: attr?.attribute_id,
				value: attr?.value,
			})),
		};
	}

	if (tabs.includes('contacts')) {
		payload.basic_details = {
			...payload.basic_details,
			contact: values?.contacts?.map((item) => {
				const attributesValues = getAttributes(item);
				return {
					attributes: attributesValues?.map((attr) => ({
						attribute_id: attr?.attribute_id,
						value: attr?.value,
					})),
				};
			}),
		};
	}

	if (tabs.includes('address')) {
		payload.address = values?.address?.map((item) => {
			const attributesValues = getAttributes(item);
			return {
				attributes: attributesValues?.map((attr) => ({
					attribute_id: attr?.attribute_id,
					value: attr?.value?.toString(),
				})),
			};
		});
	}

	if (tabs.includes('banking')) {
		payload.preferences = {
			...payload.preferences,
			banking: {
				attributes: values?.banking?.map((item) => {
					return {
						attribute_id: Object.keys(item).toString(),
						value: Object.values(item).toString(),
					};
				}),
			},
		};
	}

	if (tabs.includes('payments')) {
		const attributesValues = getAttributes(values?.payments?.[0]);
		payload.preferences = {
			...payload.preferences,
			payments: {
				attributes: attributesValues?.map((attr) => ({
					attribute_id: attr?.attribute_id,
					value: attr?.value,
				})),
			},
		};
	}

	if (tabs.includes('order_settings')) {
		const attributesValues = getAttributes(values?.order_settings?.[0]);
		payload.preferences = {
			...payload.preferences,
			order_setting: {
				attributes: attributesValues?.map((attr) => ({
					attribute_id: attr?.attribute_id,
					value: attr?.value,
				})),
			},
		};
	}

	if (tabs.includes('shipment_method')) {
		const attributesValues = getAttributes(values?.shipment_method?.[0]);
		payload.preferences = {
			...payload.preferences,
			shipment_method: {
				attributes: attributesValues?.map((attr) => ({
					attribute_id: attr?.attribute_id,
					value: attr?.value,
				})),
			},
		};
	}

	if (tabs.includes('terms')) {
		payload.preferences = {
			...payload.preferences,
			terms_conditions: {
				attributes: values?.terms?.map((item) => {
					return {
						attribute_id: Object.keys(item).toString(),
						value: Object.values(item).toString(),
					};
				}),
			},
		};
	}

	if (tabs.includes('otherDetails')) {
		const attributesValues = getAttributes(values?.otherDetails?.[0]);
		payload.other_details = {
			...payload.other_details,
			remarks: {
				attributes: attributesValues?.map((attr) => ({
					attribute_id: attr?.attribute_id,
					value: attr?.value,
				})),
			},
		};
	}

	return payload;
};
export const getImageSrc = (id) => {
	return `${process.env.REACT_APP_CLOUDINARY}/${id}`;
};
export const getFallbackSrc = (id) => {
	return `${process.env.REACT_APP_SOURCERER_BASEURL}/${id}`;
};

export const getParam = (key) => {
	const urlParams = window.location.search;
	const params = new URLSearchParams(urlParams);
	return params.get(key);
};

export const getInitials = (name) => {
	const names = name.split(' ');
	const initials = names.map((name) => name.charAt(0).toUpperCase());
	return initials.join('');
};
