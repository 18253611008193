const formatUrlParams = (filters, otherParams) => {
	const params = [];
	filters.forEach((filter) => {
		const { key, type, value } = filter;
		if (type === 'multi-select') {
			params.push(`f[${key}]=${JSON.stringify(value)}`);
		} else if (type === 'select') {
			params.push(`f[${key}]=${JSON.stringify(value)}`);
		} else if (type === 'range') {
			const [min, max, unit] = value;
			params.push(
				`f[${key}][min]=${min}`,
				`f[${key}][max]=${max}`,
				`f[${key}][unit]=${unit}`
			);
		} else {
			params.push(`f[${key}]=${JSON.stringify(value)}`);
		}
	});

	Object.keys(otherParams).forEach((key) => {
		if (
			key === 'query' ||
			key === 'page' ||
			key === 'sort' ||
			key === 'limit'
		) {
			params.push(`${key}=${otherParams[key]}`);
		}
	});
	const encodedURI = params?.map((item) => encodeURIComponent(item));
	const encodedWithEqual = encodedURI?.map((el) => el.replace('%3D', '='));
	const encodedURIParams = `${encodedWithEqual.join('&')}`;
	return encodedURIParams;
};

const getFilterType = (filtersData, filterKey) => {
	const filter = filtersData.find((item) => item?.meta?.key === filterKey);
	return filter?.meta?.type;
};

const getFilterName = (filtersData, filterKey) => {
	const filter = filtersData.find((item) => item?.meta?.key === filterKey);
	return filter?.name;
};

export { getFilterType, getFilterName, formatUrlParams };
