import PropTypes from 'prop-types';
import {
	Button,
	Checkbox,
	Drawer,
	Grid,
	Icon,
	TextArea,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';

import styles from './TextEdit.module.css';
export default function TextEdit({
	text = '',
	saveText = () => {},

	title = 'Edit Text',
	onClose = () => {},
	drawerOnly = false,
	checkboxValue = true,
	show = false,
	placeholder = 'Enter text here',
	showCheckBox = false,
}) {
	const [showDrawer, setShowDrawer] = useState(show);
	const [checkBox, setCheckbox] = useState(checkboxValue);
	const [textVal, setTextVal] = useState(text);
	const onCheckboxChange = (event) => {
		setCheckbox(event.target.checked);
	};
	const onChange = (event) => {
		setTextVal(event.target.value);
	};
	const saveClick = () => {
		const data = {
			checkBox,
			textVal,
		};
		saveText(data);
		setShowDrawer(false);
		onClose();
	};
	useEffect(() => {
		setShowDrawer(show);
	}, [show]);

	const closeIconClick = () => {
		setShowDrawer(false);
		onClose();
	};

	const renderDrawerData = () => {
		return (
			<Grid container className={styles.container}>
				<Grid item className={styles.body} xs={12}>
					<Grid xs={12} className={styles.header}>
						<Grid
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Typography variant="h5">{title}</Typography>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={closeIconClick}
							/>
						</Grid>
					</Grid>
					<Grid className={styles.content}>
						<TextArea
							className={styles}
							sx={{ width: '100%' }}
							value={textVal}
							placeholder={placeholder}
							handleChange={onChange}
						/>
						{showCheckBox && (
							<Grid item xs={12} sx={{ marginTop: '1rem' }}>
								<Checkbox checked={checkBox} onChange={onCheckboxChange} />
								<Typography variant="subitle">
									Share notes with customer
								</Typography>
							</Grid>
						)}
					</Grid>
					<Grid className={styles.footer} item xs={12}>
						<Button fullWidth onClick={saveClick}>
							Save
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			{!drawerOnly && (
				<Grid className={styles.card}>
					<Grid className={styles.cardTitle}>
						<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
							{title}
						</Typography>
						<Button variant="text" onClick={() => setShowDrawer(true)}>
							Change
						</Button>
					</Grid>
					<Grid>
						<Typography variant="body2">{text}</Typography>
					</Grid>
				</Grid>
			)}
			<Drawer
				anchor="right"
				open={showDrawer}
				content={renderDrawerData()}
				onClose={() => {
					setShowDrawer(false);
					onClose();
				}}
			/>
		</>
	);
}

TextEdit.propTypes = {
	checkboxValue: PropTypes.bool.isRequired,
	drawerOnly: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	saveText: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showCheckBox: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
