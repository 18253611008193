import PropTypes from 'prop-types';
import {
	Button,
	Checkbox,
	Drawer,
	Grid,
	Icon,
	TextArea,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	editNote,
	showHideNotesDrawer,
} from '../../../../store/actions/cart.actions';
import styles from './Drawers.module.css';
export default function AddNotes(props) {
	const cart = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		dispatch(showHideNotesDrawer({ show: false }));
	};
	const { edit, saveAction = () => {} } = props;
	const [text, setText] = useState('');
	useEffect(() => {
		setText(cart?.notes?.value ? cart?.notes?.value : '');
	}, [cart?.notes?.value]);
	const notesChange = (event) => {
		setText(event.target.value);
	};

	const saveClick = () => {
		edit &&
			dispatch(
				editNote(
					cart.notes.index,
					cart.notes.entity_id,
					text,
					cart?.notes?.meta_data
				)
			);
		setText('');
		saveAction();
		closeDrawer();
	};

	const renderDrawerData = () => {
		return (
			<Grid container className={styles.container}>
				<Grid item className={styles.body} xs={12}>
					<Grid xs={12} className={styles.header}>
						<Grid
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Typography variant="h5">
								{edit ? 'Edit' : 'Add'} notes
							</Typography>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={() => closeDrawer()}
							/>
						</Grid>
					</Grid>
					<Grid className={styles.TextArea} sx={{ marginTop: '2rem' }}>
						<TextArea
							placeholder="Notes *"
							sx={{ width: '100%' }}
							value={text}
							handleChange={notesChange}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '1rem' }}>
						<Checkbox checked />
						<Typography variant="subitle">Share notes with customer</Typography>
					</Grid>
				</Grid>
				<Grid className={styles.footer} item xs={12}>
					<Button fullWidth onClick={saveClick}>
						Save
					</Button>
				</Grid>
			</Grid>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={cart?.notes?.show}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
			{...props}
		/>
	);
}

AddNotes.propTypes = {
	edit: PropTypes.func.isRequired,
	saveAction: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};
