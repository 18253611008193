import PropTypes from 'prop-types';
import {
	Avatar,
	Button,
	Grid,
	Icon,
	Menu,
	Typography,
} from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	createQuoteAction,
	removeADiscountAction,
	removeAllTaxes,
	removeATax,
	showSelectUserModal,
	viewCartSummary,
	showHideDiscountModal,
	showHideTaxesModal,
	changeCartStatusAction,
} from '../../../store/actions/cart.actions';
import AddDiscount from '../AddDiscount/AddDiscount';
import SelectUsers from '../SelectUsers/SelectUsers';
import styles from './CartRight.module.css';

export default function CartRight({ fromDrawer = false }) {
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart.data);
	const discount = cart?.cart?.discount;
	const navigate = useNavigate();
	const addTaxesClick = () => {
		dispatch(showHideTaxesModal({ show: true }));
	};
	const removeTaxesClick = () => {
		dispatch(removeAllTaxes(cart?.id));
	};

	const showDiscount = (type) => {
		const discountData =
			discount?.data && discount?.data[0] ? discount?.data[0] : {};
		let actionData = {
			text: '',
			value: '',
			show: true,
			type,
		};
		if (discountData && discountData['value']) {
			actionData = { ...actionData, text: discountData.value };
		}
		dispatch(showHideDiscountModal(actionData));
	};

	const showUserModal = (data) => {
		dispatch(showSelectUserModal(data));
	};
	const closeDrawer = () => {
		dispatch(viewCartSummary(false));
	};

	const removeSingleTax = (index) => {
		dispatch(removeATax(index));
	};

	const removeADiscount = (index) => {
		dispatch(removeADiscountAction(index));
	};

	const customerName = 'John Doe';
	const userLocation = 'Walmart, USA';

	const submitQuote = () => {
		const cb = (id) => {
			navigate('/review-submit?quotation_id=' + id);
		};
		dispatch(createQuoteAction(cb));
	};

	const saveAsDraft = () => {
		const cb = () => {
			navigate('/all-products');
		};
		dispatch(changeCartStatusAction(cb));
	};
	return (
		<>
			<Grid className={fromDrawer ? styles.cartRightVisible : styles.cartRight}>
				<Grid>
					<Grid className={styles.userSection + ' ' + styles.border}>
						{fromDrawer && (
							<Grid className={styles.backBtn}>
								<Avatar
									onClick={closeDrawer}
									size="medium"
									variant="circular"
									isImageAvatar={false}
									backgroundColor="#ede9e9"
									content={<Icon iconName="IconChevronLeft" />}
								/>
							</Grid>
						)}
						<Grid className={styles.nameAndMenu}>
							<Grid className={styles.logoAndUserDesciption}>
								<Grid sx={{ margin: '0.5rem 0.5rem 0 0' }}>
									<Icon
										sx={{ height: '24px', width: '24px' }}
										color="#4F555E"
										iconName="IconUser"
									/>
								</Grid>
								<Grid>
									<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
										{customerName}
									</Typography>
									<Typography
										variant="subtitle2"
										sx={{ color: 'rgba(0, 0, 0, 0.6)', weight: '400px' }}>
										{userLocation}
									</Typography>
								</Grid>
							</Grid>
							<Grid className={styles.menuGrid}>
								<Menu
									anchorOrigin={{
										horizontal: 'left',
										vertical: 'bottom',
									}}
									closeOnItemClick
									menu={[
										{
											label: (
												<Grid
													onClick={() => {
														showUserModal({ show: true });
														document.body.click();
													}}
													className={styles.menuItem}>
													<Icon iconName="IconUser" />
													&nbsp;&nbsp;
													<Typography variant="subtitle">
														Change Customer
													</Typography>
												</Grid>
											),
											value: 'change_account',
										},
										{
											label: (
												<Grid className={styles.menuItem}>
													<Icon iconName="IconTrash" color="#4f555e" />
													&nbsp;&nbsp;
													<Typography variant="subtitle">
														Discard Session
													</Typography>
												</Grid>
											),
											value: 'change_abc',
										},
									]}
									btnStyle={{
										padding: '0px',
										':hover': { background: 'transparent' },
										':active': { background: 'transparent' },
									}}
									LabelComponent={
										<Button className={styles.btnClass} variant="text">
											<Icon iconName="IconDotsVertical" />
										</Button>
									}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid className={styles.discountSection + ' ' + styles.border}>
						{
							<Grid
								className={styles.spaceBtwFlex}
								sx={{ marginBottom: '1rem' }}>
								<Typography variant="subtitle1">Cart Total</Typography>
								<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
									{cart?.currency}{' '}
									{cart?.cart?.total_amount ? cart?.cart?.total_amount : '0'}
								</Typography>
							</Grid>
						}
						{discount?.data?.map((attr, index) => (
							<Grid className={styles.spaceBtwFlex} key={index + 1}>
								<Typography variant="subtitle2">
									Discount {attr.text}
								</Typography>
								<Grid sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography
										variant="body2"
										sx={{ fontWeight: 'bold' }}
										color="#D74C10">
										-{' ' + attr.value}
										{' %'}
									</Typography>
									<Icon
										iconName="IconTrash"
										color="#4f555e"
										onClick={() => removeADiscount(index)}
										sx={{
											height: '20px',
											width: '20px',
											marginLeft: '5px',
											cursor: 'pointer',
										}}
									/>
								</Grid>
							</Grid>
						))}
						{discount?.data?.length > 0 && (
							<Grid
								className={styles.spaceBtwFlex}
								sx={{ marginBottom: '0.5rem' }}>
								<Typography
									onClick={() => showDiscount('update')}
									variant="subtitle2"
									sx={{
										cursor: 'pointer',
										fontWeight: 'bold',
										marginTop: '0.5rem',
									}}
									color="#16885F">
									Update
								</Typography>
							</Grid>
						)}
						{(discount?.data?.length === 0 || !discount?.data) && (
							<Grid className={styles.addDiscountBtn}>
								<Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
									Add Discount
								</Typography>
								<Grid>
									<Icon
										color="#16885F"
										onClick={showDiscount}
										iconName="IconCirclePlus"
										sx={{ width: '24px', height: '24px', cursor: 'pointer' }}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid className={styles.taxSection}>
						{cart?.cart?.taxes?.data?.map((tax, index) => {
							return (
								<Grid
									key={index + 1}
									className={styles.spaceBtwFlex}
									sx={{ marginTop: '0.7rem' }}>
									<Grid>
										<Typography
											variant="body2"
											sx={{
												fontWeight: '400px',
												color: 'rgba(0, 0, 0, 0.87)',
											}}>
											{tax.text}
										</Typography>
									</Grid>
									<Grid sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
											{tax.value}
											{' %'}
										</Typography>
										<Icon
											iconName="IconTrash"
											color="#4f555e"
											onClick={() => removeSingleTax(index)}
											sx={{
												height: '20px',
												width: '20px',
												marginLeft: '5px',
												cursor: 'pointer',
											}}
										/>
									</Grid>
								</Grid>
							);
						})}
						{cart?.cart?.taxes?.data?.length > 0 && (
							<Grid className={styles.spaceBtwFlex}>
								<Typography
									onClick={removeTaxesClick}
									variant="subtitle2"
									sx={{
										cursor: 'pointer',
										fontWeight: 'bold',
										marginTop: '0.5rem',
									}}
									color="#16885F">
									Remove all taxes
								</Typography>
							</Grid>
						)}
						<Grid
							className={styles.addDiscountBtn}
							sx={
								cart?.cart?.taxes?.data?.length > 0
									? { marginTop: '0.9rem' }
									: {}
							}>
							<Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
								Add Taxes
							</Typography>
							<Grid>
								<Icon
									color="#16885F"
									onClick={addTaxesClick}
									iconName="IconCirclePlus"
									sx={{ width: '24px', height: '24px', cursor: 'pointer' }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid>
					<Grid className={styles.billingSummary}>
						<Grid className={styles.billingSummaryText}>
							<Grid className={styles.billingText}>
								<Typography variant={'caption'} color="rgba(0, 0, 0, 0.6)">
									BILLING SUMMARY
								</Typography>
							</Grid>
						</Grid>
						<Grid className={styles.spaceBtwFlex + ' ' + styles.margin1rem}>
							<Typography color="rgba(0, 0, 0, 0.6)" variant="body2">
								Subtotal
							</Typography>
							<Typography color="rgba(0, 0, 0, 0.6)" variant="body2">
								{cart?.currency} {cart?.cart?.summary?.subtotal}
							</Typography>
						</Grid>
						<Grid className={styles.spaceBtwFlex + ' ' + styles.margin1rem}>
							<Typography color="rgba(0, 0, 0, 0.6)" variant="body2">
								Discount
							</Typography>
							<Typography color="#D74C10" variant="body2">
								{cart?.currency} {cart?.cart?.summary?.discount}
							</Typography>
						</Grid>
						<Grid className={styles.spaceBtwFlex + ' ' + styles.margin1rem}>
							<Typography color="rgba(0, 0, 0, 0.6)" variant="body2">
								Total taxes
							</Typography>
							<Typography color="rgba(0, 0, 0, 0.6)" variant="body2">
								{cart?.currency} {cart?.cart?.summary?.total_taxes}
							</Typography>
						</Grid>
						<Grid
							className={styles.spaceBtwFlex + ' ' + styles.margin1rem}
							sx={{ marginTop: '1rem' }}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
								Grand Total
							</Typography>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
								{cart?.currency} {cart?.cart?.summary?.grand_total}
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={styles.actionBtns}>
						<Grid xs={5}>
							<Button fullWidth onClick={saveAsDraft}>
								Save as draft
							</Button>
						</Grid>
						<Grid xs={5}>
							<Button
								fullWidth
								onClick={submitQuote}
								sx={{ color: 'white', background: 'black' }}>
								Submit Quote
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<AddDiscount />
			<SelectUsers />
		</>
	);
}

CartRight.propTypes = {
	fromDrawer: PropTypes.bool.isRequired,
};
