import PropTypes from 'prop-types';
import './ProductDetails.css';

import {
	Box,
	Button,
	Counter,
	CustomInput,
	Drawer,
	Grid,
	Icon,
	TextArea,
	Typography,
} from '@sourcewiz/the-source';
import React from 'react';

export default function CustomiseContentDrawer({
	setIsCustomiseModalOpen,
	isCustomiseModalOpen,
	setTextVal,
	textVal,
	onCustomizeProductCounterChange,
	customizeCount,
	addCustomizeProductToCart = () => {},
	handlePriceChange = () => {},
}) {
	const customiseContent = (
		<Box height="100vh" sx={{ background: '#fff' }} padding={2}>
			<Grid marginBottom="2em" container width="100%">
				<Grid item>
					<Typography variant="h6">Customize product</Typography>
				</Grid>
				<Grid
					item
					alignItems="center"
					justifyContent="space-between"
					marginLeft="auto">
					<Icon
						iconName="IconX"
						className="pdp_pointer"
						onClick={() => setIsCustomiseModalOpen(false)}
					/>
				</Grid>
			</Grid>
			<Grid marginBottom="1em">
				<TextArea
					handleChange={(event) => {
						setTextVal(event.target.value);
					}}
					label="Add notes"
					name="Add notes"
					value={textVal}
					required
					rows={4}
					sx={{
						width: '100%',
					}}
				/>
			</Grid>
			<Box className="pdp_customise_input">
				<CustomInput
					fullWidth
					startIcon="$"
					onChange={handlePriceChange}
					type="number"
					className="pdp_custom_input">
					Price
				</CustomInput>
			</Box>
			<Box className="pdp_customise_footer">
				<Grid container justifyContent="space-between" spacing={2}>
					<Grid item xs={6}>
						<Counter
							handleDecrement={(val) => {
								onCustomizeProductCounterChange(val);
							}}
							handleIncrement={(val) => {
								onCustomizeProductCounterChange(val);
							}}
							min={1}
							max={100}
							isBtnDisableOnMin
							initialCount={customizeCount}
							step={1}
						/>
					</Grid>
					<Grid item xs={6}>
						<Button
							fullWidth
							onClick={addCustomizeProductToCart}
							sx={{ height: '2.75em' }}
							variant="contained"
							color="primary">
							Add to cart
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);

	return (
		<Drawer
			anchor="right"
			open={isCustomiseModalOpen}
			content={customiseContent}
			onClose={() => setIsCustomiseModalOpen(false)}
		/>
	);
}

CustomiseContentDrawer.propTypes = {
	isCustomiseModalOpen: PropTypes.any.isRequired,
	setIsCustomiseModalOpen: PropTypes.func.isRequired,
	setTextVal: PropTypes.func.isRequired,
	textVal: PropTypes.any.isRequired,
};
