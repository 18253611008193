import {
	getQuickSettingsApi,
	updateQuickSettings,
} from '../../api/handlers/quickSettings';
import { updateObjectById } from '../../util/common';
import quickSettingsTypes from '../types/quickSettings.types';
import { closeToast, showToast } from './message.actions';
export const openQuickSettings = (open) => ({
	type: quickSettingsTypes.OPEN_QUICK_SETTINGS,
	open,
});

export const getQuickSettings = () => async (dispatch) => {
	try {
		const response = await getQuickSettingsApi();
		if (response?.data?.data) {
			dispatch({
				type: quickSettingsTypes.UPDATE_QUICK_SETTINGS,
				data: response?.data?.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const setFieldData = (type, field, data) => (dispatch, getState) => {
	const state = getState();
	let settings = { ...state.quickSettings.settings };
	switch (type) {
		case 'TOGGLE':
			settings = updateObjectById(settings, field.key, (item) => {
				const val = item.is_visible === 0 ? 1 : 0;
				return { ...item, is_visible: val, disable: val };
			});
			break;
		case 'TEXT':
			settings = updateObjectById(settings, field.key, (item) => {
				return { ...item, value: data };
			});
			break;
		case 'SELECT':
			settings = updateObjectById(settings, field.key, (item) => {
				const val = item.is_selected === 0 ? 1 : 0;
				return {
					...item,
					is_selected: val,
					disable: val,
					values: item.values.map((value) => ({
						...value,
						is_selected: data.value === value.heading ? 1 : 0,
					})),
				};
			});
			break;
		case 'RADIO':
			settings = updateObjectById(settings, field.parentId, (item) => {
				item.data = item.data.map((option) => {
					const newOption = { ...option };
					const val =
						option.key === field.childId ? (option.key === 1 ? 0 : 1) : 0;
					newOption.is_selected = val;
					return newOption;
				});
				return item;
			});
			break;
		case 'CHECKBOX':
			settings = updateObjectById(settings, field.key, (item) => {
				const val = item.is_selected === 0 ? 1 : 0;
				return { ...item, is_selected: val, disable: val };
			});
			break;
	}
	dispatch({
		type: quickSettingsTypes.UPDATE_QUICK_SETTINGS,
		data: settings,
	});
};

export const updateSettingsApi = () => async (dispatch, getState) => {
	try {
		const settings = getState().quickSettings.settings;
		const data = {};
		data['price_toggle'] = settings?.price?.is_visible || 0;
		settings?.price?.data?.forEach((_data) => {
			if (_data.key === 'price_currency') {
				_data?.values.forEach((value) => {
					if (value?.is_selected) {
						data['price_currency'] = value?.heading;
					}
				});
			}
		});
		settings?.metric?.data?.forEach((_data) => {
			if (_data.is_selected) {
				data['metric'] = _data.key;
			}
		});
		data['inventory_visibility'] = settings?.inventory?.is_visible;
		settings.inventory.data.forEach((_data) => {
			data[_data.key] = _data.is_selected;
		});
		const response = await updateQuickSettings(data);
		if (response.data) {
			dispatch(
				showToast({
					open: true,
					showCross: false,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					autoHideDuration: 5000,
					onClose: () => {
						dispatch(closeToast());
					},
					state: 'success',
					title: 'Success',
					subtitle: 'Sucessfully saved settings ',
					showActions: false,
				})
			);
			dispatch(getQuickSettings());
		}
		dispatch(openQuickSettings(false));
	} catch (error) {
		console.error(error);
		dispatch(
			showToast({
				open: true,
				showCross: false,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				autoHideDuration: 5000,
				onClose: () => {
					dispatch(closeToast());
				},
				state: 'error',
				title: 'Error',
				subtitle: ' Not able to save settings',
				showActions: false,
			})
		);
		dispatch(openQuickSettings(false));
	}
};
