import { Box, Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';

const OtherDetails = ({ data }) => {
	return (
		<Box>
			<Grid>
				<Typography variant="h6">Remarks</Typography>
				{data?.remarks?.map((remark) => (
					<Grid mt={3} key={remark?.id}>
						{remark?.attributes?.map((item) => (
							<Typography key={item?.key} variant="body2">
								{item?.value?.split('\n').map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<br />
									</React.Fragment>
								))}
							</Typography>
						))}
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default OtherDetails;
