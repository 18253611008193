import {
	Drawer,
	Input,
	Button,
	Grid,
	Icon,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	addRemoveTaxes,
	addTaxesToCart,
	removeAtaxFromDrawer,
	showHideTaxesModal,
	taxChange,
} from '../../../../store/actions/cart.actions';
import styles from './addTaxes.module.css';
export default function AddTaxes(props) {
	const taxes = useSelector((state) => state.cart.taxes);
	const cart = useSelector((state) => state.cart.data);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		dispatch(showHideTaxesModal({ show: false }));
	};
	useEffect(() => {}, []);

	const addTaxes = () => {
		dispatch(addRemoveTaxes());
	};

	const taxFieldChange = (name, index, value) => {
		dispatch(taxChange(index, name, value));
	};

	const addTaxesCart = () => {
		const data = taxes.taxes;
		dispatch(addTaxesToCart(data, cart?.id));
	};

	const removeADiscount = (index) => {
		dispatch(removeAtaxFromDrawer(index));
	};

	const grandTotal = Number(cart?.cart?.total_amount);

	const afterTax = taxes?.taxes
		?.map((tax) => (!isNaN(tax.value) ? Number(tax.value) : 0))
		?.reduce((pre, curr) => Number(pre) + Number(curr), 0);

	const taxedValue =
		Number(grandTotal) +
		Number(afterTax > 0 ? (grandTotal * (afterTax / 100)).toFixed(2) : 0);

	const renderDrawerData = () => {
		return (
			<Grid container className={styles.addTaxesBody}>
				<Grid className={styles.top}>
					<Grid xs={12} className={styles.header}>
						<Grid
							className={styles.AddTaxes}
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Typography variant="h5">Add taxes</Typography>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={() => closeDrawer()}
							/>
						</Grid>
						<Grid item xs={12} className={styles.headerSubheading}>
							<Typography color="rgba(0, 0, 0, 0.6)" variant={'body2'}>
								{/* Selection will be applied to all products */}
							</Typography>
						</Grid>
					</Grid>
					<Grid className={styles.body}>
						{taxes?.taxes?.map((data, index) => {
							return (
								<Grid container className={styles.field} key={index + 1}>
									<Grid item xs={6}>
										<Input
											onChange={(event) => {
												taxFieldChange('name', index, event.target.value);
											}}
											value={taxes.taxes[index].name}
											max={100}
											variant="outlined"
											placeholder="Type of tax"
										/>
									</Grid>
									<Grid item xs={4.5}>
										<Input
											type="number"
											onChange={(event) =>
												taxFieldChange('value', index, event.target.value)
											}
											value={taxes.taxes[index].value}
											variant="outlined"
											placeholder="Value in (%)"
										/>
									</Grid>
									<Grid item xs={1}>
										{taxes.taxes.length > 1 && (
											<Icon
												iconName="IconTrash"
												color="#4f555e"
												onClick={() => removeADiscount(index)}
												sx={{
													height: '20px',
													width: '20px',
													marginLeft: '5px',
													cursor: 'pointer',
												}}
											/>
										)}
									</Grid>
								</Grid>
							);
						})}
					</Grid>
					<Grid container>
						<Button onClick={addTaxes} variant="outlined">
							<Icon iconName="IconPlus" color="#16885F" />
							&nbsp; Tax
						</Button>
					</Grid>
				</Grid>
				<Grid container className={styles.bottomFixed}>
					<Grid className={styles.subTotal} xs={12}>
						<Typography variant="subtitle1" color="grey">
							Sub Total
						</Typography>
						<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
							{cart.currency} {taxedValue}
						</Typography>
					</Grid>
					<Grid className={styles.flexJustifiedCenterWithMargin} xs={12}>
						<Button onClick={addTaxesCart} fullWidth sx={{ height: 'auto' }}>
							Confirm
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={taxes.show}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
			{...props}
		/>
	);
}
