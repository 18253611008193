import { ENDPOINT } from '../../../constants/apiEndpoints';
import axios from '../../../util/axios';
import { dataBulkMock } from '../contract';

const getQueryStringParams = (sParam) => {
	var sPageURL = window.location.search.substring(1);
	var sURLVariables = sPageURL.split('&');
	for (var index = 0; index < sURLVariables.length; index++) {
		var sParameterName = sURLVariables[index].split('=');
		if (sParameterName[0] === sParam) {
			return sParameterName[1];
		}
	}
};

export function fetchData() {
	// const url = new URL(window.location.href);
	// const searchParams = new URLSearchParams(url.search);
	const cart_id = getQueryStringParams('cart_id');
	// let cart_id = null;
	let url = `${ENDPOINT}/cart/bulk`;
	if (cart_id) {
		url = `${ENDPOINT}/cart/bulk?cart_id=${cart_id}`;
	}
	return new Promise((res) => {
		axios
			.get(url)
			.then((response) => {
				res(response.data.data);
			})
			.catch((error) => {
				console.error(error);
				res(dataBulkMock);
			});
	});
}

export const setBulkData = (data) => {
	return axios.post(`${ENDPOINT}/cart/bulk`, data);
};
