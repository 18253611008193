import { AUTH } from '../../constants/apiEndpoints';
import axios from '../../util/axios';
export const enable2Fa = () => {};
export const validate2Fa = () => {};
export const deactivate2Fa = () => {};
export const login = (data) => {
	return axios.post(AUTH.LOGIN, data);
};
export const setPassword = (data, uid, token) => {
	return axios.post(`${AUTH.RESET_PASSWORD}${uid}/${token}/`, data);
};
export const resetPassword = (data) => {
	return axios.post(AUTH.SET_PASSWORD, data);
};
export const refreshToken = (data) => {
	return axios.post(AUTH.REFRESH_TOKEN, data);
};
export const verifyToken = (data) => {
	return axios.post(AUTH.VERIFY_TOKEN, data);
};

export const logoutUser = (data) => {
	return axios.post(AUTH.LOGOUT_API, data);
};

export const sampleApi = () => {
	return axios.get(AUTH.CHECK_ME);
};

export const login2Fa = () => {};
export const blacklistToken = () => {};
