import { Grid } from '@sourcewiz/the-source';

import styles from './CartSkeleton.module.css';
export default function CartSkeletonBody() {
	return (
		<>
			<Grid className={styles.body}>
				<Grid
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '4rem',
					}}>
					<Grid>
						<Grid
							className={styles.commonClass}
							sx={{
								width: '209px',
								height: '15px',
							}}></Grid>
						<Grid
							className={styles.commonClass}
							sx={{
								width: '175px',
								marginTop: '1rem',
								height: '15px',
							}}></Grid>
					</Grid>
					<Grid
						className={styles.commonClass}
						sx={{
							width: '72px',
							height: '38px',
							left: '758px',
							top: '120px',
							borderRadius: '8px',
						}}></Grid>
				</Grid>
				{[1, 2, 2, 3, 4, 5].map((element, index) => (
					<Grid
						key={index + 1}
						mt={'2rem'}
						sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Grid sx={{ display: 'flex' }}>
							<Grid
								className={styles.commonClass}
								sx={{
									width: '72px',
									height: '72px',
									left: '0px',
									top: '2px',
									borderRadius: '8px',
								}}></Grid>
							<Grid sx={{ marginLeft: '2rem' }}>
								<Grid
									className={styles.commonClass}
									sx={{
										width: '209px',
										height: '15px',
									}}></Grid>
								<Grid
									className={styles.commonClass}
									sx={{
										width: '175px',
										marginTop: '1rem',
										height: '15px',
									}}></Grid>
							</Grid>
						</Grid>
						<Grid
							className={styles.commonClass}
							sx={{
								width: '72px',
								height: '38px',
								left: '758px',
								top: '120px',
								borderRadius: '8px',
							}}></Grid>
					</Grid>
				))}
			</Grid>
		</>
	);
}
