export default {
	price: {
		key: 'price_toggle',
		data: [
			{
				key: 'price_list',
				type: 'text',
				value: 'wholesaler',
				heading: 'Price list',
				priority: 0,
			},
			{
				key: 'price_currency',
				type: 'select',
				values: [
					{
						key: 'INR',
						heading: 'inr',
						priority: 0,
						is_selected: 1,
					},
					{
						key: 'USD',
						heading: 'usd',
						priority: 1,
						is_selected: 0,
					},
					{
						key: 'EUR',
						heading: 'eur',
						priority: 2,
						is_selected: 0,
					},
				],
				heading: 'Currency',
				priority: 1,
			},
		],
		type: 'toggle',
		heading: 'Show Price',
		is_visible: 1,
	},
	metric: {
		data: [
			{
				id: 1,
				key: 'intl_standard',
				heading: 'International Standard',
				priority: 0,
				is_selected: 1,
				sub_heading: 'meter,kilogram',
			},
			{
				id: 2,
				key: 'imperial',
				heading: 'Imperial',
				priority: 1,
				is_selected: 0,
				sub_heading: 'feet, pound',
			},
		],
		type: 'radio',
		key: 'metric',
		heading: 'Metric System',
	},
	inventory: {
		key: 'inventory_visibility',
		data: [
			{
				key: 'show_stock_quantity',
				type: 'checkbox',
				heading: 'Show Stock Quantity',
				priority: 0,
				is_selected: 0,
			},
		],
		type: 'toggle',
		heading: 'Show Inventory',
		is_visible: 0,
	},
};
