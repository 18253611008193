import { CART } from '../../constants/apiEndpoints';
import axios from '../../util/axios';
import { getParam } from '../../util/common';

export const getCart = (params) => {
	return axios.get(CART.GET_CART, null, { params });
};

export const updateCart = (data, params) => {
	return axios.post(CART.GET_CART, data, { params });
};

export const updateCartMeta = (data, cart_id) => {
	return axios.post(`${CART.GET_CART}${cart_id}/meta`, data);
};

export const deleteCartProduct = (data) => {
	return axios.post(`${CART.GET_ENTITIES}`, data);
};

export const removeAllProduct = (id) => {
	return axios.delete(`${CART.GET_CART}${id}`);
};

export const getCartBulkApi = () => {
	return axios.get(CART.BULK_GET);
};

export const createQuote = (data) => {
	return axios.post(CART.CREATE_QUTATION, data);
};
export const confirmQuote = (data) => {
	const quotation_id = getParam('quotation_id');
	return axios.post(`${CART.CONFIRM_QUTATION}${quotation_id}`, data);
};

export const getUserList = (params) => {
	return axios.get(CART.GET_ACCOUNT_LIST, { params });
};

export const getFieldsCreateAccount = (params) => {
	return axios.get(CART.GET_ACCOUNT_USERS, { params });
};

export const createAccount = (payload) => {
	return axios.post(CART.CREATE_ACCOUNT, payload);
};
