import PropTypes from 'prop-types';
import {
	Button,
	Checkbox,
	Chip,
	Drawer,
	Grid,
	Icon,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';

import { getData } from '../../api/handlers/quote';
import styles from './MultiSelect.module.css';
export default function MultiSelect({
	options = [],
	all_data = [],
	section_key = '',
	title = '',
	onSave = () => {},
}) {
	console.log('MultiSelect-->options', options);
	const [showDrawer, setShowDrawer] = useState(false);
	const [optionsLocal, setOptions] = useState([...all_data]);
	const optionClick = (index) => {
		const optionList = [...optionsLocal];
		if (optionList[index]) {
			optionList[index].is_selected = !optionList[index].is_selected;
		}
		setOptions(optionList);
	};
	const saveClick = () => {
		onSave && onSave(optionsLocal);
		setShowDrawer(false);
	};
	const getOptions = async () => {
		const data = await getData(section_key);
		if (data) {
			setOptions(data);
		}
	};
	useEffect(() => {
		if (showDrawer && all_data.length === 0) {
			getOptions();
		}
	}, [showDrawer]);
	const renderDrawerData = () => {
		return (
			<Grid container className={styles.container}>
				<Grid item className={styles.body} xs={12}>
					<Grid xs={12} className={styles.header}>
						<Grid
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Typography variant="h5">{title}</Typography>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={() => setShowDrawer(false)}
							/>
						</Grid>
					</Grid>
					<Grid className={styles.content}>
						{optionsLocal &&
							optionsLocal?.length > 0 &&
							optionsLocal?.map((option, index) => {
								return (
									<Button
										key={index + 1}
										tonal
										sx={{
											marginBottom: '1rem',
											display: 'flex',
											justifyContent: 'space-between',
											padding: '1rem',
											background: option?.is_selected ? '#E8F3EF' : 'white',
											border:
												'1px solid ' +
												(option?.is_selected
													? '#D1D6DD !important'
													: '#E8F3EF !important'),
										}}
										onClick={() => {
											optionClick(index);
										}}
										fullWidth>
										<Typography>{option?.label}</Typography>
										<Checkbox checked={option?.is_selected} />
									</Button>
								);
							})}
					</Grid>
					<Grid className={styles.footer} item xs={12}>
						<Button fullWidth onClick={saveClick}>
							Save
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<Grid className={styles.card}>
				<Grid className={styles.cardTitle}>
					<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
						{title}
					</Typography>
					<Button variant="text" onClick={() => setShowDrawer(true)}>
						Change
					</Button>
				</Grid>
				<Grid mt={'0.3rem'}>
					{options.map((ele, index) => {
						return (
							<Chip
								key={index + 1}
								variant="filled"
								label={ele?.label}
								sx={{ marginRight: '0.5rem' }}
								bgColor={'#F2F4F7'}
								textColor={'#25282D'}
							/>
						);
					})}
				</Grid>
			</Grid>
			<Drawer
				anchor="right"
				open={showDrawer}
				content={renderDrawerData()}
				onClose={() => setShowDrawer(false)}
			/>
		</>
	);
}

MultiSelect.propTypes = {
	allData: PropTypes.array.isRequired,
	onSave: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	sectionKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
