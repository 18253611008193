import { Grid } from '@sourcewiz/the-source';
import { useSelector } from 'react-redux';

import CheckoutCartStatic from '../../../CheckoutCartCard/CheckoutCartStatic';
import Styles from '../ReviewSubmit.module.css';

export default function Products() {
	const cart = useSelector((state) => state.cart);
	const cartData = cart.data;
	return (
		<Grid className={Styles.products}>
			{cartData?.entities?.map((product, index) => (
				<CheckoutCartStatic
					key={index + 1}
					{...product}
					staticProps={true}
					notEditable
				/>
			))}
		</Grid>
	);
}
