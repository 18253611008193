const loginActionTypes = {
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	CHANGE_EMAIL: 'CHANGE_EMAIL',
	CHANGE_STATUS: 'CHANGE_STATUS',
	FORGOT_PASSWORD_CHANGE_EMAIL: 'FORGOT_PASSWORD_CHANGE_EMAIL',
	RESET_PASSWORD_CHANGE_PWD: 'RESET_PASSWORD_CHANGE_PWD',
	RESET_PASSWORD_CHANGE_PWD_CONFIRM: 'RESET_PASSWORD_CHANGE_PWD_CONFIRM',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
};
export default loginActionTypes;
