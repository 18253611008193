import { Accordion, Grid, Typography } from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSectionChanges } from '../../../../store/actions/quote.action';
import MultiSelect from '../../../MultiSelect/MultiSelect';
import SingleSelect from '../../../SingleSelect/SingleSelect';
import styles from '../ReviewSubmit.module.css';
import CustomerInformation from './CustomerInformation';
import Notes from './Notes';
import Products from './Products';

export default function ReviewCartBody() {
	const dispatch = useDispatch();
	const quoteData = useSelector((state) => state?.quote?.quotation_details);
	const cart = useSelector((state) => state.cart.data);

	const optionChange = () => {};
	const title = cart?.entities?.length ? (
		`${cart?.entities?.length} Products`
	) : (
		<>------</>
	);
	const saveSection = (data, section) => {
		dispatch(saveSectionChanges(data, section));
	};
	const cartSummary = [
		{
			title: (
				<Grid>
					<Grid>
						<Typography variant="h6">Cart summary</Typography>
					</Grid>
					<Grid sx={{ margin: '1rem 0' }}>
						<Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
							{title}
						</Typography>
					</Grid>
				</Grid>
			),
			expandedContent: <Products />,
		},
	];
	const sectionRender = (section) => {
		let element = <></>;
		if (section.multi_select) {
			element = (
				<MultiSelect
					options={section.attributes}
					section_key={section?.section}
					optionChange={optionChange}
					title={section.name}
					onSave={(data) => {
						saveSection(data, section);
					}}
				/>
			);
		}
		if (section.dtype === 'select') {
			element = (
				<SingleSelect
					options={section.attributes}
					section_key={section?.section}
					optionChange={optionChange}
					title={section.name}
					onSave={(data) => {
						saveSection(data, section);
					}}
				/>
			);
		}
		switch (section.section) {
			case 'user_details':
				element = (
					<CustomerInformation
						section_key={section?.section}
						section={section}
						onSave={(data) => {
							saveSection(data, section);
						}}
					/>
				);
				break;
			case 'notes':
				element = (
					<Notes
						section_key={section?.section}
						section={section}
						onSave={(data) => {
							saveSection(data, section);
						}}
					/>
				);
				break;
			default:
				break;
		}
		return element;
	};
	return (
		<>
			<Grid className={styles.ReviewCartBody}>
				<Accordion
					content={cartSummary}
					titleBackgroundColor="#ffffff"
					titleColor="#000000"
					contentBackground="white"
					contentColor="#000000"
				/>
			</Grid>
			{quoteData.length > 0 &&
				quoteData?.map((section) => {
					return sectionRender(section);
				})}
		</>
	);
}
