import 'ag-grid-community';

import { Grid } from '@sourcewiz/the-source';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCartAction } from '../../../store/actions/cart.actions';
import CartHeader from '../CartHeader/CartHeader';
import AgGrid from './AgGrid';
import classes from './AgGrid.module.css';

export default function BulkEdit() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getCartAction());
	}, []);

	return (
		<>
			<CartHeader />
			<Grid className={classes.bulkEditBody}>
				<Grid className={classes.BulkEditContainer}>
					<AgGrid />
				</Grid>
			</Grid>
		</>
	);
}
