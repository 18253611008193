import filterActionTypes from '../types/filter.types';
const INITIAL_STATE = {
	active_filters: [],
	available_filters: [],
};

const filterReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case filterActionTypes.FETCH_ALL_FILTERS_SUCCESS:
			return {
				...state,
				available_filters: action.payload,
				error: null,
			};
		case filterActionTypes.FETCH_ALL_FILTERS_FAILURE:
			return {
				...state,
				available_filters: null,
				error: action.payload,
			};
		case filterActionTypes.SET_APPLIED_FACETS:
			const current = action.payload;
			let activeFilters = [];
			const isExisting =
				state.active_filters.filter((item) => item.key === current[0].key)
					.length > 0;
			if (isExisting) {
				const otherFilters = state.active_filters.filter(
					(item) => item.key !== current[0].key
				);
				activeFilters = [...otherFilters, ...action.payload];
			} else {
				activeFilters = [...state.active_filters, ...action.payload];
			}
			return {
				...state,
				active_filters: activeFilters,
			};

		case filterActionTypes.CLEAR_FACET:
			const filterToRemove = state.active_filters.find(
				(item) => item.key === action?.payload[0]?.key
			);
			if (filterToRemove.value.length === 1) {
				// Remove the filter from the active filters array if the item has only one
				return {
					...state,
					active_filters: state.active_filters.filter(
						(item) => item.key !== action?.payload[0]?.key
					),
				};
			} else {
				// Update the filter's value and return the updated active filters array
				const updatedFilters = { ...filterToRemove };
				updatedFilters.value = updatedFilters.value.filter(
					(item) => item !== action?.payload[0]?.value
				);
				const otherFilters = state.active_filters.filter(
					(item) => item.key !== action?.payload[0]?.key
				);
				return {
					...state,
					active_filters: [...otherFilters, updatedFilters],
				};
			}

		case filterActionTypes.CLEAR_FILTER:
			const filteredOut = state.active_filters.filter(
				(item) => item.key !== action?.payload
			);
			return {
				...state,
				active_filters: filteredOut,
			};

		case filterActionTypes.CLEAR_ALL_FILTERS:
			return {
				...state,
				active_filters: [],
			};
		default:
			return state;
	}
};
export default filterReducer;
