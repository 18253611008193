import { debounce } from 'lodash';

import {
	login,
	logoutUser,
	resetPassword,
	sampleApi,
	setPassword,
} from '../../api/handlers/login';
import { setTokenInHeader } from '../../util/axios';
import { deleteAllCookies, setAuthCookies } from '../../util/common';
import loginActionTypes from '../types/login.types';
import { closeToast, showToast } from './message.actions';

export const loginAction = (payload) => async (dispatch) => {
	try {
		const { username, password } = payload;
		if (username.length === 0 || password.length === 0) {
			return dispatch(
				showToast({
					open: true,
					showCross: false,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					autoHideDuration: 5000,
					onClose: (event, reason) => {
						if (reason === 'clickaway') {
							return;
						}
						dispatch(closeToast());
					},
					state: 'error',
					title: 'Error',
					subtitle: 'Fields cannot be empty',
					showActions: false,
				})
			);
		}
		const response = await login(payload);
		const message = {
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: () => {
				dispatch(closeToast());
			},
			state: 'success',
			title: 'Success',
			subtitle: 'Sucessfully logged in',
			showActions: false,
		};
		if (response?.data?.access) {
			setAuthCookies(response?.data?.access, response?.data?.refresh);
			setTokenInHeader(response?.data?.access);
			dispatch(showToast(message));
			dispatch(loginSuccess());
			window.location.href = window.location.origin + '/all-products';
		}
	} catch (error) {
		const message = {
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: (event, reason) => {
				if (reason === 'clickaway') {
					return;
				}
				dispatch(closeToast());
			},
			state: 'error',
			title: 'Error',
			subtitle:
				error.response.data.error ||
				'Unable to login with provided credentials',
			showActions: false,
		};
		dispatch(showToast(message));
		console.error(error.response.data.error);
	}
};

export const debouncedLoginAction = debounce((payload) => {
	return (dispatch) => {
		dispatch(loginAction(payload));
	};
}, 1000);

export const forgotPasswordAction = (payload) => async (dispatch) => {
	try {
		const response = await resetPassword(payload);

		const message = {
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: () => {
				dispatch(closeToast());
			},
			state: 'success',
			title: 'Success',
			subtitle: 'Sucessfully sent password reset mail',
			showActions: false,
		};
		if (
			window.location.href.includes('localhost') ||
			window.location.href.includes('vercel.app')
		) {
			const oldUrl = response.data.reset_url;
			const newUrl = oldUrl.replace(
				'http://dev-atlas.sourcerer.tech/api/users/password_reset_confirm',
				`${window.location.origin}/reset-password`
			);
			window.open(newUrl);
		}
		dispatch(showToast(message));
	} catch (error) {
		const message = {
			open: true,
			showCross: false,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
			autoHideDuration: 5000,
			onClose: (event, reason) => {
				if (reason === 'clickaway') {
					return;
				}
				dispatch(closeToast());
			},
			state: 'error',
			title: 'Error',
			subtitle:
				error.response.data.error || 'Unable to reset password for this email',
			showActions: false,
		};
		dispatch(showToast(message));
		console.error(error);
	}
};

export const resetPasswordAction =
	(payload, uid, token) => async (dispatch) => {
		try {
			const response = await setPassword(payload, uid, token);
			if (response?.status === 200) {
				const message = {
					open: true,
					showCross: false,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					autoHideDuration: 5000,
					onClose: () => {
						dispatch(closeToast());
					},
					state: 'success',
					title: 'Success',
					subtitle: 'Password changed please go to login screen',
					showActions: false,
				};
				dispatch(showToast(message));
				dispatch(resetStatus(true));
			}
		} catch (error) {
			const message = {
				open: true,
				showCross: false,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				autoHideDuration: 5000,
				onClose: (event, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					dispatch(closeToast());
				},
				state: 'error',
				title: 'Error',
				subtitle:
					error.response.data.error ||
					'Unable to reset password for this email',
				showActions: false,
			};
			dispatch(showToast(message));
			console.error(error);
		}
	};
export const resetStatus = (status) => ({
	type: loginActionTypes.RESET_PASSWORD_SUCCESS,
	status,
});
export const logout_click = (payload) => async (dispatch) => {
	try {
		const response = await logoutUser(payload);
		if (response?.status === 200) {
			dispatch(changeStatus({ loggedIn: false }));
			deleteAllCookies();
			window.location.href = window.location.origin;
		}
	} catch (error) {
		dispatch(changeStatus({ loggedIn: false }));
		deleteAllCookies();
		window.location.href = window.location.origin;
	} finally {
		deleteAllCookies();
	}
};

export const checkApi = () => async () => {
	try {
		const response = await sampleApi();
		if (response?.status !== 200) {
			console.error(response);
		}
	} catch (error) {
		console.error(error);
	}
};

export const changePassword_reset = (password) => ({
	type: loginActionTypes.RESET_PASSWORD_CHANGE_PWD,
	password,
});

export const changePasswordConfirm_reset = (password) => ({
	type: loginActionTypes.RESET_PASSWORD_CHANGE_PWD_CONFIRM,
	password,
});

export const changePassword = (password) => ({
	type: loginActionTypes.CHANGE_PASSWORD,
	password,
});

export const forgotPasswordEmailAction = (forgotPasswordEmail) => ({
	type: loginActionTypes.FORGOT_PASSWORD_CHANGE_EMAIL,
	forgotPasswordEmail,
});

export const changeEmail = (email) => ({
	type: loginActionTypes.CHANGE_EMAIL,
	email,
});

export const loginSuccess = () => ({
	type: loginActionTypes.LOGIN_SUCCESS,
});

export const changeStatus = (status) => ({
	type: loginActionTypes.CHANGE_STATUS,
	status,
});
