import { PRODUCTS } from '../../constants/apiEndpoints';
import axios from '../../util/axios';

export const getProductDetail = (id) => {
	return axios.get(PRODUCTS.GET_PRODUCT_DETAILS(id));
};

export const getProducts = (params) => {
	return axios.get(PRODUCTS.GET_PRODUCTS(params));
};

export const getAllFilters = () => {
	return axios.get(PRODUCTS.GET_ALL_FILTERS);
};

export const getProductVariants = (productId) => {
	return axios.get(PRODUCTS.GET_PRODUCT_VARIANTS(productId));
};
