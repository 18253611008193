import PropTypes from 'prop-types';
import { Box, Paper } from '@sourcewiz/the-source';
import React from 'react';

function Content({ children }) {
	return (
		<Paper minHeight="89.5vh">
			<Box>{children}</Box>
		</Paper>
	);
}

Content.propTypes = {
	children: PropTypes.any.isRequired,
};

export default Content;
