import { access_token } from '../../util/common';
import loginActionTypes from '../types/login.types';
const INITIAL_STATE = {
	email: '',
	password: '',
	forgotPasswordEmail: '',
	changePassword: '',
	changePasswordConfirm: '',
	status: {
		loggedIn: !!access_token(),
	},
	resetSuccess: false,
};

const loginReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loginActionTypes.CHANGE_EMAIL:
			return { ...state, email: action.email };
		case loginActionTypes.CHANGE_PASSWORD:
			return { ...state, password: action.password };
		case loginActionTypes.CHANGE_STATUS:
			return { ...state, status: action.status };
		case loginActionTypes.FORGOT_PASSWORD_CHANGE_EMAIL:
			return { ...state, forgotPasswordEmail: action.forgotPasswordEmail };
		case loginActionTypes.RESET_PASSWORD_CHANGE_PWD:
			return { ...state, changePassword: action.password };
		case loginActionTypes.RESET_PASSWORD_CHANGE_PWD_CONFIRM:
			return { ...state, changePasswordConfirm: action.password };
		case loginActionTypes.LOGIN_SUCCESS:
			return { ...state, status: { loggedIn: true } };
		case loginActionTypes.RESET_PASSWORD_SUCCESS:
			return { ...state, resetSuccess: action.status };
		default:
			return state;
	}
};
export default loginReducer;
