import { Box, TopBar as SWTopBar } from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../constants';
import { openQuickSettings } from '../../../store/actions/quickSettings.actions';

export default function Topbar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				width: '100%',
				padding: '1rem',
			}}>
			<SWTopBar
				breadCrumbList={[
					{ id: 1, linkTitle: 'Dashboard', link: '/' },
					{ id: 2, linkTitle: 'Products', link: '/all-products' },
				]}
				brandOptions={[
					{ value: 'ikea', label: 'Ikea' },
					{ value: 'walmart', label: 'Walmart' },
					{ value: 'target', label: 'Target' },
				]}
				handleCart={() => {
					navigate(ROUTES.CART.DEFAULT);
				}}
				handleQuickSettings={() => {
					dispatch(openQuickSettings(true));
				}}
				handleChange={() => {}}
			/>
		</Box>
	);
}
