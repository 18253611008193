import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@sourcewiz/the-source';

import SearchEmptyState from '../../../assets/icons/Search/SearchEmptyState.svg';
import styles from '../AllProducts.module.css';

export default function EmptyState({ type, onClearSearch, onClearFilter }) {
	const handleReset = () => {
		if (type === 'filters') {
			onClearFilter();
		} else {
			onClearSearch();
		}
	};

	return (
		<Grid
			container
			rowSpacing={4}
			direction="column"
			className={styles.empty_state_container}>
			<Grid item mt={-10}>
				<img src={SearchEmptyState} />
			</Grid>
			<Grid item alignItems="center" justifyContent="center">
				<Typography variant="h6" color="#25282D" align="center">
					We haven’t found any results for your search
				</Typography>
				<Typography variant="body1" color="#2D323A" sx={{ lineHeight: 2.5 }}>
					Try clearing some of the filters you have applied
				</Typography>
			</Grid>
			<Grid item>
				<Button onClick={handleReset}>
					{type === 'filters' ? 'Reset Filters' : 'Clear Search'}
				</Button>
			</Grid>
		</Grid>
	);
}

EmptyState.propTypes = {
	onClearFilter: PropTypes.func.isRequired,
	onClearSearch: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};
