import {
	Button,
	Checkbox,
	Grid,
	Input,
	Radio,
	SingleSelect,
	Switch,
	Typography,
} from '@sourcewiz/the-source';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFieldData } from '../../store/actions/quickSettings.actions';
import styles from './quickSettings.module.css';
const QuickSettingsContent = () => {
	const formData = useSelector((state) => state.quickSettings.settings);
	const dispatch = useDispatch();

	const toggleChange = (data, event) => {
		dispatch(setFieldData('TOGGLE', data, event));
	};
	const textChange = (data, event) => {
		dispatch(setFieldData('TEXT', data, event.target.value));
	};
	const selectChange = (data, event) => {
		dispatch(setFieldData('SELECT', data, event));
	};
	const radioChange = (data, event) => {
		dispatch(setFieldData('RADIO', data, event));
	};
	const checkboxChange = (data, event) => {
		dispatch(setFieldData('CHECKBOX', data, event));
	};

	const renderField = (field, parent = null) => {
		const disabled =
			parent?.is_visible === 0 ||
			parent?.is_selected === 0 ||
			parent?.disabled === 0 ||
			field?.disabled === 0;

		switch (field?.type) {
			case 'toggle':
				return (
					<Grid
						className={styles.commonMargin1rem}
						key={field?.heading}
						sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
							{field?.heading}
						</Typography>
						<Switch
							disabled={disabled}
							checked={field?.is_visible === 1}
							onChange={() => toggleChange(field)}
						/>
					</Grid>
				);
			case 'text':
				return (
					<Grid key={field?.heading} className={styles.commonMargin2rem}>
						<Input
							disabled={disabled}
							sx={{ width: '100%' }}
							label={field?.heading}
							error={false}
							variant="outlined"
							onChange={(event) => textChange(field, event)}
							value={field?.value}>
							{field?.heading}
						</Input>
					</Grid>
				);
			case 'select':
				let label = '';
				label = field?.heading;
				const selected = field.values.find((event) => event.is_selected);
				return (
					<Grid className={styles.commonMargin2rem}>
						<SingleSelect
							disabled={disabled}
							label={label}
							handleChange={(event) => selectChange(field, event)}
							options={field?.values?.map((option) => ({
								...option,
								value: option.heading,
								label: option.heading,
							}))}
							value={selected?.heading}
							defaultValue={selected?.heading}
						/>
					</Grid>
				);
			case 'radio':
				return (
					<Grid key={field?.heading} className={styles.commonMargin2rem}>
						<Grid className={styles.commonMargin1rem}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
								{field?.heading}
							</Typography>
						</Grid>
						{field?.data?.map((option) => (
							<Grid key={option?.id} className={styles.commonMargin1rem}>
								<Button
									onClick={() =>
										radioChange({ parentId: field?.key, childId: option.key })
									}
									{...(option?.is_selected === 1
										? { tonal: true }
										: { variant: 'outlined' })}
									disabled={disabled}
									sx={{
										width: '100%',
										padding: '6px 0',
										...(option?.is_selected === 1
											? { border: '1px solid #E8F3EF !important' }
											: { border: '1px solid #D1D6DD !important' }),
									}}>
									<Radio
										sx={{ paddingTop: '0px' }}
										checked={option?.is_selected === 1}
									/>
									<Grid container sx={{ marginLeft: '0.2rem' }}>
										<Grid item xs={12}>
											<Typography
												align="left"
												variant="body2"
												sx={{
													fontWeight: '400',
													color: 'rgba(0, 0, 0, 0.87)',
												}}>
												{option?.heading}
											</Typography>
										</Grid>
										<Grid item xs={12} display="flex" justifyContent="left">
											<Typography
												sx={{
													fontWeight: 'bold',
													fontSize: '12px',
													color: 'rgba(0, 0, 0, 0.6)',
												}}
												variant="subtitle2">
												{option?.sub_heading}
											</Typography>
										</Grid>
									</Grid>
								</Button>
							</Grid>
						))}
					</Grid>
				);
			case 'checkbox':
				return (
					<Grid
						container
						display="flex"
						alignItems="center"
						sx={{ marginBottom: '0.5rem' }}
						justifyContent="left">
						<Checkbox
							onChange={() => checkboxChange(field)}
							sx={{ paddingLeft: '0px' }}
							checked={field?.is_selected}
							disabled={disabled}
						/>{' '}
						<Typography
							onClick={() => (disabled ? () => {} : checkboxChange(field))}
							sx={{ cursor: 'pointer' }}
							variant="subtitle2"
							color={disabled ? 'grey' : 'black'}>
							{field?.heading}
						</Typography>
					</Grid>
				);
			default:
				return null;
		}
	};

	return (
		<Grid padding={'0 2rem'} className={styles.body} item xs={12}>
			{Object.entries(formData).map(([key, field]) => (
				<Grid key={key}>
					{renderField(field, null)}
					{field?.data?.map((nestedField) => renderField(nestedField, field))}
				</Grid>
			))}
		</Grid>
	);
};

export default QuickSettingsContent;
