import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginSuccess } from '../store/actions/login.actions';
import { access_token } from '../util/common';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

export default function AllRoutes() {
	const login = useSelector((state) => state.login);
	const dispatch = useDispatch();

	useEffect(() => {
		const token = access_token();
		token && dispatch(loginSuccess());
		// setIsLoggedin(!!token);
	}, [dispatch]);
	return <>{login.status.loggedIn ? <ProtectedRoutes /> : <PublicRoutes />}</>;
}
