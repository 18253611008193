import axios from '../../util/axios';
import { getParam } from '../../util/common';

export const getQuoteApi = (quotation_id) => {
	return axios.get(`/quotation/${quotation_id}`);
};

export const getNotes = () => {
	return axios.get('/quotation/notes');
};

export const getShippingAddresses = () => {
	return axios.get('/quotation/ShippingAddresses');
};

export const getBillingAddresses = () => {
	return axios.get('/quotation/BillingAddresses');
};

export const getShippingMethod = () => {
	return axios.get('/quotation/ShippingMethod');
};

export const getPaymentMethod = () => {
	return axios.get('/quotation/PaymentMethod');
};

export const getPaymentTerms = () => {
	return axios.get('/quotation/PaymentTerms');
};

export const getBankDetails = () => {
	return axios.get('/quotation/BankDetails');
};

export const getData = (sectionName) => {
	let request;
	switch (sectionName) {
		case 'payment_method': {
			request = getPaymentMethod();
			request = Promise.resolve([
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			]);
			break;
		}
		case 'payment_terms': {
			request = getPaymentTerms();
			request = Promise.resolve([
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			]);
			break;
		}
		case 'bank_details': {
			request = getBankDetails();
			request = Promise.resolve([
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			]);
			break;
		}
		case 'shipping_method': {
			request = getShippingMethod();
			request = Promise.resolve([
				{
					label: 'method1',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method2',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
				{
					label: 'method3',
					color: '#000000',
					bg_color: '#FFFFFF',
					priority: 1,
				},
			]);
			break;
		}
		case 'shipping_address': {
			request = getShippingAddresses();
			break;
		}
		case 'billing_address': {
			request = getBillingAddresses();
			break;
		}
	}
	return request;
};

export const updateQuote = (data) => {
	const quotation_id = getParam('quotation_id');
	return axios.patch(`/quotation/${quotation_id}`, data);
};

export const createQuotation = (data) => {
	const quotation_id = getParam('quotation_id');
	return axios.post(`/quotation/update/${quotation_id}`, data);
};

export const getAddressFieldsData = () => {
	const quotation_id = getParam('quotation_id');
	return axios.get(`/quotation/address/fields/${quotation_id}`);
};

export const createAddress = (data) => {
	return axios.post(`/settings/account/add`, data);
};
