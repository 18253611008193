// export const ENDPOINT = 'http://10.170.0.56:8000';
// export const ENDPOINT = 'https://dev-atlas.sourcerer.tech';
export const ENDPOINT = 'https://dev-atlas.sourcerer.tech';
export const POSTMAN_MOCK =
	'https://e06f6a5f-4c44-4f2b-b203-94d9469288b5.mock.pstmn.io';
export const POSTMAN_ENDPOINT =
	'https://528cdc15-33b6-407f-a746-332085f87a93.mock.pstmn.io';
// 'https://2d3715ee-751d-4c25-9742-3549c0f0dbb6.mock.pstmn.io';

export const AUTH = {
	LOGIN: `${ENDPOINT}/auth/login/`,
	REFRESH_TOKEN: `${ENDPOINT}/auth/token/refresh/`,
	VERIFY_TOKEN: `${ENDPOINT}/auth/token/verify/`,
	ENABLE_2FA: `${ENDPOINT}/auth/email/activate/`,
	VALIDATE_2FA: `${ENDPOINT}/auth/email/activate/confirm/`,
	SET_PASSWORD: `${ENDPOINT}/users/password_reset/`,
	LOGOUT_API: `${ENDPOINT}/users/logout`,
	RESET_PASSWORD: `${ENDPOINT}/users/password_reset_confirm/`,
	DEACTIVATE_2FA: `${ENDPOINT}/auth/email/deactivate/`,
	LOGIN_2FA: `${ENDPOINT}/auth/email/deactivate/`,
	BLACKLIST_TOKEN: `${ENDPOINT}/auth/token/blacklist/`,
	CHECK_ME: `${ENDPOINT}/api/users/me/`,
};

export const QUICK_SETTINGS = {
	UPDATE_QUICK_SETTINGS: `${ENDPOINT}/settings/`,
	GET_QUICK_SETTINGS: `${ENDPOINT}/settings`,
};

export const CART = {
	GET_CART: `${ENDPOINT}/cart/`,
	GET_ENTITIES: `${ENDPOINT}/cart/entities`,
	CART: `${ENDPOINT}/cart`,
	BULK_GET: `${ENDPOINT}/cart/bulk`,
	CREATE_QUTATION: `${ENDPOINT}/quotation/create/`,
	CONFIRM_QUTATION: `${ENDPOINT}/quotation/confirm/`,
	GET_ACCOUNT_LIST: `${ENDPOINT}/settings/account/list`,
	GET_ACCOUNT_USERS: `${ENDPOINT}/settings/account/details/create/cart`,
	CREATE_ACCOUNT: `${ENDPOINT}/settings/account/add`,
};
export const PRODUCTS = {
	GET_PRODUCT_DETAILS: (id) => `${ENDPOINT}/pim/entity/${id}`,
	GET_PRODUCTS: (params) =>
		`${ENDPOINT}/pim/entity/search${params ? `?${params}` : ''}`,
	GET_ALL_FILTERS: `${ENDPOINT}/pim/entity/filters`,
	GET_PRODUCT_VARIANTS: (productId) =>
		`${ENDPOINT}/pim/entity/variants/${productId}`,
};

export const ACCOUNTS = {
	GET_CREATE_ACCOUNTS_FORM: `${ENDPOINT}/settings/account/details/create/main`,
	POST_CREATE_ACCOUNT: `${ENDPOINT}/settings/account/add`,
};
