import messageTypes from '../types/message.types';
const INITIAL_STATE = {
	open: false,
	showCross: false,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
	autoHideDuration: 5000,
	onClose: () => {},
	state: 'success',
	title: 'Title 1',
	subtitle: 'Subtitle 1',
	showActions: false,
	iconSize: 'large',
	primaryBtnName: 'Submit',
	secondryBtnName: 'Cancel',
};

const loginReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case messageTypes.SHOW_MESSAGE:
			return { ...state, ...action.payload };
		case messageTypes.SHOW_INITIAL_STATE:
			return { ...INITIAL_STATE };
		default:
			return state;
	}
};
export default loginReducer;
