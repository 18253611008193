import { Grid } from '@sourcewiz/the-source';

import styles from './CartSkeleton.module.css';
export default function CartSkeletonRight() {
	return (
		<>
			<Grid className={styles.cartRight}>
				<Grid>
					<Grid
						className={styles.commonClass}
						sx={{
							width: '209px',
							height: '15px',
						}}></Grid>
					<Grid
						className={styles.commonClass}
						sx={{
							width: '175px',
							marginTop: '1rem',
							height: '15px',
						}}></Grid>
				</Grid>
				<Grid>
					<Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Grid>
							<Grid
								className={styles.commonClass}
								sx={{
									width: '73px',
									height: '15px',
								}}></Grid>
							<Grid
								className={styles.commonClass}
								sx={{
									width: '115px',
									height: '15px',
									marginTop: '1rem',
								}}></Grid>
						</Grid>
						<Grid>
							<Grid
								className={styles.commonClass}
								sx={{
									width: '73px',
									height: '15px',
									left: '297px',
									top: '653px',
								}}></Grid>
							<Grid
								className={styles.commonClass}
								sx={{
									width: '73px',
									height: '15px',
									left: '297px',
									top: '653px',
									marginTop: '1rem',
								}}></Grid>
						</Grid>
					</Grid>
					<Grid
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: '2.5rem',
						}}>
						<Grid
							className={styles.commonClass}
							sx={{
								width: 'calc(50% - 40px)',
								height: '40px',
								left: '40px',
								top: '786px',
								borderRadius: '8px',
							}}></Grid>
						<Grid
							className={styles.commonClass}
							sx={{
								width: 'calc(50% - 40px)',
								height: '40px',
								left: '40px',
								top: '786px',
								borderRadius: '8px',
							}}></Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
