import PropTypes from 'prop-types';
import {
	Button,
	Drawer,
	Grid,
	Icon,
	Image,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateQuote } from '../../api/handlers/quote';
import { showAddresses } from '../../store/actions/cart.actions';
import AddAddress from '../AddAddress/AddAddress';
import styles from './SelectAddress.module.css';
export default function SelectAddress({
	open,
	close,
	address,
	selectedAddress,
	getAddress = () => {},
	type,
	name,
}) {
	const [selectedAdd, selectAdd] = useState(null);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		close();
		dispatch(showAddresses({ show: false }));
	};
	const [addAdd, showAdd] = useState(false);
	const closeAddAddress = () => {
		showAdd(false);
	};
	const addAddress = () => {
		showAdd(true);
	};
	const imgSrc =
		'https://img.freepik.com/premium-vector/no-address-found-flat-illustration_418302-85.jpg?w=2000';
	useEffect(() => {
		const index = address?.find((add) => add.id === selectedAddress.id);
		if (index > -1) {
			selectAdd(index);
		}
	}, [address]);
	const saveAddress = () => {
		if (isNaN(selectedAdd) === false) {
			const payload = {
				user_details: {
					address: [
						{
							key: type,
							value: address[selectedAdd].id,
						},
					],
				},
			};
			updateQuote(payload);
		}
		close();
	};
	const renderDrawerData = () => {
		return (
			<>
				<Grid className={styles.addressContainer}>
					<Grid item xs={12}>
						<Grid xs={12} className={styles.header}>
							<Grid
								xs={12}
								item
								display="flex"
								justifyContent="space-between"
								alignItems="center">
								<Typography variant="h5">Update {name} </Typography>
								<Icon
									iconName="IconX"
									sx={{ cursor: 'pointer' }}
									onClick={() => closeDrawer()}
								/>
							</Grid>
						</Grid>
						{address && (
							<Grid item xs={12} className={styles.addressBody}>
								<Grid item xs={12} className={styles.addresses}>
									{address.map((add, index) => {
										return (
											<Grid
												key={`${index}`}
												className={
													styles.address +
													' ' +
													(index === selectedAdd
														? styles.selectedAddress
														: styles.notSelectedAdd)
												}
												onClick={() => selectAdd(index)}>
												<Grid className={styles.mainAddress}>
													<Typography
														variant="subtitle1"
														sx={{ fontWeight: 'bold' }}>
														{add.name}
													</Typography>
													{index === selectedAdd && (
														<Icon iconName="IconCircleCheck" color="#16885F" />
													)}
												</Grid>
												<Grid>
													{add?.attributes.map((attr, index) => (
														<Typography
															key={`${index}`}
															variant="body2"
															color={attr?.color}>
															{attr?.label}
														</Typography>
													))}
												</Grid>
											</Grid>
										);
									})}
								</Grid>
								<Grid item xs={12}>
									<Button variant="outlined" onClick={addAddress}>
										+ Address
									</Button>
								</Grid>
							</Grid>
						)}
						{!address && (
							<Grid container className={styles.noAddress} xs={12}>
								<Grid xs={12} className={styles.sampleImage}>
									<Image src={imgSrc} height="255px" width="255px" />
								</Grid>
								<Grid xs={12} className={styles.centerAlign}>
									<Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
										There are no addresses
									</Typography>
								</Grid>
								<Grid xs={12} className={styles.centerAlign}>
									<Button width="150px" onClick={addAddress}>
										Add
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>

					{address && (
						<Grid className={styles.footerAdd} xs={12}>
							<Button fullWidth onClick={saveAddress}>
								Save
							</Button>
						</Grid>
					)}
				</Grid>
				<AddAddress
					getAddress={getAddress}
					open={addAdd}
					close={closeAddAddress}
				/>
			</>
		);
	};

	return (
		<>
			<Drawer
				anchor="right"
				open={open}
				content={renderDrawerData()}
				onClose={() => closeDrawer()}
			/>
		</>
	);
}

SelectAddress.propTypes = {
	address: PropTypes.shape({
		find: PropTypes.func,
		map: PropTypes.func,
	}).isRequired,
	close: PropTypes.func.isRequired,
	getAddress: PropTypes.func.isRequired,
	name: PropTypes.any.isRequired,
	open: PropTypes.any.isRequired,
	selectedAddress: PropTypes.shape({
		id: PropTypes.any,
	}).isRequired,
	type: PropTypes.any.isRequired,
};
