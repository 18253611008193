import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Checkbox,
	Drawer,
	Grid,
	Icon,
	Input,
	SingleSelect,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createAddress, getAddressFieldsData } from '../../api/handlers/quote';
import { toast } from '../../store/actions/cart.actions';
import { addressFieldsData } from '../CartModule/contract';
import styles from './AddAddress.module.css';
export default function AddAddress({ open, close, getAddress }) {
	const [fields, setFields] = useState([]);
	const cart = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const closeDrawer = () => {
		close();
	};
	const sx = { width: '100%', marginBottom: '1.5rem', marginTop: '5px' };
	useEffect(() => {
		getAddressFieldsData()
			.then((res) => {
				setFields(res?.data?.data);
			})
			.catch((err) => {
				console.log(err);
				setFields(addressFieldsData);
			});
	}, []);

	const onChange = (event, index, type) => {
		const fields2 = [...fields];
		switch (type) {
			case 'text': {
				fields2[index].value = event.target.value;
				break;
			}
			case 'number': {
				fields2[index].value = event.target.value;
				break;
			}
			case 'select': {
				fields2[index].value = event.value;
				break;
			}
			case 'checkbox': {
				fields2[index].value = event.target.checked;
				break;
			}
			default:
				break;
		}
		setFields(fields2);
	};

	const createAdd = (event) => {
		event.preventDefault();
		if (event.target.form.reportValidity()) {
			const payload = {
				address: {
					attributes: fields.map((attr) => ({
						attribute_id: attr.attribute_id,
						value: attr.value,
					})),
				},
			};
			createAddress(payload)
				.then(() => {
					dispatch(toast('success', 'Success', 'Created Address'));
					getAddress();
					closeDrawer();
				})
				.catch((error) => {
					console.error(error);
					dispatch(toast('error', 'Error', 'Error while creating address'));
					getAddress();
					closeDrawer();
				});
		}
	};

	const fieldRenderer = (field, index) => {
		switch (field.type) {
			case 'text': {
				return (
					<Grid sx={sx}>
						<Input
							sx={{ width: '100%' }}
							variant="outlined"
							placeholder={field.name}
							label={field.name}
							onChange={(event) => onChange(event, index, field.type)}
							required={field.required}
							value={field.value}
							type={field.type}
						/>
					</Grid>
				);
			}
			case 'number': {
				return (
					<Grid sx={sx}>
						<Input
							sx={{ width: '100%' }}
							variant="outlined"
							placeholder={field.name}
							label={field.name}
							onChange={(event) => onChange(event, index, field.type)}
							required={field.required}
							value={field.value}
							type={field.type}
						/>
					</Grid>
				);
			}
			case 'select': {
				return (
					<Grid sx={sx}>
						<SingleSelect
							label={field.name}
							handleChange={(event) => onChange(event, index, field.type)}
							required={field.required}
							defaultValue={field.value}
							name={field.name}
							options={field.options}
						/>
					</Grid>
				);
			}
			case 'checkbox': {
				return (
					<Grid sx={sx}>
						<Grid
							container
							display="flex"
							alignItems="center"
							justifyContent="left">
							<Checkbox
								onChange={(event) => onChange(event, index, field.type)}
								checked={!!field.value}
								sx={{ paddingLeft: '0px' }}
							/>{' '}
							<Typography
								onClick={() =>
									onChange(
										{ target: { checked: !field.value } },
										index,
										field.type
									)
								}
								sx={{ cursor: 'pointer' }}
								variant="subtitle2">
								{field?.name}
							</Typography>
						</Grid>
					</Grid>
				);
			}
			default:
				return <></>;
		}
	};

	const renderDrawerData = () => {
		return (
			<Box component="form" className={styles.addAddressContainer}>
				<Grid item xs={12}>
					<Grid xs={12} className={styles.header}>
						<Grid
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Grid
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									paddingBottom: '1rem',
								}}>
								<Icon sx={{ marginRight: '3px' }} iconName="IconChevronLeft" />
								<Typography variant="h5">
									Add {cart.addresses.type} Address
								</Typography>
							</Grid>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={() => closeDrawer()}
							/>
						</Grid>
					</Grid>
					<Grid xs={12} className={styles.addressBody}>
						{fields.map(fieldRenderer)}
					</Grid>
				</Grid>
				<Grid xs={12} sx={{ position: 'relative' }}>
					<Button
						type="submit"
						sx={{ position: 'absolute', bottom: '0' }}
						onClick={createAdd}
						fullWidth>
						Save
					</Button>
				</Grid>
			</Box>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={open}
			content={renderDrawerData()}
			onClose={() => closeDrawer()}
		/>
	);
}

AddAddress.propTypes = {
	close: PropTypes.func.isRequired,
	getAddress: PropTypes.func.isRequired,
	open: PropTypes.any.isRequired,
};
