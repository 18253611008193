import PropTypes from 'prop-types';
import {
	Button,
	Drawer,
	Grid,
	Icon,
	Radio,
	Typography,
} from '@sourcewiz/the-source';
import { useEffect, useState } from 'react';

import { getData } from '../../api/handlers/quote';
import styles from './SingleSelect.module.css';
export default function SingleSelect({
	options = [],
	section_key = '',
	all_data = [],
	title = '',
	onSave = () => {},
}) {
	const [showDrawer, setShowDrawer] = useState(false);
	const selected = options.find((option) => option.is_selected);
	const [optionsLocal, setOptions] = useState([...all_data]);
	const optionClick = (index) => {
		const optionList = [...optionsLocal];
		optionList?.forEach((option) => {
			option.is_selected = false;
		});
		if (optionList[index]) {
			optionList[index].is_selected = true;
		}
		setOptions(optionList);
	};
	const saveClick = () => {
		onSave && onSave(optionsLocal);
		setShowDrawer(false);
	};
	const getOptions = async () => {
		const data = await getData(section_key);
		if (data) {
			setOptions(data);
		}
	};
	useEffect(() => {
		if (showDrawer && all_data?.length === 0) {
			getOptions();
		}
	}, [showDrawer]);
	const renderDrawerData = () => {
		return (
			<Grid container className={styles.container}>
				<Grid item className={styles.body} xs={12}>
					<Grid xs={12} className={styles.header}>
						<Grid
							xs={12}
							item
							display="flex"
							justifyContent="space-between"
							alignItems="center">
							<Typography variant="h5">{title}</Typography>
							<Icon
								iconName="IconX"
								sx={{ cursor: 'pointer' }}
								onClick={() => setShowDrawer(false)}
							/>
						</Grid>
					</Grid>
					<Grid className={styles.content}>
						{optionsLocal?.map((option, index) => {
							return (
								<Button
									key={index + 1}
									tonal
									sx={{
										marginBottom: '1rem',
										display: 'flex',
										justifyContent: 'space-between',
										padding: '1rem',
										background: option?.is_selected ? '#E8F3EF' : 'white',
										border:
											'1px solid ' +
											(option?.is_selected
												? '#D1D6DD !important'
												: '#E8F3EF !important'),
									}}
									onClick={() => optionClick(index)}
									fullWidth>
									<Typography>{option?.label}</Typography>
									<Radio checked={option?.is_selected} />
								</Button>
							);
						})}
					</Grid>
					<Grid className={styles.footer} item xs={12}>
						<Button fullWidth onClick={saveClick}>
							Save
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<Grid className={styles.card}>
				<Grid className={styles.cardTitle}>
					<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
						{title}
					</Typography>
					<Button variant="text" onClick={() => setShowDrawer(true)}>
						Change
					</Button>
				</Grid>
				<Grid className={styles.contentText}>
					<Typography
						variant="body2"
						sx={{ fontWeight: '400', color: 'rgba(0, 0, 0, 0.87)' }}>
						{selected?.label}
					</Typography>
				</Grid>
			</Grid>
			<Drawer
				anchor="right"
				open={showDrawer}
				content={renderDrawerData()}
				onClose={() => setShowDrawer(false)}
			/>
		</>
	);
}

SingleSelect.propTypes = {
	all_data: PropTypes.array.isRequired,
	attributes: PropTypes.array.isRequired,
	onSave: PropTypes.func.isRequired,
	optionChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	section_key: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
