import { getAllFilters } from '../../api/handlers/products';
import filterActionTypes from '../types/filter.types';

export const fetchAllFilters = () => {
	return async (dispatch) => {
		try {
			getAllFilters().then((response) => {
				const { data } = response?.data || {};
				dispatch(fetchAllFiltersSuccess(data));
			});
		} catch (error) {
			dispatch(fetchAllFiltersFailure(error));
		}
	};
};

export const fetchAllFiltersSuccess = (data) => ({
	type: filterActionTypes.FETCH_ALL_FILTERS_SUCCESS,
	payload: data,
});

export const fetchAllFiltersFailure = (data) => ({
	type: filterActionTypes.FETCH_ALL_FILTERS_FAILURE,
	payload: data,
});

export const setAppliedFacets = (data) => ({
	type: filterActionTypes.SET_APPLIED_FACETS,
	payload: data,
});

export const clearFacet = (data) => ({
	type: filterActionTypes.CLEAR_FACET,
	payload: data,
});
export const clearFilter = (data) => ({
	type: filterActionTypes.CLEAR_FILTER,
	payload: data,
});

export const clearAllFilters = (data) => ({
	type: filterActionTypes.CLEAR_ALL_FILTERS,
	payload: data,
});
