import quoteTypes from '../types/quote.types';
const INITIAL_STATE = {
	quotation_details: [],
	billing_details: {},
};

const quoteReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case quoteTypes.GET_QUOTE: {
			const newState = { ...state, ...action.data };
			return newState;
		}
		default:
			return state;
	}
};
export default quoteReducer;
