const cartActionTypes = {
	OPEN_NOTES: 'OPEN_NOTES',
	OPEN_BILLING_ADDRESS: 'OPEN_BILLING_ADDRESS',
	OPEN_SHIPPING_ADDRESS: 'OPEN_SHIPPING_ADDRESS',
	SHOW_ADDRESSES: 'SHOW_ADDRESSES',
	ADD_ADDRESS: 'ADD_ADDRESS',
	SELECT_USER: 'SELECT_USER',
	CREATE_ACCOUNT_DRAWER: 'CREATE_ACCOUNT_DRAWER',
	GET_CART_DATA: 'GET_CART_DATA',
	SET_CART_DATA: 'SET_CART_DATA',
	VIEW_CART_SUMMARY: 'VIEW_CART_SUMMARY',
	OPEN_TAXES_MODAL: 'OPEN_TAXES_MODAL',
	ADD_TAXES: 'ADD_TAXES',
	TAXES_CHANGE: 'TAXES_CHANGE',
	SET_TAXES_TO_CART: 'SET_TAXES_TO_CART',
	REMOVE_ALL_TAXES: 'REMOVE_ALL_TAXES',
	SHOW_DISCOUNT_MODAL: 'SHOW_DISCOUNT_MODAL',
	DISCOUNT_CHANGE: 'DISCOUNT_CHANGE',
	DISCOUNT_TYPE_CHANGE: 'DISCOUNT_TYPE_CHANGE',
	UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
	REMOVE_A_TAX: 'REMOVE_A_TAX',
	PRODUCT_CHANGE: 'PRODUCT_CHANGE',
	PRODUCT_TO_CART_ADD_ACTION: 'PRODUCT_TO_CART_ADD_ACTION',
	REMOVE_A_DISCOUNT: 'REMOVE_A_DISCOUNT',
	REMOVE_ALL_PRODUCTS: 'REMOVE_ALL_PRODUCTS',
	REMOVE_A_PRODUCT_FROM_CART: 'REMOVE_A_PRODUCT_FROM_CART',
	UPDATE_NOTE: 'UPDATE_NOTE',
	REMOVE_A_TAX_FROM_DRAWER: 'REMOVE_A_TAX_FROM_DRAWER',
	SET_LOADING: 'SET_LOADING',
};
export default cartActionTypes;
