import PropTypes from 'prop-types';

import { Grid, Icon, Typography } from '@sourcewiz/the-source';

export default function NavInfo(props) {
	const { title, type } = props;
	let color = '#8CB910';
	let name = 'IconCircleCheck';
	if (type === 'error') {
		name = 'IconAlertTriangle';
		color = '#D74C10';
	} else if (type === 'warning') {
		color = '#F0AF30';
		name = 'IconAlertTriangle';
	}
	return (
		<Grid
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				background: '#F7F8F8',
				padding: '15px 22px',
				borderRadius: '8px',
			}}>
			<Icon color={color} iconName={name} sx={{ marginRight: '10px' }} />
			<Typography
				sx={{ fontWeight: '400', fontSize: '16px' }}
				variant="subtitle">
				{title}
			</Typography>
		</Grid>
	);
}

NavInfo.propTypes = {
	title: PropTypes.any.isRequired,
	type: PropTypes.string.isRequired,
};
