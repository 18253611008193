const AUTH = {
	LOGIN: '/',
	FORGOT_PASSWORD_CHANGE_EMAIL: '/forgot-password',
	RESET_PASSWORD: '/reset-password/:id/:token',
	RESET_PASSWORD_SUCCESS: '/reset-password-success',
};

const PRODUCTS = {
	ALL_PRODUCTS: '/all-products',
	PRODUCT_DETAILS: '/product-details/:id',
};

const ACCOUNT = {
	CREATE_ACCOUNT: '/create-account',
	ACCOUNT_DETAILS: '/account-details',
	EDIT_ACCOUNT: '/edit-account/:id',
};

const CART = {
	DEFAULT: '/cart',
	REVIEW_AND_SUBMIT: '/review-submit',
	BULK_EDIT: '/cart/bulk/edit',
};

const ROUTES = { ACCOUNT, AUTH, PRODUCTS, CART };

export default ROUTES;
