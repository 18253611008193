import PropTypes from 'prop-types';
import { Drawer } from '@sourcewiz/the-source';
import ReviewCartRightBody from '../ReviewCartRightBody/ReviewCartRightBody';
import styles from './ReviewRightDrawer.module.css';
export default function ReviewRightDrawer({ show, closeDrawer = () => {} }) {
	const renderDrawerData = () => {
		return (
			<ReviewCartRightBody
				fromDrawer
				closeDrawer={closeDrawer}
				className={styles.Drawer}
			/>
		);
	};

	return (
		<Drawer
			anchor="right"
			open={show}
			content={renderDrawerData()}
			onClose={() => {}}
		/>
	);
}

ReviewRightDrawer.propTypes = {
	closeDrawer: PropTypes.func.isRequired,
	show: PropTypes.any.isRequired,
};
