import { Grid } from '@sourcewiz/the-source';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CartModule from '../components/CartModule';
import BulkEdit from '../components/CartModule/BulkEdit/BulkEdit';
import ReviewSubmit from '../components/CartModule/ReviewSubmit/ReviewSubmit';
import { Content, Sidebar, Topbar } from '../components/Layout';
import QuickSettings from '../components/QuickSettings/QuickSettings';
import { ROUTES } from '../constants';
import { checkApi } from '../store/actions/login.actions';
import history from '../util/history';
import { AccountDetails, CreateAccount } from './Accounts';
import AllProducts from './AllProducts';
import ProductDetailsPage from './ProductDetailsPage';

export default function ProtectedRoutes() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(checkApi());
	}, [dispatch]);
	return (
		<Router history={history}>
			<Grid container>
				<Grid item position="fixed" width={85} zIndex="drawer">
					<Sidebar />
				</Grid>
				<Grid
					item
					xs
					position="absolute"
					right={0}
					left={85}
					width="calc(100% - 6.5em)"
					wrap="nowrap">
					<Grid
						sx={{
							background: '#FFFFFF',
							position: 'sticky',
							top: 0,
							zIndex: 999,
						}}>
						<Topbar />
					</Grid>
					<Content>
						<Routes>
							<Route
								path={ROUTES.ACCOUNT.CREATE_ACCOUNT}
								element={<CreateAccount />}
							/>
							<Route
								path={ROUTES.ACCOUNT.EDIT_ACCOUNT}
								element={<CreateAccount />}
							/>
							<Route
								path={ROUTES.ACCOUNT.ACCOUNT_DETAILS}
								element={<AccountDetails />}
							/>
							<Route
								path={ROUTES.PRODUCTS.ALL_PRODUCTS}
								element={<AllProducts />}
							/>
							<Route
								path={ROUTES.PRODUCTS.PRODUCT_DETAILS}
								element={<ProductDetailsPage />}
							/>
							<Route path={ROUTES.CART.DEFAULT} element={<CartModule />} />
							<Route
								path={ROUTES.CART.REVIEW_AND_SUBMIT}
								element={<ReviewSubmit />}
							/>
							<Route path={ROUTES.CART.BULK_EDIT} element={<BulkEdit />} />
						</Routes>
					</Content>
				</Grid>
				<Grid>
					<QuickSettings />
				</Grid>
			</Grid>
		</Router>
	);
}
